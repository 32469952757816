import {EntityType, Mapping} from "./metadataManager";
import {StructureDescription, ValidationSchema, UgdmTypeWithRights} from "../interfaces";
import type {FormLayout} from "../interfaces";
import {requestHeaders} from "./ajaxService";
import Swal from "sweetalert2";
import {addValuesToAdvancedFilter, createFilter} from "../utilities/filterUtils";
import {appendErrorInformation, success} from "../utilities/alertNotificationUtils";
import {compile, match} from "path-to-regexp";
import {
    getDateFieldFormat,
    getDateTimeStringFromISOString, getTimeStringFromISOString,
    getHumanReadableDateFromDate,
    parseStringDateToDate,
    parseStringDateToISO
} from "../utilities/dateUtils";
import {searchFeatureInfosByWKT} from "../components/Map/map-utils";
import QRCode from 'qrcode' // https://www.npmjs.com/package/qrcode
import {constants} from "../constants";
import {v4 as uuidv4} from 'uuid'

export const timeout = async (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const afterMetadata = (guids: string[], callback: (metadata: EntityType[]) => {}) => {
    const loadData = async () => {
        let metadata: EntityType[] = []
        for (let i = 0; i < guids.length; i++) {
            try {
                let data = await bc.parameterService.getMetadata(guids[i])
                metadata.push(data)
            } catch (error) {
                console.log('error', error)
                throw 'Error getting metadata for: ' + guids[i]
            }
        }
        callback(metadata)
    }
    loadData()
}

const validateEntity = (metadata: EntityType, entity: any, schemas: ValidationSchema[]): { valid: boolean, errors: string[] }[] => {
    let results: { valid: boolean, errors: string[] } [] = []
    for (let i = 0; i < schemas.length; i++) {
        let validation = validateSchema(metadata, entity, schemas[i])
        results.push(validation)
    }
    return results
}

const validateSchema = (metadata: EntityType, entity: any, schema: ValidationSchema): { valid: boolean, errors: string[] } => {
    const generalText = getComponentText('general')
    let errors = []
    let valid = true
    try {
        if (!entity) {
            errors.push('No object given to validate!')
            valid = false
            return {valid: valid, errors}
        }

        const {requiredFields, rules} = schema

        for (let i = 0; i < requiredFields.length; i++) {
            let field = metadata.properties.fields.find(f => f.name === requiredFields[i])
            if (field) {
                if (!entity[requiredFields[i]]) {
                    let message = generalText.requiredField + field.longName
                    errors.push(message)
                    valid = false
                }
            } else {
                let message = generalText.fieldNotExisting + requiredFields[i]
                errors.push(message)
                valid = false
            }
        }

        for (let i = 0; i < rules.length; i++) {
            let rule = rules[i]
            let field = metadata.properties.fields.find(f => f.name === rule.field)
            if (field) {
                if (!entity[requiredFields[i]]) {
                    let message = generalText.requiredField + field.longName
                    errors.push(message)
                    valid = false
                } else {
                    let values = rule.values
                    let ruleIsValid = false
                    for (let j = 0; j < values.length; j++) {
                        if (entity[rule.field] === values[j]) {
                            ruleIsValid = true
                            break;
                        }
                    }

                    if (!ruleIsValid) {
                        errors.push(rule.message)
                        valid = false
                    }
                }
            } else {
                let message = generalText.fieldNotExisting + requiredFields[i]
                errors.push(message)
                valid = false
            }
        }

        return {valid: valid, errors}
    } catch (error) {
        console.error(generalText.error, error)
        errors.push(generalText.error)
        errors.push(error)
        valid = false
        return {valid: valid, errors}
    }
}

// TODO fetch metadata and url parameters from dataviewGuid
const getEntityItem = async (metadata: EntityType, objectId: string) => {
    try {
        console.log('metadata', metadata, objectId)
        let url = bc.parameterService.appParameters.ApplicationDataServiceBaseUrl + metadata.resource.TY_NAME + `(guid'${objectId}')`
        console.log('url', url)
        let response = await bc.ajaxService.fetchRaw(requestHeaders.jsonGet, url)
        if (response) {
            let jsonAnswer = await response.json()
            bc.parameterService.set('OBJECT_ITEM_' + metadata.resourceId + '_' + objectId, jsonAnswer.Items[0])
            return jsonAnswer.Items[0]
        } else {
            return null
        }

    } catch (error) {
        console.error('error ENTITY does not exist anymore?', error, metadata)
        return null
    }
}

const getActionItem = async (metadata: EntityType, objectId: string) => {
    try {
        // console.log('metadata', metadata, objectId)
        let actionIdFilter = createFilter({
            filterExpressionNumber: 0,
            filterExpressionJoinType: 'and',
            columnName: 'AC_ID',
            joinType: 'and',
            metadata: metadata,
            comparator: '=',
            intable: false,
        })
        addValuesToAdvancedFilter(actionIdFilter, [{name: metadata.getColumnLongName('AC_ID'), value: objectId}])
        const response = await bc.metadataManager.fetchEntityActions(
            [actionIdFilter],
            metadata.resourceName,
            'allpages',
            1,
            0,
            [],
        )
        //console.log('response', response)
        if (response) {
            bc.parameterService.set('OBJECT_ITEM_' + metadata.resourceId + '_' + objectId, response.value[0])
            return response.value[0]
        } else {
            return null
        }

    } catch (error) {
        console.error('error Action does not exist anymore?', error, metadata)
        return null
    }
}


const getDocumentItem = async (metadata: EntityType, objectId: string) => {
    try {
        // console.log('metadata', metadata, objectId)
        let documentIdFilter = createFilter({
            filterExpressionNumber: 0,
            filterExpressionJoinType: 'and',
            columnName: 'EY_ID',
            joinType: 'and',
            metadata: metadata,
            comparator: '=',
            intable: false,
        })
        addValuesToAdvancedFilter(documentIdFilter, [{name: metadata.getColumnLongName('EY_ID'), value: objectId}])
        const response = await bc.metadataManager.fetchAttachement([documentIdFilter], 'UgdmArtifacts', 'allpages', 1, 0, [])
        console.log('response', response)
        if (response) {
            bc.parameterService.set('OBJECT_ITEM_' + metadata.resourceId + '_' + objectId, response.value[0])
            return response.value[0]
        } else {
            return null
        }

    } catch (error) {
        console.error('error Action does not exist anymore?', error, metadata)
        return null
    }
}

// updateEntity = async (dataviewGuid: string, entity: any)
// Save a new or existing data record into a view, specified by its guid, including data, core and metadata views
const updateEntity = async (dataviewGuid: string, entity: any): Promise<object | boolean> => {
    try {
        let objectMetadata = await bc.parameterService.getMetadata(dataviewGuid)
        const urlAndResourceParams = bc.metadataManager.getResourceUrlAndParameters(objectMetadata.resourceName, '')
        const answerEnttityUpdate = await bc.ajaxService.fetchPost(requestHeaders.jsonPost, urlAndResourceParams.url, JSON.stringify(entity))
        const newObject = await answerEnttityUpdate.json()
        bc.parameterService.set('OBJECT_ITEM_' + dataviewGuid + '_' + entity[objectMetadata.idProperty], newObject)
        return newObject
    } catch (error) {
        appendErrorInformation(error, 'Fehler beim Speichern und Aktualisieren eines Datensatzes. View-GUID: ' + dataviewGuid + ': ' + JSON.stringify(entity));
        throw error
    }
}

// deleteEntity = async(dataviewGuid: string, objectId: string)
// Delete a new data record by giving its view, specified by its guid, and its object-GUID
const deleteEntity = async(dataviewGuid: string, objectId: string) => {
    let metadata = await bc.parameterService.getMetadata(dataviewGuid)
    const urlAndResourceParams = bc.metadataManager.getResourceUrlAndParameters(metadata.resourceName, '')
    let url = urlAndResourceParams.url+ `(guid'${objectId}')`
    let answerDelete = await bc.ajaxService.fetchDelete(requestHeaders.jsonDelete, url)
     if(answerDelete.status !== 204){
         return false
     }else{
         return true
     }
}

const getComponent = (name: string) => {
    return window['components'][name]
}

const getDataviewGuidByName = (name: string) => {
    let views: UgdmTypeWithRights[] = bc.parameterService.get('DATAVIEWS')
    if (views) {
        for (let i = 0; i < views.length; i++) {
            if (views[i].TY_NAME === name) {
                return views[i].TY_ID
            }
        }
    }
    return null
}

const createSimpleFilter = async (data: {
    metadata: EntityType,
    field: string,
    comparator: '=' | '!=' | string,
    value: any,
    filterNumber: number,
    joinType?: 'and' | 'or'
}) => {

    let {metadata, field, comparator, value, filterNumber, joinType} = data
    let selectedColumn = metadata.properties.fields.find(f => f.name === field)
    const generalText = getComponentText('general')

    if (!joinType) {
        joinType = 'and'
    }

    let filter = createFilter({
        filterExpressionNumber: filterNumber !== undefined && filterNumber !== null ? filterNumber : 1,
        filterExpressionJoinType: 'and',
        columnName: field,
        joinType: joinType,
        metadata: metadata,
        comparator: comparator,
        intable: false,
    })

    if (filter.comparator !== 'is Empty' && filter.comparator !== 'is NULL' && filter.comparator !== 'is not NULL') {
        if (value) {
            switch (selectedColumn.dataType) {
                case 'Int16':
                case 'Int32':
                case 'Int64':
                case 'Decimal':
                case 'Double': {
                    if (Array.isArray(value)) {
                        for (let i = 0; i < value.length; i++) {
                            addValuesToAdvancedFilter(filter, [{name: value[i], value: value[i]}])
                        }
                    } else {
                        addValuesToAdvancedFilter(filter, [{name: value, value: value}])
                    }
                    break
                }

                case 'Boolean': {
                    if (Array.isArray(value)) {
                        for (let i = 0; i < value.length; i++) {
                            if (value[i] === 'true') {
                                addValuesToAdvancedFilter(filter, [{name: generalText.yes, value: value[i]}])
                            } else if (value[i] === 'false') {
                                addValuesToAdvancedFilter(filter, [{name: generalText.no, value: value[i]}])
                            } else if (value[i] === 'null') {
                                addValuesToAdvancedFilter(filter, [{name: 'NULL', value: value[i]}])
                            }
                        }
                    } else {
                        if (value === 'true') {
                            addValuesToAdvancedFilter(filter, [{name: generalText.yes, value: value}])
                        } else if (value === 'false') {
                            addValuesToAdvancedFilter(filter, [{name: generalText.no, value: value}])
                        } else if (value === 'null') {
                            addValuesToAdvancedFilter(filter, [{name: 'NULL', value: value}])
                        }
                    }
                    break
                }
                case 'EntityList': {
                    let viewList = metadata.properties.viewLists.find((e) => e.columnName === field)
                    if (viewList) {
                        let allViewEntries = await viewList.getEntries()
                        if (allViewEntries && allViewEntries['Items']) {
                            let viewListMetadata = await bc.parameterService.getMetadata(viewList.dataviewGuid)
                            let entries = allViewEntries['Items']
                            if (Array.isArray(value)) {
                                for (let i = 0; i < value.length; i++) {
                                    let entry = entries.find(e => e[viewListMetadata.idProperty] === value[i])
                                    addValuesToAdvancedFilter(filter, [{name: entry[viewListMetadata.titleProperty], value: value[i]}])
                                }
                            } else {
                                let entry = entries.find(e => e[viewListMetadata.idProperty] === value)
                                addValuesToAdvancedFilter(filter, [{name: entry[viewListMetadata.titleProperty], value: value}])
                            }
                        }
                    }
                    break
                }
                case 'List': {
                    const mapping = metadata.properties.listMapping.find((m) => m.fieldName === field)
                    if (mapping) {
                        if (Array.isArray(value)) {
                            for (let i = 0; i < value.length; i++) {
                                let item = mapping.values.find(e => e.guid === value[i])
                                addValuesToAdvancedFilter(filter, [{name: item.value, value: value[i]}])
                            }
                        } else {
                            let item = mapping.values.find(e => e.guid === value)
                            addValuesToAdvancedFilter(filter, [{name: item.value, value: value}])
                        }
                    }
                    break
                }
                case 'String': {
                    if (Array.isArray(value)) {
                        for (let i = 0; i < value.length; i++) {
                            addValuesToAdvancedFilter(filter, [{name: value[i], value: value[i]}])
                        }
                    } else {
                        addValuesToAdvancedFilter(filter, [{name: value, value: value}])
                    }
                    break
                }
                case 'Guid': {
                    if (Array.isArray(value)) {
                        for (let i = 0; i < value.length; i++) {
                            addValuesToAdvancedFilter(filter, [{name: value[i], value: value[i]}])
                        }
                    } else {
                        addValuesToAdvancedFilter(filter, [{name: value, value: value}])
                    }
                    break
                }
                case 'Point': {
                    if (Array.isArray(value)) {
                        throw new Error('Point filters can only have 1 value!')
                    } else {
                        addValuesToAdvancedFilter(filter, [{name: value, value: value}])
                    }
                    break
                }
                case 'Polygon': {
                    if (Array.isArray(value)) {
                        throw new Error('Polygon filters can only have 1 value!')
                    } else {
                        addValuesToAdvancedFilter(filter, [{name: value, value: value}])
                    }
                    break
                }
                case 'MultiPoint': {
                    if (Array.isArray(value)) {
                        throw new Error('Multipoint filters can only have 1 value!')
                    } else {
                        addValuesToAdvancedFilter(filter, [{name: value, value: value}])
                    }
                    break
                }
                case 'MultiPolygon': {
                    if (Array.isArray(value)) {
                        throw new Error('MultiPolygon filters can only have 1 value!')
                    } else {
                        addValuesToAdvancedFilter(filter, [{name: value, value: value}])
                    }
                    break
                }
                case 'LineString': {
                    if (Array.isArray(value)) {
                        throw new Error('LineString filters can only have 1 value!')
                    } else {
                        addValuesToAdvancedFilter(filter, [{name: value, value: value}])
                    }
                    break
                }
                case 'MultiLineString': {
                    if (Array.isArray(value)) {
                        throw new Error('MultiLineString filters can only have 1 value!')
                    } else {
                        addValuesToAdvancedFilter(filter, [{name: value, value: value}])
                    }
                    break
                }
                case 'DateTime': {

                    if (['today', 'week', 'month', 'last week', 'last month', 'next week', 'next month'].includes(comparator)) {
                        addValuesToAdvancedFilter(filter, [])
                        if (value) {
                            console.warn(`The date comparator ${comparator} is not supposed to have a value!`)
                        }
                    } else {
                        if (Array.isArray(value)) {
                            for (let i = 0; i < value.length; i++) {
                                let ISODate = parseStringDateToISO(value[i])
                                let dateformat = getDateFieldFormat(selectedColumn)
                                let frontEndValue = ''
                                if (dateformat === 'date') {
                                    frontEndValue = getDateTimeStringFromISOString(ISODate, false)
                                }
                                if (dateformat === 'datetime') {
                                    frontEndValue = getDateTimeStringFromISOString(ISODate, true)
                                }
                                if (dateformat === 'time') {
                                    frontEndValue = getTimeStringFromISOString(ISODate)
                                }
                                addValuesToAdvancedFilter(filter, [{name: frontEndValue, value: ISODate}])
                            }
                        } else {
                            let ISODate = parseStringDateToISO(value)
                            let dateformat = getDateFieldFormat(selectedColumn)
                            let frontEndValue = ''
                            if (dateformat === 'date') {
                                frontEndValue = getDateTimeStringFromISOString(ISODate, false)
                            }
                            if (dateformat === 'datetime') {
                                frontEndValue = getDateTimeStringFromISOString(ISODate, true)
                            }
                            if (dateformat === 'time') {
                                frontEndValue = getTimeStringFromISOString(ISODate)
                            }
                            addValuesToAdvancedFilter(filter, [{name: frontEndValue, value: ISODate}])
                        }
                    }
                    break
                }
            }
        } else {
            addValuesToAdvancedFilter(filter, [])
        }
    }

    console.log('filter', filter)

    return filter
}


const getRelationshipItems = async (metadata: EntityType, fromItem: any, associationId: string, count: number) => {

    let relationship = metadata.relations.find(r => r.relationShip.TY_ID === associationId)
    let otherViewMetadata = await bc.parameterService.getMetadata(relationship.viewId)
    if (relationship) {
        const dataPromise = await bc.metadataManager.fetchJsonData({
            filters: [],
            resourceName: otherViewMetadata.resourceName,
            inlinecount: 'allpages',
            top: count,
            offset: 0,
            orderBy: [],
            typeDomainOrDmParent: null,
            entityGuid: null,
            rlType: relationship.rlType,
            rlFromItemguid: fromItem[metadata.idProperty],
            rlDirection: relationship.direction,
        })

        if (dataPromise) {
            return dataPromise['Items']
        }

    } else {
        return null
    }
}

const showConfirm = (data: {
    icon: 'warning' | 'error' | 'success' | 'info' | 'question',
    title: string,
    text: string,
    confirmText: string,
    cancelText: string,
    cancelCallback: Function,
    successCallback: Function
}) => {
    const {icon, title, text, confirmText, cancelText, cancelCallback, successCallback} = data
    Swal.fire({
        icon: icon,
        title: title,
        text: text,
        html: text,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: cancelText,
        confirmButtonText: confirmText,

    }).then(result => {
        if (result.isConfirmed) {
            successCallback()
        }
        if (result.isDenied || result.isDismissed) {
            cancelCallback()
        }
    })
}

const showInfo = (data: { icon: 'warning' | 'error' | 'success' | 'info' | 'question', title: string, text: string }) => {
    const {icon, title, text} = data
    Swal.fire({
        icon: icon,
        title: title,
        text: text,
    })
}

const showInfoWithCallback = (data: {
    icon: 'warning' | 'error' | 'success' | 'info' | 'question';
    callback: (...any) => void;
    title: string;
    text: string
}, ...args) => {
    const {icon, title, text, callback} = data
    Swal.fire({
        icon: icon,
        title: title,
        text: text,
    }).then(async (result) => {
        if (callback.constructor.name === 'AsyncFunction') {
            await callback(...args)
        } else {
            callback(...args)
        }
    })
}

const parsePath = (path: string) => {
    return match(path, {decode: decodeURIComponent});
}

const createPathFunction = (path: string) => {
    let fn = compile(path, {encode: encodeURIComponent});
    return fn
}

const getRouterComponent = (name: string) => {
    let routerComponents = bc.parameterService.get('routerComponents') as { name: string, component: HTMLElement }[]
    if (!routerComponents) {
        return null
    } else {
        let item = routerComponents.find((item) => item.name === name)
        if (item) {
            return item.component
        } else {
            return null
        }
    }
}

const getRouterComponentAsync = async (name: string, duration?: number) => {

    let maxTries = 500
    if (duration) {
        maxTries = Math.ceil(duration / 5)
    }

    return new Promise((resolve, reject) => {
        let routerComponent = null
        let counter = 0
        const getRouterComponentAsync = () => {
            if (counter > maxTries) {
                clearInterval(routerInterval)
                resolve(null)
            }
            let routerComponents = bc.parameterService.get('routerComponents') as { name: string, component: HTMLElement }[]
            if (routerComponents) {
                let item = routerComponents.find((item) => item.name === name)
                if (item) {
                    routerComponent = item.component
                    clearInterval(routerInterval)
                    resolve(routerComponent)
                }
            }
            counter++
        }
        let routerInterval = setInterval(getRouterComponentAsync, 25)
    })
}

const getComponentAsync = async (name: string, duration?: number) => {

    let maxTries = 100
    if (duration) {
        maxTries = Math.ceil(duration / 25)
    }

    console.log('looking for', name)
    return new Promise((resolve, reject) => {

        let counter = 0
        const getElement = () => {
            if (counter > maxTries) {
                clearInterval(routerInterval)
                resolve(null)
            }

            if (window['components'][name]) {
                clearInterval(routerInterval)
                resolve(window['components'][name])
            }
            counter++
        }
        let routerInterval = setInterval(getElement, 25)
    })
}

const getDocumentComponentAsync = async (name: string, duration?: number) => {

    let maxTries = 100
    if (duration) {
        maxTries = Math.ceil(duration / 25)
    }

    console.log('looking for', name)
    return new Promise((resolve, reject) => {
        let routerComponent = null
        let counter = 0
        const getRouterComponentAsync = () => {
            if (counter > maxTries) {
                clearInterval(routerInterval)
                resolve(null)
            }
            let item = document.getElementsByName(name)[0] as HTMLElement
            if (item) {
                clearInterval(routerInterval)
                resolve(routerComponent)
            }
            counter++
        }
        let routerInterval = setInterval(getRouterComponentAsync, 25)
    })
}

const addRouterFunction = (type: string, mapping: { create: Function, parse: Function, params: string[], path: string, type: string }) => {
    let routerFunctions = bc.parameterService.get('ROUTER-FUNCTIONS') as {
        type: string,
        mapping: { create: Function, parse: Function, path: string }
    }[]
    if (!routerFunctions) {
        routerFunctions = []
    }
    routerFunctions.push({type, mapping})
    bc.parameterService.set('ROUTER-FUNCTIONS', routerFunctions)
}

const createRouterMapping = (args: {
    type: 'fix' | 'view' | 'entity' | 'document' | 'action' | 'map' | 'dashboard' | string,
    path: string,
    params: string[],
    callback: (parsedMatch: any, location: any) => Promise<any>
}) => {

    const {type, path, params, callback} = args
    let create = createPathFunction(path)
    let parse = parsePath(path)
    addRouterFunction(type, {create, parse, params, path, type})

    return {
        type: type,
        create: create,
        parse: parse,
        params: params,
        path: path,
        callback: callback
    }
}

const createRouteUrl = (path: string) => {

    let urlObj = new URL(bc.parameterService.appParameters.pageName);
    let newURL = urlObj.href.replace(urlObj.origin, '');
    if (!newURL.endsWith('/')) {
        newURL = newURL + '/'
    }
    if (path) {
        if (path.startsWith('/')) {
            path = path.substring(1)
        }
        if (!path.endsWith('/')) {
            path = path + '/'
        }
        return newURL + path
    } else {
        return newURL
    }
}

const isGuid = (guid) => {
    const regexGuid = /[[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}]*/gi
    return regexGuid.test(guid)
}

const getGuid = () => {
    return uuidv4()
}

const mustHandleLink = () => {
    if (window['browserHistory'] && window['browserHistory'].doHandle === true) {
        return true
    } else {
        window['browserHistory']['doHandle'] = true
        return false
    }
}

const getViewName = (guid: string) => {
    let allViews = bc.parameterService.get('DATAVIEWS') as UgdmTypeWithRights[]
    let view = allViews.find(v => v.TY_ID === guid)
    if (view) {
        return view.TY_NAME
    }
    return null
}

const getViewId = (name: string) => {
    let allViews = bc.parameterService.get('DATAVIEWS') as UgdmTypeWithRights[]
    let view = allViews.find(v => v.TY_NAME === name)
    if (view) {
        return view.TY_ID
    }
    return null
}

export const registerMultistepForm = (data: {
    dataviewGuid: string,
    steps: StructureDescription[],
    name: string,
    onCancel: () => Promise<boolean>,
    onFinish: () => Promise<boolean>,
    onStep: (data: {step: number, stepName: string, initial: boolean, type: 'init'|'next'|'previous', stepper: HTMLElement})=> Promise<boolean> ,
    cancelText: string,
    finishText: string
}) => {
    const {dataviewGuid, steps, name, onCancel, onFinish, cancelText, finishText, onStep} = data
    bc.parameterService.set(name, steps)
    bc.parameterService.set('MULTISTEPFORM_' + dataviewGuid, name)
    bc.parameterService.set('MULTISTEPFORM_DETAILS_' + dataviewGuid, {onCancel, onFinish, onStep, cancelText, finishText})
}


export const registerRelationshipMultistepForm = (data: {
    dataviewGuid: string,
    relationshipAssociation: string,
    steps: StructureDescription[],
    name: string,
    onCancel: () => Promise<boolean>,
    onFinish: () => Promise<boolean>,
    onStep: (data: {step: number, stepName: string, initial: boolean, type: 'init'|'next'|'previous', stepper: HTMLElement})=> Promise<boolean> ,
    cancelText: string,
    finishText: string
}) => {
    const {dataviewGuid, relationshipAssociation, steps, name, onCancel, onFinish, cancelText, finishText, onStep} = data
    bc.parameterService.set(name, steps)
    bc.parameterService.set('RELATIONSHIP_MULTISTEPFORM_' + dataviewGuid + '_' + relationshipAssociation, name)
    bc.parameterService.set('RELATIONSHIP_MULTISTEPFORM_DETAILS_' + dataviewGuid + '_' + relationshipAssociation, {
        onCancel,
        onFinish,
        onStep,
        cancelText,
        finishText
    })
}

export const getRegisteredMultistepForm = (dataviewGuid: string) => {
    return bc.parameterService.get('MULTISTEPFORM_' + dataviewGuid)
}
export const getRegisteredMultistepFormDetails = (dataviewGuid: string) => {
    return bc.parameterService.get('MULTISTEPFORM_DETAILS_' + dataviewGuid)
}

export const getRegisteredRelationshipMultistepForm = (dataviewGuid: string, relationshipAssociation: string) => {
    return bc.parameterService.get('RELATIONSHIP_MULTISTEPFORM_' + dataviewGuid + '_' + relationshipAssociation)
}

export const getRegisteredRelationshipMultistepFormDetails = (dataviewGuid: string, relationshipAssociation: string) => {
    return bc.parameterService.get('RELATIONSHIP_MULTISTEPFORM_DETAILS_' + dataviewGuid + '_' + relationshipAssociation)
}


export function closestElement(selector, base = this) {
    function __closestFrom(el) {
        if (!el || el === document || el === window) return null;
        let found = el.closest(selector);
        return found ? found : __closestFrom(el.getRootNode().host);
    }

    return __closestFrom(base);
}

export const pageReady = (callBack: Function) => {
    if (bc.parameterService.get('APPLICATION_READY')) {
        window.addEventListener('load', callBack())
    } else {
        bc.parameterService.subscribe('APPLICATION_READY', 'PAGE_READY', () => {
            if (document.readyState === "complete") {
                callBack()
            } else {
                window.addEventListener('load', callBack())
            }
            bc.parameterService.unsubscribe('APPLICATION_READY', 'PAGE_READY')
        })
    }
}

const getComponentText = (name: string) => {
    let allTexts = window['texts'][name]
    let finalText = null
    if (allTexts) {
        finalText = {...allTexts['en']}
        finalText = {...finalText, ...allTexts[navigator.language.substring(0, 2)]}
        finalText = {...finalText, ...allTexts[navigator.language]}
    }
    return finalText
}

const getRouter = () => {
    return bc.parameterService.get('ROUTER')
}

const navigateTo = (route: string, params: any) => {
    let router = getRouter()
    if (router) {
        router.navigateTo(route, params)
    }
}

// const defineFormLayout = (data: { columns: number, dividerTop: string[], dividerBottom: string[] }, dataviewGuid: string) => {
//     const {columns, dividerTop, dividerBottom} = data
//     bc.parameterService.set('FORM_LAYOUT_' + dataviewGuid, data)
// }

const getRoute = (route: string) => {
    let router = getRouter()
    if (router) {
        let routes = router.getRoutes()
        if (routes) {
            return routes.find(r => r.type === route)
        }
    }
    return null
}

const createComponentText = (key: string, textObject: { de: {}, en: {}, fr: {}, it: {} }) => {
    if (!window['texts']) {
        window['texts'] = {}
    }

    if (!window['texts'][key]) {
        window['texts'][key] = textObject
        return true
    } else {
        console.error('Texts for key ' + key + ' already defined')
        return false
    }
}

const createFormLayout = (dataviewGuid, config: FormLayout) => {
    bc.parameterService.set('FORM_LAYOUT_' + dataviewGuid, config)
}

const setFeatureInfoCallback = (layerguid: string, callback: (text: string) => {}) => {
    bc.parameterService.set('FEATURE_INFO_CALLBACK' + layerguid, callback)
}

const showQrCode = (dataviewGuid: string, show: boolean) => {
    bc.parameterService.set('SHOW_QR_CODE_' + dataviewGuid, show)
}

const setDataviewManagerConfiguration = (dataviewGuid: string, config: {
    table: boolean,
    kanban: boolean,
    statistics: boolean,
    calendar: boolean,
    timeline: boolean,
    startWith: 'Table' | 'Kanban' | 'Chart' | 'Calendar' | 'TimeLine'
}) => {

    bc.parameterService.set('DATAVIEW_MANAGER_CONFIGURATION_' + dataviewGuid, config)

}

const drawQrCode = (canvas: HTMLCanvasElement, dataviewGuid: string, entityGuid: string) => {
    return new Promise((resolve, reject) => {
        let entityRoute = bc.utils.getRoute('entity')
        if (entityRoute) {
            let urlObj = new URL(bc.parameterService.appParameters.pageName);
            let entityLink = urlObj.origin + entityRoute.create({viewName: bc.utils.getViewName(dataviewGuid), entityId: entityGuid})
            QRCode.toCanvas(canvas, entityLink, (error) => {
                if (error) {
                    console.warn('error generating QR code')
                    console.error(error)
                    resolve(false)
                }
                resolve(true)
            })
        } else {
            console.warn('no entity route found to generate QR code')
            resolve(false)
        }
    })
}

export const utils = {
    timeout,
    afterMetadata,
    validateEntity,
    getEntityItem,
    updateEntity,
    deleteEntity,
    
    getComponent,
    getComponentAsync,
    createSimpleFilter,
    showConfirm,
    showInfoWithCallback,
    showInfo,
    getRelationshipItems,
    
    getRouterComponent,
    getRouterComponentAsync,
    parsePath,
    createPathFunction,
    createRouterMapping,
    mustHandleLink,
    getViewName,
    getViewId,
    closestElement,
    
    registerMultistepForm,
    registerRelationshipMultistepForm,
    getRegisteredMultistepForm,
    getRegisteredMultistepFormDetails,
    getRegisteredRelationshipMultistepForm,
    getRegisteredRelationshipMultistepFormDetails,
    pageReady,
    getComponentText,
    createRouteUrl,
    getRouter,
    getDataviewGuidByName,
    isGuid,
    getGuid,
    getActionItem,
    getDocumentItem,
    getRoute,
    navigateTo,
    getDocumentComponentAsync,
    createComponentText,
    createFormLayout,
    searchFeatureInfoByWKT: searchFeatureInfosByWKT,
    setFeatureInfoCallback,
    showQrCode,
    setDataviewManagerConfiguration,
    drawQrCode
}