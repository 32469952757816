import {messageService as mService, MessageServiceInterface} from './messageService'
import {parameterService as pService, ParameterServiceInterface} from './parameterService'
import {ajaxService as ajService, AjaxServiceInterface} from './ajaxService'
import {metadataManager as metaManager, MetadataServiceInterface} from './metadataManager'
import {componentConstructorHelper} from './constructorHelper'
import {formRuleHelper} from './formRuleHelper'
import {utils as Utils} from './bcutils'

export interface bcNamespace {
    messageService: MessageServiceInterface,
    parameterService: ParameterServiceInterface,
    ajaxService: AjaxServiceInterface,
    metadataManager: MetadataServiceInterface,
    componentConstructorHelper,
    formRuleHelper,
    utils
}

export namespace  bc {
    export const messageService = mService
    export const parameterService = pService
    export const ajaxService =  ajService
    export const metadataManager = metaManager
    export const constructorHelper = componentConstructorHelper
    export const utils = Utils
    export const ruleHelper= formRuleHelper
}

window['bc'] = {
    messageService: mService,
    parameterService: pService,
    ajaxService:  ajService,
    metadataManager:  metaManager,
    constructorHelper: componentConstructorHelper,
    utils: Utils,
    ruleHelper: formRuleHelper
}
