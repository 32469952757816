import type {AdvancedDataviewSearch, AdvancedFilterElement} from "../interfaces";
import {add, endOfMonth, endOfWeek, startOfMonth, startOfWeek, sub} from "date-fns";
import {EntityType} from "../services/metadataManager";
import bc from "bc";
import {handleErrorInAlert} from "./alertNotificationUtils";

/***************************************************************
 * FILTER METHODS
 ***************************************************************/

const createStringFilterCondition = (advancedFilter: AdvancedFilterElement) => {
    let filterCondition = ''

    switch (advancedFilter.comparator) {
        case '=': {
            for (let i = 0; i < advancedFilter.values.length; i++) {
                let value = advancedFilter.values[i].value;
                value = value.replace(/'/g, "''")
                filterCondition = filterCondition + advancedFilter.column + " eq '" + value + "' or "
            }
            filterCondition = filterCondition.substring(0, filterCondition.length - 4)
            break
        }
        case '!=': {
            for (let i = 0; i < advancedFilter.values.length; i++) {
                let value = advancedFilter.values[i].value;
                value = value.replace(/'/g, "''")
                filterCondition = filterCondition + advancedFilter.column + " ne '" + value + "' or "
            }
            filterCondition = filterCondition.substring(0, filterCondition.length - 4)
            break
        }
        case 'contains': {
            for (let i = 0; i < advancedFilter.values.length; i++) {
                let value = advancedFilter.values[i].value;
                value = value.replace(/'/g, "''")
                filterCondition =
                    filterCondition + `substringof(tolower('${value.toLowerCase()}'),${advancedFilter.column}) or `
            }
            filterCondition = filterCondition.substring(0, filterCondition.length - 4)
            break
        }
        case 'contains not': {
            for (let i = 0; i < advancedFilter.values.length; i++) {
                let value = advancedFilter.values[i].value;
                value = value.replace(/'/g, "''")
                filterCondition =
                    filterCondition + `indexof(${advancedFilter.column}, tolower('${value.toLowerCase()}')) eq -1 or `
            }
            filterCondition = filterCondition.substring(0, filterCondition.length - 4)
            break
        }
        case 'is NULL': {
            filterCondition = filterCondition + `${advancedFilter.column} eq null`
            break
        }
        case 'is Empty': {
            filterCondition = filterCondition + `${advancedFilter.column} eq ''`
            break
        }
        case 'is not NULL': {
            filterCondition = filterCondition + `${advancedFilter.column} ne null`
            break
        }
    }
    return filterCondition
}
const createNumberFilterString = (advancedFilter: AdvancedFilterElement) => {
    let filterCondition = ''
    switch (advancedFilter.comparator) {
        case 'contains': {
            for (let i = 0; i < advancedFilter.values.length; i++) {
                filterCondition =
                    filterCondition + `substringof(tolower('${advancedFilter.values[i].value.toLowerCase()}'+''),${advancedFilter.column}) or `
                // filterCondition = filterCondition + advancedFilter.column + "||''  eq " + advancedFilter.values[i].value + ' or '
            }
            filterCondition = filterCondition.substring(0, filterCondition.length - 4)
            break
        }
        case '=': {
            for (let i = 0; i < advancedFilter.values.length; i++) {
                filterCondition = filterCondition + advancedFilter.column + ' eq ' + advancedFilter.values[i].value + ' or '
            }
            filterCondition = filterCondition.substring(0, filterCondition.length - 4)
            break
        }
        case '!=': {
            for (let i = 0; i < advancedFilter.values.length; i++) {
                filterCondition = filterCondition + advancedFilter.column + ' ne ' + advancedFilter.values[i].value + ' or '
            }
            filterCondition = filterCondition.substring(0, filterCondition.length - 4)
            break
        }
        case '>': {
            for (let i = 0; i < advancedFilter.values.length; i++) {
                filterCondition = filterCondition + advancedFilter.column + ' gt ' + advancedFilter.values[i].value + ' or '
            }
            filterCondition = filterCondition.substring(0, filterCondition.length - 4)
            break
        }
        case '<': {
            for (let i = 0; i < advancedFilter.values.length; i++) {
                filterCondition = filterCondition + advancedFilter.column + ' lt ' + advancedFilter.values[i].value + ' or '
            }
            filterCondition = filterCondition.substring(0, filterCondition.length - 4)
            break
        }
        case '>=': {
            for (let i = 0; i < advancedFilter.values.length; i++) {
                filterCondition = filterCondition + advancedFilter.column + ' ge ' + advancedFilter.values[i].value + ' or '
            }
            filterCondition = filterCondition.substring(0, filterCondition.length - 4)
            break
        }
        case '<=': {
            for (let i = 0; i < advancedFilter.values.length; i++) {
                filterCondition = filterCondition + advancedFilter.column + ' le ' + advancedFilter.values[i].value + ' or '
            }
            filterCondition = filterCondition.substring(0, filterCondition.length - 4)
            break
        }
        case 'is NULL': {
            filterCondition = filterCondition + `${advancedFilter.column} eq null`
            break
        }

        case 'is not NULL': {
            filterCondition = filterCondition + `${advancedFilter.column} ne null`
            break
        }
    }
    return filterCondition
}
const createDateFilterString = (advancedFilter: AdvancedFilterElement) => {
    // console.log('---------createDateFilterString----------')
    let filterCondition = ''

    switch (advancedFilter.comparator) {
        case '=': {
            let date1 = advancedFilter.values[0].value.split('T')[0] + 'T00:00:00.000Z'
            let date2 = advancedFilter.values[0].value.split('T')[0] + 'T23:59:59.999Z'
            filterCondition =
                advancedFilter.column + ' ge ' + "datetime'" + date1 + "' and " + advancedFilter.column + ' le ' + "datetime'" + date2 + "'"
            break
        }
        case '!=': {
            let date1 = advancedFilter.values[0].value.split('T')[0] + 'T00:00:00.000Z'
            let date2 = advancedFilter.values[0].value.split('T')[0] + 'T23:59:59.999Z'
            filterCondition =
                advancedFilter.column + ' lt ' + "datetime'" + date1 + "' or " + advancedFilter.column + ' gt ' + "datetime'" + date2 + "'"
            break
        }
        case 'after': {
            // let date1 = advancedFilter.values[0].value.split('T')[0] + 'T00:00:00.000Z'
            // let date2 = advancedFilter.values[0].value.split('T')[0] + 'T23:59:59.999Z'
            const value = advancedFilter.values.find((v) => v.value !== '').value
            let date = value.split('T')[0] + 'T23:59:59.999Z'
            filterCondition = advancedFilter.column + ' gt ' + "datetime'" + date + "'"
            break
        }
        case 'before': {
            const value = advancedFilter.values.find((v) => v.value !== '').value
            let date = value.split('T')[0] + 'T00:00:00.000Z'
            // let date1 = advancedFilter.values[0].value.split('T')[0] + 'T00:00:00.000Z'
            // let date2 = advancedFilter.values[0].value.split('T')[0] + 'T23:59:59.999Z'
            filterCondition = advancedFilter.column + ' lt ' + "datetime'" + date + "'"
            break
        }
        case 'after inclusive': {
            // let date1 = advancedFilter.values[0].value.split('T')[0] + 'T00:00:00.000Z'
            // let date2 = advancedFilter.values[0].value.split('T')[0] + 'T23:59:59.999Z'
            const value = advancedFilter.values.find((v) => v.value !== '').value
            let date = value.split('T')[0] + 'T00:00:00.000Z'
            filterCondition = advancedFilter.column + ' ge ' + "datetime'" + date + "'"
            break
        }
        case 'before inclusive': {
            // console.log(advancedFilter)
            // let date1 = advancedFilter.values[0].value.split('T')[0] + 'T00:00:00.000Z'
            // let date2 = advancedFilter.values[1].value.split('T')[0] + 'T23:59:59.999Z'
            const value = advancedFilter.values.find((v) => v.value !== '').value
            let date = value.split('T')[0] + 'T23:59:59.999Z'
            filterCondition = advancedFilter.column + " le datetime'" + date + "'"
            break
        }

        case 'between': {
            let date1 = advancedFilter.values[0].value.split('T')[0] + 'T00:00:00.000Z'
            let date2 = advancedFilter.values[1].value.split('T')[0] + 'T23:59:59.999Z'
            filterCondition = advancedFilter.column + " gt datetime'" + date1 + "' and " + advancedFilter.column + " lt datetime'" + date2 + "'"
            break
        }

        case 'between inclusive': {
            let date1 = advancedFilter.values[0].value.split('T')[0] + 'T00:00:00.000Z'
            let date2 = advancedFilter.values[1].value.split('T')[0] + 'T23:59:59.999Z'
            filterCondition = advancedFilter.column + " ge datetime'" + date1 + "' and " + advancedFilter.column + " le datetime'" + date2 + "'"
            break
        }
        case 'is NULL': {
            filterCondition = filterCondition + `${advancedFilter.column} eq null`
            break
        }

        case 'is not NULL': {
            filterCondition = filterCondition + `${advancedFilter.column} ne null`
            break
        }

        case 'today': {
            const date = new Date()
            const isoDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString()
            let date1 = isoDate.split('T')[0] + 'T00:00:00.000Z'
            let date2 = isoDate.split('T')[0] + 'T23:59:59.999Z'
            filterCondition = advancedFilter.column + " ge datetime'" + date1 + "' and " + advancedFilter.column + " le datetime'" + date2 + "'"
            break
        }

        case 'week': {
            const date = new Date()
            let start = startOfWeek(date, {weekStartsOn: 1})
            let end = endOfWeek(date, {weekStartsOn: 1})
            const date1 = dateToDatabaseString(start)
            const date2 = dateToDatabaseString(end)
            filterCondition = advancedFilter.column + " ge datetime'" + date1 + "' and " + advancedFilter.column + " le datetime'" + date2 + "'"
            break
        }

        case 'last week': {
            const date = new Date()
            let start = startOfWeek(date, {weekStartsOn: 1})
            start = sub(start, {weeks: 1})
            let end = endOfWeek(start, {weekStartsOn: 1})
            const date1 = dateToDatabaseString(start)
            const date2 = dateToDatabaseString(end)
            filterCondition = advancedFilter.column + " ge datetime'" + date1 + "' and " + advancedFilter.column + " le datetime'" + date2 + "'"
            break
        }

        case 'next week': {
            const date = new Date()
            let start = startOfWeek(date, {weekStartsOn: 1})
            start = add(start, {weeks: 1})
            let end = endOfWeek(start, {weekStartsOn: 1})
            const date1 = dateToDatabaseString(start)
            const date2 = dateToDatabaseString(end)
            filterCondition = advancedFilter.column + " ge datetime'" + date1 + "' and " + advancedFilter.column + " le datetime'" + date2 + "'"
            break
        }

        case 'month': {
            const date = new Date()
            let start = startOfMonth(date)
            let end = endOfMonth(date)
            const date1 = dateToDatabaseString(start)
            const date2 = dateToDatabaseString(end)
            filterCondition = advancedFilter.column + " ge datetime'" + date1 + "' and " + advancedFilter.column + " le datetime'" + date2 + "'"
            break
        }

        case 'last month': {
            const date = new Date()
            let start = sub(date, {months: 1})
            start = startOfMonth(start)
            let end = endOfMonth(start)
            const isoDate = dateToDatabaseString(start)
            const isoDate2 = dateToDatabaseString(end)
            filterCondition = advancedFilter.column + " ge datetime'" + isoDate + "' and " + advancedFilter.column + " le datetime'" + isoDate2 + "'"
            break
        }

        case 'next month': {
            const date = new Date()
            let start = startOfMonth(date)
            start = add(start, {months: 1})
            let end = endOfMonth(start)
            const date1 = dateToDatabaseString(start)
            const date2 = dateToDatabaseString(end)
            filterCondition = advancedFilter.column + " ge datetime'" + date1 + "' and " + advancedFilter.column + " le datetime'" + date2 + "'"
            break
        }

    }
    // console.log('filterCondition', filterCondition)
    return filterCondition
}
export const dateToDatabaseString = (date: Date): string => {
    if (!date) {
        return null
    }
    let months = date.getMonth() + 1
    let monthString = months < 10 ? '0' + months : '' + months

    let days = date.getDate()
    let daysString = days < 10 ? '0' + days : '' + days

    let hours = date.getHours()
    let hoursString = hours < 10 ? '0' + hours : '' + hours

    let minutes = date.getMinutes()
    let minutesString = minutes < 10 ? '0' + minutes : '' + minutes

    let seconds = date.getSeconds()
    let secondsString = seconds < 10 ? '0' + seconds : '' + seconds

    let miliSeconds = date.getMilliseconds()
    let miliSecondsString = miliSeconds < 10 ? '00' + miliSeconds : miliSeconds < 100 ? '0' + miliSeconds : '' + miliSeconds
    return date.getFullYear() + '-' + monthString + '-' + daysString + 'T' + hoursString + ':' + minutesString + ':' + secondsString + '.' + miliSecondsString + 'Z'
}
const createBooleanFilterString = (advancedFilter: AdvancedFilterElement) => {
    let filterCondition = ''
    switch (advancedFilter.comparator) {
        case '=': {
            for (let i = 0; i < advancedFilter.values.length; i++) {
                filterCondition = filterCondition + advancedFilter.column + ' eq ' + advancedFilter.values[i].value + ' or '
            }
            filterCondition = filterCondition.substring(0, filterCondition.length - 4)
            break
        }
        case '!=': {
            for (let i = 0; i < advancedFilter.values.length; i++) {
                filterCondition = filterCondition + advancedFilter.column + ' ne ' + advancedFilter.values[i].value + ' or '
            }
            filterCondition = filterCondition.substring(0, filterCondition.length - 4)
            break
        }
        case 'is NULL': {
            filterCondition = filterCondition + `${advancedFilter.column} eq null`
            break
        }

        case 'is not NULL': {
            filterCondition = filterCondition + `${advancedFilter.column} ne null`
            break
        }
    }
    return filterCondition
}
const createEntityCodeListFilterString = (advancedFilter: AdvancedFilterElement) => {
    let filterCondition = ''
    switch (advancedFilter.comparator) {
        case '=': {
            for (let i = 0; i < advancedFilter.values.length; i++) {
                filterCondition = filterCondition + advancedFilter.column + " eq guid'" + advancedFilter.values[i].value + "' or "
            }
            filterCondition = filterCondition.substring(0, filterCondition.length - 4)
            break
        }
        case '!=': {
            for (let i = 0; i < advancedFilter.values.length; i++) {
                filterCondition = filterCondition + advancedFilter.column + " ne guid'" + advancedFilter.values[i].value + "' and "
            }
            filterCondition = filterCondition.substring(0, filterCondition.length - 5)
            break
        }
        case 'current user': {
            let userId = window['bc'].parameterService.appParameters.userId
            filterCondition = filterCondition + `${advancedFilter.column} eq guid'${userId}'`
            break
        }

        case 'is NULL': {
            filterCondition = filterCondition + `${advancedFilter.column} eq null`
            break
        }

        case 'is not NULL': {
            filterCondition = filterCondition + `${advancedFilter.column} ne null`
            break
        }
    }
    return filterCondition
}
const createGuidFilterString = (advancedFilter: AdvancedFilterElement) => {
    // console.log('=====> createGuidFilterString')
    let filterCondition = ''
    switch (advancedFilter.comparator) {
        case '=': {
            for (let i = 0; i < advancedFilter.values.length; i++) {
                filterCondition = filterCondition + advancedFilter.column + " eq guid'" + advancedFilter.values[i].value + "' or "
            }
            filterCondition = filterCondition.substring(0, filterCondition.length - 4)
            break
        }
        case '!=': {
            for (let i = 0; i < advancedFilter.values.length; i++) {
                filterCondition = filterCondition + advancedFilter.column + " ne guid'" + advancedFilter.values[i].value + "' and "
            }
            filterCondition = filterCondition.substring(0, filterCondition.length - 5)
            break
        }
        case 'is NULL': {
            filterCondition = filterCondition + `${advancedFilter.column} eq null`
            break
        }

        case 'is not NULL': {
            filterCondition = filterCondition + `${advancedFilter.column} ne null`
            break
        }
    }
    return filterCondition
}
const createSpatialFilterString = (advancedFilter: AdvancedFilterElement) => {
    let filterCondition = ''
    switch (advancedFilter.comparator) {
        case '=': {
            filterCondition = `$spatialfilter=geo.intersects('${advancedFilter.column}',geometry'SRID=2056;GEOMETRYCOLLECTION(${advancedFilter.values[0].value})')`
            break
        }
    }
    return filterCondition
}
export const createAdvancedFilterConditionString = (advancedFilter: AdvancedFilterElement) => {
    let filterCondition = ''

    switch (advancedFilter.type) {
        case 'string': {
            filterCondition = createStringFilterCondition(advancedFilter)
            break
        }
        case 'decimal':
        case 'number': {
            filterCondition = createNumberFilterString(advancedFilter)
            break
        }
        case 'boolean': {
            filterCondition = createBooleanFilterString(advancedFilter)
            break
        }
        case 'date': {
            filterCondition = createDateFilterString(advancedFilter)
            break
        }

        case 'codelist':
        case 'entity': {
            filterCondition = createEntityCodeListFilterString(advancedFilter)
            break
        }

        case 'guid': {
            filterCondition = createGuidFilterString(advancedFilter)
            break
        }

        case 'spatial-filter': {
            filterCondition = createSpatialFilterString(advancedFilter)
            break
        }
    }
    return filterCondition
}
//================================================================================================
// TODO make comparator lists language independent and the same across all create filter function!

export const createFilter = (options: {
    filterExpressionNumber: number
    filterExpressionJoinType: 'and' | 'or' | ''
    columnName: string
    joinType: 'and' | 'or' | null
    metadata: EntityType
    comparator: string
    intable: boolean,
    isCalendarFilter?: boolean
}) => {
    const {
        filterExpressionNumber,
        filterExpressionJoinType,
        columnName,
        joinType,
        metadata,
        comparator,
        intable
    } = options

    let isCalendarFilter = options.isCalendarFilter ? options.isCalendarFilter : false
    let type = ''
    let comparatorList = []

    // console.log('metadata', metadata)
    let entityColumn = null
    if (metadata.geometryField && metadata.geometryField === columnName) {
        entityColumn = metadata.getField(columnName + '.Geometry.WellKnownText')
    } else {
        entityColumn = metadata.getField(columnName)
    }

    try {
        switch (entityColumn.dataType) {
            case 'Int16':
            case 'Int32':
            case 'Int64': {
                type = 'number'
                comparatorList = [
                    {name: '=', value: '='},
                    {name: '!=', value: '!='},
                    {name: '> ', value: '>'},
                    {name: '<', value: '<'},
                    {name: '>=', value: '>='},
                    {name: '<=', value: '<='},
                    {name: 'ist NULL', value: 'is NULL'},
                    {name: 'ist nicht NULL', value: 'is not NULL'},
                ]
                break
            }
            case 'Decimal':
            case 'Double': {
                type = 'decimal'
                comparatorList = [
                    {name: '=', value: '='},
                    {name: '!=', value: '!='},
                    {name: '> ', value: '>'},
                    {name: '<', value: '<'},
                    {name: '>=', value: '>='},
                    {name: '<=', value: '<='},
                    {name: 'ist NULL', value: 'is NULL'},
                    {name: 'ist nicht NULL', value: 'is not NULL'},
                ]
                break
            }
            case 'Boolean': {
                type = 'boolean'
                comparatorList = [
                    {name: '=', value: '='},
                    {name: '!=', value: '!='},
                    {name: 'ist NULL', value: 'is NULL'},
                    {name: 'ist nicht NULL', value: 'is not NULL'},
                ]
                break
            }
            case 'EntityList': {
                type = 'entity'
                comparatorList = [
                    {name: '=', value: '='},
                    {name: '!=', value: '!='},
                    {name: 'ist NULL', value: 'is NULL'},
                    {name: 'ist nicht NULL', value: 'is not NULL'},
                    {name: 'Angemeldeter Anwender', value: 'current user'},
                ]
                break
            }
            case 'List': {
                type = 'codelist'
                comparatorList = [
                    {name: '=', value: '='},
                    {name: '!=', value: '!='},
                    {name: 'ist NULL', value: 'is NULL'},
                    {name: 'ist nicht NULL', value: 'is not NULL'},
                ]
                break
            }
            case 'String': {
                type = 'string'
                comparatorList = [
                    {name: '=', value: '='},
                    {name: '!=', value: '!='},
                    {name: 'enthält', value: 'contains'},
                    {name: 'ohne', value: 'contains not'},
                    {name: 'ist NULL', value: 'is NULL'},
                    {name: 'ist nicht NULL', value: 'is not NULL'},
                ]
                break
            }
            case 'Guid': {
                type = 'guid'
                comparatorList = [
                    {name: '=', value: '='},
                    {name: '!=', value: '!='},
                ]
                break
            }
            case 'Point': {
                type = 'spatial-filter'
                comparatorList = [
                    {name: '=', value: '='},
                    /*{ name: '!=', value: '!=' },*/
                ]
                break
            }
            case 'Polygon': {
                type = 'spatial-filter'
                comparatorList = [
                    {name: '=', value: '='},
                    /*{ name: '!=', value: '!=' },*/
                ]
                break
            }
            case 'DateTime': {
                type = 'date'
                comparatorList = [
                    {name: '=', value: '='},
                    {name: '!=', value: '!='},
                    {name: 'zwischen Daten', value: 'between'},
                    {name: 'von Datum bis zu Datum', value: 'between inclusive'},
                    {name: 'nach Datum', value: 'after'},
                    {name: 'ab Datum', value: 'after inclusive'},
                    {name: 'bevor Datum', value: 'before'},
                    {name: 'bis zu Datum', value: 'before inclusive'},
                    {name: 'ist NULL', value: 'is NULL'},
                    {name: 'ist nicht NULL', value: 'is not NULL'},
                    {name: 'heute', value: 'today'},
                    {name: 'diese Woche', value: 'week'},
                    {name: 'dieser Monat', value: 'month'},
                    {name: 'letzte Woche', value: 'last week'},
                    {name: 'letzter Monat', value: 'last month'},
                    {name: 'nächste Woche', value: 'next week'},
                    {name: 'nächster Monat', value: 'next month'},
                ]
                break
            }
        }
    } catch (error) {
        console.error('ERROR CREATING FILTER', 'entityColumn', entityColumn, 'columnName', columnName, 'metadata', metadata)
        handleErrorInAlert(error, 'Fehler beim erstellen eines Filters.')
        console.error('Fehler beim erstellen eines Filters.', error, 'options', options, entityColumn)
    }


    let comparatorFront = comparatorList.find((c) => c.value === comparator).name

    return {
        filterExpression: filterExpressionNumber,
        filterExpressionJoinType: filterExpressionJoinType,
        joinType: joinType,
        column: columnName,
        frontName: entityColumn.longName,
        comparator: comparator,
        comparatorFront: comparatorFront,
        type: type,
        values: [],
        valid: false,
        current: false,
        filterString: '',
        intable: intable,
        isCalendarFilter: isCalendarFilter
    } as AdvancedFilterElement
}

export const createAdvancedFilterObjectWithoutMetadata = (
    filterExpressionNumber: number,
    filterExpressionJoinType: 'and' | 'or' | '',
    columnName: string,
    joinType: 'and' | 'or' | null,
    comparator: string,
    columnFrontName: string,
    columnType:
        | 'Int16'
        | 'Int32'
        | 'Int64'
        | 'String'
        | 'Decimal'
        | 'Double'
        | 'Boolean'
        | 'List'
        | 'Guid'
        | 'EntityList'
        | 'DateTime'
        | 'Point'
        | 'MultiPoint'
        | 'Polygon'
        | 'MultiPolygon'
        | 'LineString'
        | 'MultiLineString'
    ,
) => {
    let type = ''
    let comparatorList = []
    switch (columnType) {
        case 'Int16':
        case 'Int32':
        case 'Int64': {
            type = 'number'
            comparatorList = [
                {name: '=', value: '='},
                {name: '!=', value: '!='},
                {name: '> ', value: '>'},
                {name: '<', value: '<'},
                {name: '>=', value: '>='},
                {name: '<=', value: '<='},
            ]

            break
        }
        case 'Decimal':
        case 'Double': {
            type = 'decimal'
            comparatorList = [
                {name: '=', value: '='},
                {name: '!=', value: '!='},
                {name: '> ', value: '>'},
                {name: '<', value: '<'},
                {name: '>=', value: '>='},
                {name: '<=', value: '<='},
            ]
            break
        }
        case 'Boolean': {
            type = 'boolean'
            comparatorList = [
                {name: '=', value: '='},
                {name: '!=', value: '!='},
            ]
            break
        }
        case 'EntityList': {
            type = 'entity'
            comparatorList = [
                {name: '=', value: '='},
                {name: '!=', value: '!='},
            ]
            break
        }
        case 'List': {
            type = 'codelist'
            comparatorList = [
                {name: '=', value: '='},
                {name: '!=', value: '!='},
            ]
            break
        }
        case 'String': {
            type = 'string'
            comparatorList = [
                {name: '=', value: '='},
                {name: '!=', value: '!='},
                {name: 'enthält', value: 'contains'},
                {name: 'ohne', value: 'contains not'},
            ]
            break
        }
        case 'Guid': {
            type = 'guid'
            comparatorList = [
                {name: '=', value: '='},
                {name: '!=', value: '!='},
            ]
            break
        }
        case 'Polygon':
        case 'MultiPolygon':
        case 'LineString':
        case 'MultiLineString':
        case 'Point':
        case 'MultiPoint': {
            type = 'spatial-filter'
            comparatorList = [
                {name: '=', value: '='},
                /*{ name: '!=', value: '!=' },*/
            ]
            break
        }

        case 'DateTime': {
            type = 'date'
            comparatorList = [
                {name: '=', value: '='},
                {name: '!=', value: '!='},
                {name: 'zwischen Daten', value: 'between'},
                {name: 'von Datum bis zu Datum', value: 'between inclusive'},
                {name: 'nach Datum', value: 'after'},
                {name: 'ab Datum', value: 'after inclusive'},
                {name: 'bevor Datum', value: 'before'},
                {name: 'bis zu Datum', value: 'before inclusive'},
            ]
            break
        }
    }

    let comparatorFront = comparatorList.find((c) => c.name === comparator)

    return {
        filterExpression: filterExpressionNumber,
        filterExpressionJoinType: filterExpressionJoinType,
        joinType: joinType,
        column: columnName,
        frontName: columnFrontName,
        comparator: comparator,
        comparatorFront: comparatorFront,
        type: type,
        values: [],
        valid: false,
        current: false,
        filterString: ''
    } as AdvancedFilterElement
}


export const addValuesToAdvancedFilter = (filter: AdvancedFilterElement, data: { name: string; value: string }[]) => {
    try {
        filter.values = [...filter.values, ...data]
        checkIfFilterValid(filter)
    } catch (error) {
        console.error(error)
        console.trace(error)
        // handleErrorInAlert(error, 'Fehler')
    }
}
export const checkIfFilterValid = (filter: AdvancedFilterElement) => {


    if (filter.values.length > 0) {

        for (let i = 0; i < filter.values.length; i++) {
            if (filter.type === 'guid') {
                let value = filter.values[i]
                if (/[[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}]*/gi.test(value.value) === false) {
                    throw 'Unvalid Guid as filter value:' + value
                }

            }
        }


        if (filter.type !== 'date') {
            filter.valid = true
            filter.filterString = createAdvancedFilterConditionString(filter)
        } else if (filter.type === 'date') {
            if (filter.comparator === 'between' || filter.comparator === 'between inclusive') {
                if (filter.values.length === 2) {
                    filter.valid = true
                    filter.filterString = createAdvancedFilterConditionString(filter)
                } else {
                    filter.valid = false
                    filter.filterString = ''
                }
            } else {

                const value = filter.values.find((v) => v.value !== '')
                if (value) {
                    filter.valid = true
                    filter.filterString = createAdvancedFilterConditionString(filter)
                } else {
                    if (['week', 'month', 'today', 'last week', 'last month', 'next week', 'next month'].includes(filter.comparator)) {
                        filter.valid = true
                        filter.filterString = createAdvancedFilterConditionString(filter)
                    } else {
                        filter.valid = false
                        filter.filterString = ''
                    }
                }
            }
        }
    } else if (filter.values.length === 0 && (filter.comparator === 'is NULL' || filter.comparator === 'is not NULL' || filter.comparator === 'is Empty'
        || filter.comparator === 'week' || filter.comparator === 'month' || filter.comparator === 'today' || filter.comparator === 'last week'
        || filter.comparator === 'next week' || filter.comparator === 'last month' || filter.comparator === 'next month' || filter.comparator === 'current user')) {
        filter.filterString = createAdvancedFilterConditionString(filter)
        filter.valid = true
    } else {
        filter.valid = false
        filter.filterString = ''
        throw 'Filter is invalid'
    }

}
export const createFinalFilterString = (advancedFilter: AdvancedFilterElement[]) => {
    // console.log('------------------createFinalFilterString---------------')
    let normalFilterString = ''
    let spatialFilterString = ''
    let finalFilterString = ''
    let validFilters = advancedFilter.filter((ad) => ad.valid === true && ad.type !== 'spatial-filter')
    let spatialFilter = advancedFilter.filter((ad) => ad.valid === true && ad.type === 'spatial-filter')

    ///////////////////////////////////////////////////////////////
    // CALENDAR/TIMELINE FILTERS
    ///////////////////////////////////////////////////////////////
    const filterNumbersCalendar = validFilters.filter(f => f.isCalendarFilter).map((a) => a.filterExpression)
    const filterNumbersUniqueCalendar = filterNumbersCalendar.filter((value, index, self) => {
        return self.indexOf(value) === index
    })

    // console.log('filterNumbers', filterNumbersUniqueCalendar)
    let numberOfDefaultFiltersCalendar = filterNumbersUniqueCalendar.filter(n => n <= 0)

    let openParenthesisAddedCalendar = false
    let closeParenthesisAddedCalendar = false
    let filterNumberBecamePositif = false


    for (let i = 0; i < filterNumbersUniqueCalendar.length; i++) {
        let number = filterNumbersUniqueCalendar[i]
        const filterObjects = validFilters.filter((a) => a.filterExpression === number)
        let joinType = filterObjects[0].filterExpressionJoinType

        normalFilterString = ''

        for (let j = 0; j < filterObjects.length; j++) {
            let filter = filterObjects[j]

            let filterString = ''
            if (j === 0) {
                filterString = '(' + createAdvancedFilterConditionString(filter) + ')'
            } else {
                filterString = ' ' + filter.joinType + ' (' + createAdvancedFilterConditionString(filter) + ')'
            }
            normalFilterString = normalFilterString + filterString
        }

        if (i === 0) {
            if (numberOfDefaultFiltersCalendar.length > 0) {
                finalFilterString = finalFilterString + '((' + normalFilterString + ')'
                openParenthesisAddedCalendar = true

            } else {
                finalFilterString = finalFilterString + '(' + normalFilterString + ')'
            }
        } else {
            if (numberOfDefaultFiltersCalendar.length > 0 && number > 0 && filterNumberBecamePositif === false) {
                finalFilterString = finalFilterString + ')' + joinType + '(' + normalFilterString + ')'
                closeParenthesisAddedCalendar = true
                filterNumberBecamePositif = true
            } else {
                finalFilterString = finalFilterString + joinType + '(' + normalFilterString + ')'
            }
        }
    }

    if (openParenthesisAddedCalendar && !closeParenthesisAddedCalendar) {
        finalFilterString = finalFilterString + ')'
    }


    ///////////////////////////////////////////////////////////////
    // OTHER FILTERS
    ///////////////////////////////////////////////////////////////
    const filterNumbers = validFilters.filter(f => !f.isCalendarFilter).map((a) => a.filterExpression)
    const filterNumbersUnique = filterNumbers.filter((value, index, self) => {
        return self.indexOf(value) === index
    })

    if (filterNumbers.length > 0 && finalFilterString !== '') {
        finalFilterString = finalFilterString + ' and '
    }

    // console.log('filterNumbers', filterNumbers)
    let numberOfDefaultFilters = filterNumbers.filter(n => n <= 0)

    // console.log('validFilters', validFilters)
    // console.log('numberOfDefaultFilters', numberOfDefaultFilters)
    // console.log('spatialFilter', spatialFilter)

    filterNumbersUnique.sort()

    let openParenthesisAdded = false
    let closeParenthesisAdded = false

    let becamePositif = false


    for (let i = 0; i < filterNumbersUnique.length; i++) {
        let number = filterNumbersUnique[i]
        const filterObjects = validFilters.filter((a) => a.filterExpression === number)
        let joinType = filterObjects[0].filterExpressionJoinType
        // console.log('joinType', joinType)

        normalFilterString = ''


        for (let j = 0; j < filterObjects.length; j++) {
            let filter = filterObjects[j]

            let filterString = ''
            if (j === 0) {
                filterString = '(' + createAdvancedFilterConditionString(filter) + ')'
            } else {
                filterString = ' ' + filter.joinType + ' (' + createAdvancedFilterConditionString(filter) + ')'
            }
            normalFilterString = normalFilterString + filterString
        }

        if (i === 0) {
            if (numberOfDefaultFilters.length > 0) {
                finalFilterString = finalFilterString + '((' + normalFilterString + ')'
                openParenthesisAdded = true

            } else {
                finalFilterString = finalFilterString + '(' + normalFilterString + ')'
            }
        } else {
            if (numberOfDefaultFilters.length > 0 && number > 0 && becamePositif === false) {
                finalFilterString = finalFilterString + ')' + joinType + '(' + normalFilterString + ')'
                closeParenthesisAdded = true
                becamePositif = true
            } else {
                finalFilterString = finalFilterString + joinType + '(' + normalFilterString + ')'
            }
        }
    }

    if (openParenthesisAdded && !closeParenthesisAdded) {
        finalFilterString = finalFilterString + ')'
    }

    if (spatialFilter.length > 0) {
        spatialFilterString = createAdvancedFilterConditionString(spatialFilter[0])
    }

    // console.log('finalFilterString', finalFilterString)
    if (finalFilterString !== '' && finalFilterString !== '()') {
        finalFilterString = '$filter=' + finalFilterString + ''
        // console.log('finalFilterString not empty', finalFilterString)
    } else {
        finalFilterString = ''
        // console.log('finalFilterString empty')
    }
    if (spatialFilter.length > 0 && spatialFilterString !== '') {
        // console.log('spatialFilter not empty', spatialFilter)
        finalFilterString = finalFilterString + '&' + spatialFilterString
    }
    // console.log('FINAL FILTER', finalFilterString)
    return finalFilterString
}
export const createDefaultActionFilters = (metadata: EntityType, tabletype: string, entityguid: string) => {
    try {
        let defaultFilters: AdvancedFilterElement[] = []
        if (metadata.resourceName === 'UgdmActions') {
            if (tabletype === 'UserActions' && bc.parameterService.appParameters.userId) {
                const userIdFilter: AdvancedFilterElement = createFilter({
                    filterExpressionNumber: -1,
                    filterExpressionJoinType: 'and',
                    columnName: 'AC_ACTOR',
                    joinType: 'and',
                    metadata: metadata,
                    comparator: '=',
                    intable: false,
                })
                addValuesToAdvancedFilter(userIdFilter, [
                    {
                        name: metadata.getColumnLongName('AC_ACTOR'),
                        value: bc.parameterService.appParameters.userId
                    },
                ])
                defaultFilters = [userIdFilter]
            } else {
                const entityFilter: AdvancedFilterElement = createFilter({
                    filterExpressionNumber: -1,
                    filterExpressionJoinType: 'and',
                    columnName: 'AC_ENTITY',
                    joinType: 'and',
                    metadata: metadata,
                    comparator: '=',
                    intable: false,
                })
                addValuesToAdvancedFilter(entityFilter, [{
                    name: metadata.getColumnLongName('AC_ENTITY'),
                    value: entityguid
                }])
                defaultFilters = [entityFilter]
            }
            let actionCodeFilter: AdvancedFilterElement

            // If there is a default value for the AC_CODE, create additional filter
            // (This is for specific action types)
            const acCodeEntity = metadata.getField('AC_CODE')
            if (acCodeEntity.defaultValue !== undefined) {
                actionCodeFilter = createFilter({
                    filterExpressionNumber: -1,
                    filterExpressionJoinType: 'and',
                    columnName: 'AC_CODE',
                    joinType: 'and',
                    metadata: metadata,
                    comparator: '=',
                    intable: false,
                })
                addValuesToAdvancedFilter(actionCodeFilter, [{
                    name: acCodeEntity.longName,
                    value: acCodeEntity.defaultValue.toString()
                }])
            }
            // Setup default Filter
            if (actionCodeFilter) {
                defaultFilters.push(actionCodeFilter)
            }
        }
        return defaultFilters
    } catch (error) {
        handleErrorInAlert(error, 'Error handling ActionFilters in ObjectTable.')
        console.error('Error handling ActionFilters in ObjectTable.', error)
        return []
    }
}
export const combineFiltersWithDefaultActionFilters = (
    tabletype: string,
    dataviewSearch: AdvancedDataviewSearch,
    defaultFilters: AdvancedFilterElement[],
) => {
    let hasActionCodeFilter = !!defaultFilters.find((f) => f.column === 'AC_CODE')
    dataviewSearch.filters = hasActionCodeFilter
        ? [...defaultFilters, ...dataviewSearch.filters.filter((f) => f.column !== 'AC_ENTITY' && f.column !== 'AC_CODE')]
        : [...defaultFilters, ...dataviewSearch.filters.filter((f) => f.column !== 'AC_ENTITY')]
}