// import bc from 'bc'
import { writable } from 'svelte/store';
export const messageCache = writable(new Map<string, Array<{ id: string; fn: Function }>>());

import { parameterService } from "./parameterService";

export interface MessageServiceElement {
    topic: string
    callbacks: Array<{ id: string; fn: Function }>
}

export interface MessageServiceInterface {
    cache: Array<MessageServiceElement>
    subscribe: (topic: string, idSubscriber: string, callback: Function) => void
    publish: (topic: string, args?: any) => void
    savePublish: (topic: string, args?: any) => void
    publishCallback: (topic: string, callback: Function, args?: any) => void
    unsubscribe: (topic: string, idSubscriber: string) => void
    unsubscribeComponent: (idSubscriber: string) => void
}


export const createMessageService = () => {
    let myCache: MessageServiceElement[] = []
    let called = new Map<string, number>()

    // this is the callback cache
    let myNewCache;
    messageCache.subscribe((value) => {
		myNewCache = value;
        // console.log("message cache updated");
	});

    // console.log("createMessageService called", myNewCache); // is called twice, why?
    
    // this is the list of events a component supports, the callbacks are then stored in myNewCache
    let appartenanceCache = new Map<string, Array<string>>() // store a list of strings for a subscriber id

    // the "list" is the appartenanceCache
    const addToList = ( idSubscriber: string, topic: string)=>{
        let compEnty = appartenanceCache.get(idSubscriber) // get the array for subscriber id
        if(compEnty){
            if(!compEnty.includes(topic)){
                compEnty.push(topic)
            }
        }else{
            appartenanceCache.set(idSubscriber, [topic]) // create the entry for a new subscriber id
        }
    }

    // the "list" is the appartenanceCache
    const removeFromList = ( idSubscriber: string, topic: string)=>{
        let compEnty = appartenanceCache.get(idSubscriber)
        if(compEnty){
            let index = compEnty.indexOf(topic)
            if(index !== -1){
                compEnty.splice(index, 1)
            }
        }
    }

    return {
        newCache: myNewCache,
        appartenance: appartenanceCache,
        cache: myCache,
        called: called,

        subscribe: (topic: string,  idSubscriber: string, callback: Function) => {

            // console.log("subscribe " + topic + " " + idSubscriber, myNewCache);
            let entry = myNewCache.get(topic)
            if(entry){
                let hasSameFn = false
                for(let i = 0; i< entry.length; i++){
                    if(entry[i].fn === callback){
                        if(entry[i].id === idSubscriber) { // added for layer switcher: only if it is the same subscriber you can leave out registration
                            hasSameFn = true;
                            break;
                        }
                    }
                }

                if(!hasSameFn){
                    entry.push({ id: idSubscriber, fn: callback})
                    addToList(idSubscriber, topic) // adds to appartenanceCache, i.e. all callbacks are also "appartenance"
                    myNewCache.delete(topic);
                    myNewCache.set(topic, entry)
                    // myNewCache.set(topic, entry.push({ id: idSubscriber, fn: callback}))
                    // console.log("subscribe " + topic + " " + idSubscriber + " subscribed");
                }

            }else{
                myNewCache.set(topic, [{ id: idSubscriber, fn: callback}])
                addToList(idSubscriber, topic) // adds to appartenanceCache
            }

            // update the message cache store
            messageCache.set(myNewCache);

        },

        publish: (topic: string, ...args: any) => {
            try{
                let entry = myNewCache.get(topic)
                if(entry){
                    console.log("Topic " + topic + " has " + entry.length + " entries", entry);
                    for (let i = 0; i < entry.length; i++) {
                        entry[i].fn(...args);
                    }
                }
            }catch(error){
                console.error('error in publish', topic, args)
                console.error(error)
            }
        },

        savePublish: (topic: string, args: any) => {
            try{
                let publishEvent = false
                let value = parameterService.get(topic)
                if(value === null){
                    publishEvent = true
                }else{
                    if(value !== args){
                        publishEvent = true
                    }
                }
                parameterService.set(topic, args)
                if(publishEvent){
                    
                    let entry = myNewCache.get(topic)
                    if(entry){
                        for (let i = 0; i < entry.length; i++) {
                            entry[i].fn(args)
                        }
                    }
                }
            }catch(error){
                console.error('error in publish', topic, args)
                console.error(error)
            }
        },

        publishCallback: (topic: string, callback: Function, args?: any) => {

            let entry = myNewCache.get(topic)
            if(entry){
                for (let i = 0; i < entry.length; i++) {
                    callback(entry[i].fn(args))
                }
            }
        },

        unsubscribe: (topic: string, idSubscriber: string) => {
            
            let entry = myNewCache.get(topic)
            if(entry){
                let newValue = []
                for(let i = 0; i< entry.length; i++){
                    if(entry[i].id !== idSubscriber){
                        newValue.push(entry[i])
                    }
                }

                if(newValue.length === 0){
                    myNewCache.delete(topic)
                }else{
                    myNewCache.set(topic, newValue)
                }
            }
            removeFromList(idSubscriber, topic)
            
            // update the message cache store
            messageCache.set(myNewCache);
        },

        unsubscribeComponent: (idSubscriber: string) => {
            // let startTime = performance.now()
            if(idSubscriber !== undefined  && idSubscriber !== null && idSubscriber !== ''){
                let componentEventsArray = appartenanceCache.get(idSubscriber)
                if(componentEventsArray){
                    
                    for(let i= 0; i< componentEventsArray.length; i++){
                        let entry = myNewCache.get(componentEventsArray[i])
                        if(entry){
                            for (let j = entry.length -1; j >= 0; j--) {
                                if(entry[j].id === idSubscriber){
                                    entry.splice(j, 1)
                                }
                            }
                        }

                        if(entry.length === 0 ){
                            myNewCache.delete(componentEventsArray[i])
                        }
                    }
                    appartenanceCache.delete(idSubscriber)

                    // update the message cache store
                    messageCache.set(myNewCache);
                }
            }
            // let endTime = performance.now()
            // console.log(`Call to doSomething took ${endTime - startTime} milliseconds`)
        },
    }
}

export const messageService: MessageServiceInterface = createMessageService()
