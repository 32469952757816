import {EntityType} from "../services/metadataManager";
import bc from "bc";
import {PARAMS} from "../services/parameterService";
import {errorLog} from "./consoleUtils";

/*******************************************
 * Entity / Dataview Utils
 *******************************************/

export const isUserDefinedView = (resourceName: string) => {
    switch (resourceName) {
        case 'RELATIONSHIP':
        case 'UgdmActions':
        case 'UgdmArtifacts':
        case 'UgdmAssociations':
        case 'UgdmAttachments':
        case 'UgdmAttributes':
        case 'UgdmDataViewRoles':
        case 'UgdmDomainRoles':
        case 'UgdmDomains':
        case 'UgdmEntities':
        case 'UgdmEntityDomains':
        case 'UgdmEntityLocations':
        case 'UgdmEntityLocs':
        case 'UgdmEntityMeasurementTypes':
        case 'UgdmEntityTypeAttributes':
        case 'UgdmEntityTypes':
        case 'UgdmEntityUsers':
        case 'UgdmEntityViewAttributes':
        case 'UgdmEntityViews':
        case 'UgdmHtmPageRoles':
        case 'UgdmLocationTypes':
        case 'UgdmMeasurements':
        case 'UgdmMeasurementTypes':
        case 'UgdmReferences':
        case 'UgdmRelationshipAssociations':
        case 'UgdmRelationships':
        case 'UgdmRoles':
        case 'UgdmRouteEvents':
        case 'UgdmSrs':
        case 'UgdmSystems':
        case 'UgdmTypeDomains':
        case 'UgdmTypeRelationships':
        case 'UgdmTypeRoles':
        case 'UgdmTypes':
        case 'UgdmTypesWithChildren':
        case 'UgdmUnits':
        case 'UgdmUserClaims':
        case 'UgdmUserLogins':
        case 'UgdmUserRoles':
        case 'UgdmUsers': {
            return false
        }
        default: {
            return true
        }
    }
}
/**
 * > Returns true if the object metadata has a geometry field
 * @param {EntityType} objectMetadata - The metadata of the object you're trying to check.
 * @returns A function that takes an objectMetadata and returns a boolean.
 */
export const metadataHasGeo = (objectMetadata: EntityType) => {
    return objectMetadata.geometryField && objectMetadata.geometryField !== ''
}
/**
 * It returns the rights of the current user for a given view
 * @param data - { objectMetadata: EntityType,  sourceviewguid: string | null,isBackend: boolean}
 * @returns an object with the following properties:
 */
export const getViewRights = async (data: { objectMetadata: EntityType, sourceviewguid: string | null, isBackend: boolean }) => {
    if (bc.parameterService.appParameters.authenticationRequired === false) {
        return {
            canCreate: true,
            canDelete: true,
            canModify: true,
            canRead: true,
        }
    }

    const {objectMetadata, sourceviewguid, isBackend} = data
    // console.log('objectMetadata', objectMetadata,)
    let id = objectMetadata.resourceId
    let viewRights = {
        canCreate: true,
        canDelete: true,
        canModify: true,
        canRead: true,
    }

    if (isBackend) {
        return viewRights
    } else {
        if (
            objectMetadata.resourceName === 'UgdmTypes' ||
            objectMetadata.resourceName === 'UgdmAttributes' ||
            objectMetadata.resourceName === 'UgdmDomains' ||
            objectMetadata.resourceName === 'UgdmUsers'
        ) {
            viewRights = {
                canCreate: true,
                canDelete: true,
                canModify: true,
                canRead: true,
            }
            return viewRights
        }

        // Actions in Dashboard
        if (objectMetadata.resourceName === 'UgdmActions') {
            if (isBackend || objectMetadata.resourceId === '8ba03c3e-429c-479b-bfa8-8df102adec48') {
                viewRights = {
                    canCreate: true,
                    canDelete: true,
                    canModify: true,
                    canRead: true,
                }
                return viewRights
            } else {
                id = sourceviewguid
            }
        }

        // Measurments ==> Gleiche Rechte wie die SourceView?
        if (objectMetadata.resourceName === 'UgdmMeasurements') {
            id = sourceviewguid
        }

        // UgdmActions ==> Gleiche Rechte wie die SourceView
        if (objectMetadata.resourceName === 'UgdmActions') {
            id = sourceviewguid
        }

        // UgdmArtifacts ==> Gleiche Rechte wie die SourceView
        if (objectMetadata.resourceName === 'UgdmArtifacts') {
            id = sourceviewguid
        }

        let rights = bc.parameterService.get('RIGHTS')

        if (rights && rights[id]) {
            viewRights = rights[id]
            // console.log('found view rights in parameterService', viewRights)
        } else {
            let userRoles = bc.parameterService.get(PARAMS.user.roleIds)
            if (userRoles) {
                // console.log('userRights', userRoles, 'userid', id)
                viewRights = await bc.metadataManager.fetchViewRights(userRoles, id)
                if (rights) {
                    rights[id] = viewRights
                }
                bc.parameterService.set('RIGHTS', rights)
            } else {
                // User hat keine Rolle => welche Rechte?
                errorLog('VIEWRIGHTS', 'USER has no assigned ROLE')
                return (viewRights = {
                    canCreate: false,
                    canDelete: false,
                    canModify: false,
                    canRead: false,
                })
            }
        }
        return viewRights
    }
}