import Swal from "sweetalert2";
import bc from "bc";
import {copyToClipboard} from "./generalUtils";

/********************************************
 * Error / Alert / Notification Utils
 **********************************************/


export const errorToast = (title: string, message?: string, timeout?: number) => {
    bc.messageService.publish('TOAST', {
        infoType: 'error',
        title: title,
        message: message ? message : '',
        timeout: timeout ? timeout : 2000,
    })
}
export const warn = (title: string, message?: string, timeout?: number) => {
    bc.messageService.publish('TOAST', {
        infoType: 'warning',
        title: title,
        message: message ? message : '',
        timeout: timeout ? timeout : 2000,
    })
}
export const notify = (title: string, message?: string, timeout?: number) => {
    bc.messageService.publish('TOAST', {
        infoType: 'info',
        title: title,
        message: message ? message : '',
        timeout: timeout ? timeout : 2000,
    })
}
export const success = (title: string, message?: string, timeout?: number) => {
    bc.messageService.publish('TOAST', {
        infoType: 'success',
        title: title,
        message: message ? message : '',
        timeout: timeout ? timeout : 3000,
    })
}
export const appendErrorInformation = (error: any, message: string) => {
    if (typeof error === 'object') {
        if (!error['information']) {
            error.information = message
        } else {
            error.information = error.information + '\n' + message
        }

    } else {
        error = error + '\n' + 'Information : ' + message
    }
    return error
}
export const customAlert = (message: string) => {
    Swal.fire({
        icon: 'info',
        title: message
    })
}
export const handleErrorInAlert = (error: any, title?: string) => {

    let errorString = ''

    if (typeof error === 'object') {
        errorString = JSON.stringify(error, null, 4)
    } else {
        errorString = error.toString()
    }

    Swal.fire({
        icon: 'error',
        title: title ? title : 'Ein Fehler ist aufgetreten',
        html: '<span style="display:flex; text-align:left; white-space: pre; flex-direction: column;">' + errorString + '</span>',
        showDenyButton: true,
        denyButtonText: 'Kopieren',
        denyButtonColor: '#3781dc',
        preDeny: () => {
            copyToClipboard(errorString).then(hasCopied => {
                if (hasCopied) {
                    success('In die Zwischenablage kopiert')
                } else {
                    errorToast('Kopieren nicht unterstützt')
                }

            })
            return false
        }
    })
}