import {EntityField, EntityType} from "../services/metadataManager";
import {PARAMS} from "../services/parameterService";

/**
 * It takes a date string and returns a formatted date string
 * @param {EntityType} metadata - EntityType
 * @param {EntityField} fieldInfo - EntityField
 * @param {string} value - The value of the field
 * @returns A string
 */
export const getDateInRawFormat = (metadata: EntityType, fieldInfo: EntityField, value: string) => {

    let locale = metadata.resource.TY_CULTURE
    let separator

    if (locale === '46ba9cdc-f416-421b-a38f-75715f6a1554') {
        separator = '.'
    } else {
        separator = '/'
    }


    if (
        (fieldInfo.viewLength === 0 && fieldInfo.viewPrecision === 0) ||
        (fieldInfo.viewLength === undefined && fieldInfo.viewPrecision === undefined)
    ) {
        let dateString = ''
        let workWeek

        const tSplit = value.split('T')
        const fullDateArray = tSplit[0].split('-')
        const fullTimeArray = tSplit[1].split(':')
        let hours = fullTimeArray[0]
        let minutes = fullTimeArray[1]
        // @ts-ignore
        let date = moment(`${fullDateArray[0]}-${fullDateArray[1]}-${fullDateArray[2]}`)
        // @ts-ignore
        workWeek = moment(date).isoWeek()
        if (+workWeek < 10) {
            workWeek = '0' + workWeek
        }
        workWeek = 'KW' + workWeek
        dateString = `${fullDateArray[2]}${separator}${fullDateArray[1]}${separator}${fullDateArray[0]} ${hours}:${minutes}`

        return `${workWeek}  ${dateString}`
        // TIME
    } else if (fieldInfo.viewLength === 0 && fieldInfo.viewPrecision === 8) {
        const tSplit = value.split('T')
        const fullTimeArray = tSplit[1].split(':')
        let hours = fullTimeArray[0]
        let minutes = fullTimeArray[1]
        return `${hours}:${minutes}`
        // DATE
    } else if (fieldInfo.viewLength === 10 && fieldInfo.viewPrecision === 0) {
        let dateString = ''
        let workWeek

        const tSplit = value.split('T')
        const fullDateArray = tSplit[0].split('-')
        // @ts-ignore
        let date = moment(`${fullDateArray[0]}-${fullDateArray[1]}-${fullDateArray[2]}`)
        // @ts-ignore
        workWeek = moment(date).isoWeek()
        if (+workWeek < 10) {
            workWeek = '0' + workWeek
        }
        workWeek = 'KW' + workWeek
        dateString = `${fullDateArray[2]}${separator}${fullDateArray[1]}${separator}${fullDateArray[0]}`

        return `${workWeek}  ${dateString}`
    }
}

/**
 * Takes an ISO date string and returns a formatted date string
 * @param {string} dateString - The ISO string to convert
 * @param {boolean} addTime - If true, the time will be added to the date string
 * @returns A string
 */
export const getDateTimeStringFromISOString = (dateString: string, addTime: boolean) => {
    if (dateString && dateString !== '') {
        let tempString = dateString.substring(0, 10).split('-')
        let timeString = dateString.split('T')[1].substring(0, 8)

        let clientCulture = bc.parameterService.appParameters.clientCulture
        // If it's a German/Swiss client
        if (clientCulture === 'de'
            || clientCulture === 'de-CH'
            || clientCulture === 'fr-CH'
            || clientCulture === 'it-CH'
            || clientCulture === 'rm-CH'
            || clientCulture === 'de-LI'
            || clientCulture === 'de-AT'
            || clientCulture === 'de-DE'
            || clientCulture === 'en-CH'
            ) {
                if (addTime) {
                    return `${tempString[2]}.${tempString[1]}.${tempString[0]}  ${timeString}`
                } else {
                    return `${tempString[2]}.${tempString[1]}.${tempString[0]}`
                }

            } else 
            // If it's a French client
            if (clientCulture === 'fr'
            || clientCulture === 'fr-FR') {
                if (addTime) {
                    return `${tempString[2]}/${tempString[1]}/${tempString[0]}  ${timeString}`
                } else {
                    return `${tempString[2]}/${tempString[1]}/${tempString[0]}`
                }
            // If it's an English client
            } else
            if (clientCulture === 'en'
            || clientCulture === 'en-GB'
            || clientCulture === 'en-US') {
                if (addTime) {
                    return `${tempString[2]}/${tempString[1]}/${tempString[0]}  ${timeString}`
                } else {
                    return `${tempString[2]}/${tempString[1]}/${tempString[0]}`
                }

            } else {
                if (addTime) {
                    return `${tempString[2]}/${tempString[1]}/${tempString[0]}  ${timeString}`
                } else {
                    return `${tempString[2]}/${tempString[1]}/${tempString[0]}`
                }
            }
    } else {
        return ''
    }
}

/**
 * Takes an ISO date string and returns a time string
 * @param {string} dateString - The ISO string to convert
 * @returns A string with the time
 */
export const getTimeStringFromISOString = (dateString: string) => {
    if (dateString && dateString !== '') {
        let tempString = dateString.substring(0, 10).split('-')
        let timeString = dateString.split('T')[1].substring(0, 8)
        return timeString;
    }
}

/**
 * It takes a string in the format of an ISO 8601 date and returns a Date object
 * @param {string} s - the string to parse
 * @returns A date object.
 */
export const parseISOString = (s: string) => {
    let b = s.split(/\D+/)
    let c = b.map((e) => +e)
    return new Date(Date.UTC(c[0], --c[1], c[2], c[3], c[4], c[5]))
}


/**
 * It takes a date and returns a string in the format of the current language
 * @param {Date} date - Date
 * @returns A string
 */
export const getHumanReadableDateFromDate = (date: Date): string => {

    let annee = date.getFullYear()
    let month: string | number = date.getMonth()
    let day: string | number = date.getDate()
    let hour: string | number = date.getHours()
    let minutes: string | number = date.getMinutes()
    let seconds: string | number = date.getSeconds()
    
    month = month + 1

    if (month < 10) {
        month = '0' + month
    }
    if (day < 10) {
        day = '0' + day
    }

    if (hour < 10) {
        hour = '0' + hour
    }
    if (seconds < 10) {
        seconds = '0' + seconds
    }
    if (minutes < 10) {
        minutes = '0' + minutes
    }

    let returnDate = `${annee}-${month}.${day}T${hour}:${minutes}:${seconds}`

    return getDateTimeStringFromISOString(returnDate,true);
}

/**
 * If the field is a date, return the format of the date
 * @param {EntityField} field - EntityField - this is the field object that you are trying to format.
 * @returns A function that returns a string.
 */
export const getDateFieldFormat = (field: EntityField): 'date' | 'datetime' | 'time' => {
    if (field.viewLength === 10 && field.viewPrecision === 0) {
        return 'date'
    }
    if (field.viewLength === 0 && field.viewPrecision === 8) {
        return 'time'
    }
    return 'datetime'
}


export const parseStringDateToISO = (value: string) => {
    if (value && value !== '') {
        // console.log(value)
        let day = ''
        let month = ''
        let year = ''
        let hours = '00'
        let minutes = '00'
        let seconds = '00'


        if (value.length === 19) {
            day = value.substring(0, 2)
            month = value.substring(3, 5)
            year = value.substring(6, 10)
            hours = value.substring(11, 13)
            minutes = value.substring(14, 16)
            seconds = value.substring(17, 19)

        }

        if (value.length === 10) {
            day = value.substring(0, 2)
            month = value.substring(3, 5)
            year = value.substring(6, 10)
        }

        const date = new Date(year + '-' + month + '-' + day)
        date.setHours(+hours)
        date.setMinutes(+minutes)
        date.setSeconds(+seconds)
        const isoDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString()
        console.log('isoDate', isoDate)
        return isoDate
    } else {
        return null
    }
}

export const parseStringDateToDate = (value: string) => {
    if (value && value !== '') {
        // console.log(value)
        let day = ''
        let month = ''
        let year = ''
        let hours = '00'
        let minutes = '00'
        let seconds = '00'

        if (value.length === 19) {
            day = value.substring(0, 2)
            month = value.substring(3, 5)
            year = value.substring(6, 10)
            hours = value.substring(11, 13)
            minutes = value.substring(14, 16)
            seconds = value.substring(17, 19)
        }

        if (value.length === 10) {
            day = value.substring(0, 2)
            month = value.substring(3, 5)
            year = value.substring(6, 10)
        }

        const date = new Date(year + '-' + month + '-' + day)
        date.setHours(+hours)
        date.setMinutes(+minutes)
        date.setSeconds(+seconds)
        console.log('parsed', new Date(date.getTime() - date.getTimezoneOffset() * 60000))
        return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    } else {
        return null
    }
}