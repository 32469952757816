// import bc from 'bc'
import {parameterService, ParameterServiceInterface, PARAMS} from './parameterService'
import {ajaxService, AjaxServiceInterface, RequestHeaderList, requestHeaders} from './ajaxService'
import {messageService, MessageServiceInterface} from './messageService'
import {constants, geometryTypeNames} from '../constants'
import type {AdvancedFilterElement, RightObject, UgdmRelationshipAssociation} from '../interfaces'
import type {IM_USER_SETTING} from '../utilities/userSettingsManager'
import type {GroupDefinition} from "../interfaces";
import {addValuesToAdvancedFilter, createAdvancedFilterObjectWithoutMetadata, createFinalFilterString} from "../utilities/filterUtils";
import {appendErrorInformation} from "../utilities/alertNotificationUtils";
import {isUserDefinedView} from "../utilities/dataviewUtils";
import {noDuplicateRequest, ongoingRequestHandler} from "../utilities/requestUtils";

export interface ThemeType {
    name: string
    contents: {
        id: string
        theme: string
    }[]
}

export interface EntityField {
    [key: string]: any
    type: string
    dataType: string
    name: string
    description: string | null,
    originalName: string
    visible: boolean
    visibleShort: boolean
    visibleTable: boolean
    visibleKanban: boolean
    queryable: boolean
    editable: boolean
    nullable: boolean
    primaryKey: boolean
    unique: boolean
    typeLength: number
    typePrecision: number
    viewLength: number
    viewPrecision: number
    defaultValue?: string | number | boolean
    remarks?: string
    atId: string
    atGroup: string
    atGroupId: string
    longName: string,
}

export interface EntityColumn {
    [key: string]: any

    field: string
    title: string
    hidden: false
    template?: string
    filter?: string
    format?: string
    editor?: string
}

export interface EntityType {
    [key: string]: any

    name: string
    resourceId: string
    entityType?: string
    geometryField?: string
    resourceName?: string
    resourceLongname?: string
    srid?: string
    keys: string[]
    properties: {
        fields: EntityField[]
        columns: EntityColumn[]
        listMapping: ListMapping[]
        viewLists: MappedViewLists[]
        domains?: MappedDomain[]
    }
    loaded: boolean
    resource?: {
        TY_ID: string
        TY_CULTURE: string
        TY_NAME: string
        TY_DOMAIN: string
        TY_LONGNAME: string
        TY_ACRONYM: string
        TY_ARTIFACT: string
        TY_DESCRIPTION: string
        TY_CREATED: string
        TY_MODIFIED: string
        TY_NUMERIC?: number
        Children: []
        TYPE_DOMAIN_Domains: []

    },

    isEditable: (key: any) => boolean,
    getField: (key: any) => EntityField,
    getBaseField: (key: any) => EntityField,
    getDataType: (key: any) => string,
    getDescription: (key: any) => string,
    isNullable: (key: any) => boolean,
    getColumnLongName: (key: any) => any,
    getTypeLength: (key: any) => number,
    isPrimaryKey: (key: any) => boolean,
    isVisible: (key: any) => boolean,
    isVisibleList: (key: any) => boolean,
    isVisibleTable: (key: any) => boolean,
    isVisibleKanban: (key: any) => boolean,
    idProperty?: string,
    nameProperty?: string,
    titleProperty?: string,
    startDateProperty?: string,
    endDateProperty?: string,
    descriptionProperty?: string,
    statusProperty?: string
    hasCalendar: boolean
    relations: EntityRelationship[]
}

export interface EntityRelationship {
    direction: 'FROM' | 'TO',
    relationShip: UgdmRelationshipAssociation,
    label: string,
    rlType: string,
    viewId: string,
}

export interface MappedViewLists {
    columnName: string
    filter: string,
    order: string,
    getEntries?: Function,
    getEntry?: Function
    dataviewGuid: string,
}

export interface MappedDomain {
    columnName: string
    domains: UgdmDomain[]
}

export interface UgdmCoreEntity {
    EY_CODE: string
    EY_CREATED: string
    EY_DESCRIPTION: string
    EY_END: boolean | undefined
    EY_ID: string
    EY_LONGNAME: string
    EY_MODIFIED: string
    EY_NAME: string
    EY_REMARKS: string
    EY_START: string
    EY_STATUS: boolean | undefined
    EY_TYPE: string
}

export interface Mapping {
    guid: string
    value: string
    remarks?: string
}

export interface ListMapping {
    columnTitle: string
    fieldName: string
    guid: string
    filter: string
    values: Mapping[]
}

export interface Filter {
    column: string
    condition: string
    dateFilterType: 'range' | 'exact' | null
    value: string
    value2: string | null
}

export type OrderByDirection = 'asc' | 'desc' | null

export interface OrderByInterface {
    columnName: string
    orderDirection: OrderByDirection
    forKanban: boolean
    frontName?: string
    isSelected?: boolean
}

export interface UgdmDomain {
    DM_CREATED: string
    DM_CULTURE: string
    DM_DESCRIPTION: string
    DM_ID: string
    DM_LONGNAME: string
    DM_MODIFIED: string
    DM_NAME: string
    DM_PARENT: string
    HasChildren?: boolean | null
    NodeId?: string | null
    Culture?: string | null
    DisplayName?: string | null
}

export interface UgdmTypeDomain {
    TD_CREATED: string
    TD_DEFAULT: boolean | undefined
    TD_DOMAIN: string
    TD_MODIFIED: string
    TD_POSITION: string
    TD_TYPE: string | undefined
}

export interface UgdmType {
    Culture: string | null
    DisplayName: string | null
    HasChildren: boolean | null
    NodeId: string | null
    TY_ACRONYM: string
    TY_APPLICATION: string | undefined
    TY_ARTIFACT: string | null
    TY_CREATED: string
    TY_CULTURE: string
    TY_DECIMAL: number | null
    TY_DESCRIPTION: string
    TY_DOMAIN: string
    TY_ID: string
    TY_IMPLEMENTATION: string | null
    TY_LONGNAME: string
    TY_MODIFIED: string
    TY_NAME: string
    TY_NUMERIC: number | null
    TY_PARENT: string | null
    TY_REMARKS: string | null
}

export interface EntityAttribute {
    'odata.type'?: string
    TY_ID: string
    TY_CULTURE: string
    TY_NAME: string
    TY_DOMAIN: string
    TY_PARENT: string
    TY_LONGNAME: string
    TY_ACRONYM: string
    TY_DESCRIPTION: string
    TY_ARTIFACT: string | null
    TY_REMARKS: string
    TY_NUMERIC: number | null
    TY_DECIMAL: number | null
    TY_IMPLEMENTATION: string
    TY_APPLICATION: string | null
    TY_CREATED: string
    TY_MODIFIED: string
    HasChildren?: null
    NodeId?: null
    Culture?: null
    DisplayName?: null
    canRead?: null
    canCreate?: null
    canModify?: null
    canDelete?: null
    AT_ENTITY_TYPE: string
    AT_POSITION: number
    AT_DATA_TYPE: string
    AT_LENGTH: number
    AT_PRECISION: number
    AT_FIXED_LENGTH: null
    AT_COMPLEX_TYPE: string
    AT_COMPLEX: string | null
    AT_PRIMARY_KEY: boolean
    AT_NULLABLE: boolean
    AT_INDEXED: boolean
    AT_UNIQUE: boolean
    AT_VISIBLE: boolean
    AT_VISIBLE_SHORT: boolean
    AT_VISIBLE_TABLE: boolean
    AT_VISIBLE_KANBAN: boolean
    AT_QUERYABLE: boolean
    AT_EDITABLE: boolean
    AT_VIEW_LENGTH: number
    AT_VIEW_PRECISION: number
    AT_DEFAULT_VALUE: any | null
    AT_GROUP?: string | null
    displayEdit?: boolean
    baseAttribute?: string // Needed for the Dataview-Creator
}


interface UgdmLayer {
    Children: any[]
    Culture: string | null
    DisplayName: string | null
    HasChildren: boolean | null
    LY_LT_ALTITUDE_MAX: number | null
    LY_LT_ALTITUDE_MIN: number | null
    LY_LT_COORD_DIMENSION: number
    LY_LT_DESCRIPTION: string
    LY_LT_DOMAIN: string
    LY_LT_ID: string
    LY_LT_LATITUDE_MAX: number
    LY_LT_LATITUDE_MIN: number
    LY_LT_LONGITUDE_MAX: number
    LY_LT_LONGITUDE_MIN: number
    LY_LT_LONGNAME: string
    LY_LT_NAME: string
    LY_LT_NUMERIC: number
    LY_LT_SRS: string
    LY_LT_X_MAX: number
    LY_LT_X_MIN: number
    LY_LT_Y_MAX: number
    LY_LT_Y_MIN: number
    LY_LT_Z_MAX: number | null
    LY_LT_Z_MIN: number | null
    LY_MS_AUTHENTICATION: string
    LY_MS_AUTHENTICATION_METHOD: string
    LY_MS_DESCRIPTION: string
    LY_MS_DOMAIN: string
    LY_MS_ID: string
    LY_MS_LONGNAME: string
    LY_MS_NAME: string
    LY_MS_NUMERIC: number | null
    LY_MS_PASSWORD: string | null
    LY_MS_PRODUCT: string
    LY_MS_REMARKS: string | null
    LY_MS_SERVICE: string
    LY_MS_URI: string
    LY_MS_USER: string | null
    LY_MS_VERSION: string
    LY_MT_DESCRIPTION: string
    LY_MT_ID: string
    LY_MT_LONGNAME: string
    LY_MT_NAME: string
    LY_MT_REMARKS: string
    NodeId: string | null
    TYPE_DOMAIN_Domains: any[]
    TY_ACRONYM: string
    TY_APPLICATION: string | null
    TY_ARTIFACT: string | null
    TY_CREATED: string
    TY_CULTURE: string
    TY_DECIMAL: number
    TY_DESCRIPTION: string
    TY_DOMAIN: string
    TY_ID: string
    TY_IMPLEMENTATION: null
    TY_LONGNAME: string
    TY_MODIFIED: string
    TY_NAME: string
    TY_NUMERIC: number | null
    TY_PARENT: string | null
    TY_REMARKS: string
}

export type InlineCountType = 'allpages' | 'other' | 'none'

export interface MetadataServiceInterface {
    entityTypes: EntityType[]

    fetchLanguageName: () => Promise<any>
    fetchApplicationName: () => Promise<any>
    fetchDomainName: (domainGuid: string) => Promise<any>

    fetchEntityDetail: (resourceId: string) => Promise<boolean>
    fetchEntityDetail3: (resourceId: string) => Promise<boolean>
    fetchTypeNameSync: (typeId: any) => any
    fetchTypeLongnameSync: (typeId: any) => any
    fetchEntityNameSync: (entityId: any) => any
    fetchEntityLongnameSync: (entityId: any) => any
    fetchNextId: (keyName: any, keyGroup: any, length: any) => any
    fetchThemeTree: (themetreeGuid: string) => Promise<any>
    fetchThemeTreeLevel: (parentGuid: string, layers: boolean, views: boolean, checkRights: boolean) => Promise<any>,
    
    fetchApplicationSchema: () => Promise<UgdmType[]> // returns the application schema(ta)in a UgdmType array
    
    getDomains: () => Promise<any>,
    getTypeDomains: () => Promise<any>,
    getUgdmDomainsByParent: (parentDomain: string) => Promise<any>,
    getResourceUrlAndParameters: (
        resourceName: string,
        filterGuid: string | null,
    ) => {
        resourceName: string
        baseUrl: string
        defaultFilter: Filter
        url: string
    }

    fetchViewList: (resourceName: string, filter: string, order: string)=>Promise<any>,

    fetchJsonData: (data: {
        filters: AdvancedFilterElement[]
        resourceName: string
        inlinecount: InlineCountType
        top: number | null
        offset: number | null
        orderBy: OrderByInterface[]
        typeDomainOrDmParent?: string
        entityGuid?: string
        rlType?: string
        rlFromItemguid?: string
        rlDirection?: string
    }) => Promise<any>,

    fetchJsonDataInOngoingRequest: (options: {
        duration: number,
        filters: AdvancedFilterElement[]
        resourceName: string
        inlinecount: InlineCountType
        top: number | null
        offset: number | null
        orderBy: OrderByInterface[]
        typeDomainOrDmParent?: string | null
        entityGuid?: string | null
        rlType?: string | null
        rlFromItemguid?: string | null
        rlDirection?: string | null
    }) => Promise<any>,

    fetchRelationshipCount: (rlType: string, entityGuid: string, rldirection: string) => Promise<any>,
    fetchAllRelationshipCounts: (entityGuid: string) => Promise<any>,
    fetchEntityActions: (
        filters: AdvancedFilterElement[],
        resourceName: string,
        inlinecount: InlineCountType,
        top: number | null,
        offset: number | null,
        orderBy: OrderByInterface[],
    ) => Promise<any>
    fetchAttachements: (
        filters: AdvancedFilterElement[],
        resourceName: string,
        inlinecount: InlineCountType,
        top: number | null,
        offset: number | null,
        orderBy: OrderByInterface[],
        entityGuid: string,
    ) => Promise<any>,
    fetchAttachement: (
        filters: AdvancedFilterElement[],
        resourceName: string,
        inlinecount: InlineCountType,
        top: number | null,
        offset: number | null,
        orderBy: OrderByInterface[],
    ) => Promise<any>
    createExcelURL: (params: {
        resourceName: string,
        filters: AdvancedFilterElement[],
        orderBy: OrderByInterface[],
        userfriendly: boolean,
        rlType?: string,
        rlTo?: string,
        rlDirection?: string,
        top?: number,
        entityGuid?: string
    }) => string | null,

    fetchToTypeRelationships:(TR_TO: string, TR_TYPE: string) => Promise<any>
    fetchFromTypeRelationships: (TR_FROM: string, TR_TYPE: string) => Promise<any>

    fetchPrimaryViewsForEntityType: (entityType: string) => Promise<any>
    fetchViewsForEntityType: (entityType: string) => Promise<any>
    searchEntities: (filters: AdvancedFilterElement[], top: number, offset: number, defaultFilterValue?: string) => Promise<any>
    fetchAttachementsCount: (entityGuid: string) => Promise<any>
    checkColumnForExistenNullValues: (dataviewName: string, column: string) => Promise<any>
    fetchEntityActionCount: (filters: AdvancedFilterElement[]) => Promise<any>
    checkMeasurementExistence: (entityguid: string) => Promise<boolean>
    fetchAllArtifactMimeTypes: () => Promise<any>
    fetchArtifactMimeType: (artifactId: string) => Promise<any>
    updateResource: (resourceName: string, postData: string) => Promise<any>
    getUgdmDomains: (url: string) => Promise<UgdmDomain[]>
    getUgdmDomainById: (domain: string) => Promise<UgdmDomain>
    getUgdmChildDomainsByDomain: (domain: string) => Promise<UgdmDomain[]>
    getUgdmTypesByDomain: (domain: string) => Promise<UgdmType[]>
    getUgdmTypesByDomainAndStringFilter: (domain: string, attribute: string, value: string) => Promise<UgdmType[]>
    getUgdmTypeDomainsByDomain: (domain: string) => Promise<UgdmTypeDomain[]>
    getUgdmActionById: (actionId: string) => Promise<any>,
    getUgdmTypeById: (typeId: string, domainId?: string) => Promise<any>
    getAttributeById: (attributeId: string) => Promise<EntityAttribute>
    getAttributesByEntityTypes: (entityType: string) => Promise<EntityAttribute[]>
    getUgdmLayers: (url: string) => Promise<UgdmLayer[]>
    fetchQGisLayers: () => Promise<any[]>
    fetchAppLocations: () => Promise<any[]>
    fetchCount: (
        filters: AdvancedFilterElement[],
        resourceName: string,
        orderBy: OrderByInterface[],
        filterGuid: string,
        entityGuid: string,
        rlType: string,
        rlFromItemguid: string,
        rlDirection: string,
    ) => Promise<any>
    fetchEntityTypeMetaData: (urlParams: string) => Promise<any>
    fetchActionViews: () => Promise<any>
    getEntitiesFromEntityType: (entityType: string) => Promise<any>
    fetchActionViewsForView: (dataViewGuid: string) => Promise<any[]>
    getUserSettings: (userId: string) => Promise<any[]>
    setUserSetting: (setting: IM_USER_SETTING) => Promise<any>
    deleteUserSetting: (userSettingId: string) => Promise<any>
    getSingleUserClaim: (userId: string, claimType: string) => Promise<any>
    setSingleUserClaim: (claim: string) => Promise<any>
    getAllUserClaimsByUserId: (userId: string) => Promise<any[]>
    getAllUserClaims: () => Promise<any[]>
    deleteUserClaim: (claimId: string) => Promise<any>
    getExelUrl: (
        tableType: 'action' | 'entity' | 'relation' | 'measurement' | 'types' | 'domains' | 'attachment' | 'users' | 'roles',
        filters: AdvancedFilterElement[],
        resourceName: string,
        orderBy: OrderByInterface[],
        rlType: string,
        rlTo: string,
        rldirection: string,
        userfriendly: boolean,
        top?: number,
    ) => string
    getGeoPackageURL: (
        tableType: 'action' | 'entity' | 'relation' | 'measurement' | 'types' | 'domains' | 'users' | 'roles' | 'mesurementtypes' | 'attachment',
        filters: AdvancedFilterElement[],
        resourceName: string,
        orderBy: OrderByInterface[],
        rlType: string,
        rlTo: string,
        rldirection: string,
        userfriendly: boolean,
        top?: number,
    ) => string
    getWordUrl: (
        tableType: 'action' | 'entity' | 'relation',
        filters: AdvancedFilterElement[],
        resourceName: string,
        orderBy: OrderByInterface[],
        rlType: string,
        rlTo: string,
        rldirection: string,
        userfriendly: boolean,
        templateId?: string,
        asPdf?: boolean,
    ) => string
    getQRBillUrl: (
        tableType: 'entity' | 'relation',
        filters: AdvancedFilterElement[],
        resourceName: string,
        orderBy: OrderByInterface[],
        rlType: string,
        rlTo: string,
        rldirection: string,
        userfriendly: boolean,
        templateId: string,
    ) => string
    fetchMeasurementTypesForEntityType: (entityType: string) => Promise<any>
    fetchMeasurementProgrammesForEntityType: (entityType: string) => Promise<any>
    fetchMeasurementTypesForMeasurementProgram: (measureProgramGuid: string) => Promise<any>
    fetchUgdmCoreUgdmEntitiesByEntityType: (urlParams: string) => Promise<any>

    

    fetchUgdmCoreUgdmEntitiesById: (entityId: string) => Promise<any>
    fetchUgdmCoreUgdmEntityAttributes: () => Promise<any>
    fetchEntitiesForArtifact: (artifactId: string) => Promise<any>
    getDocumentCategories: (domainId: string) => Promise<any>
    getEntityDomainsByEntityIdArray: (entityArray: string[]) => Promise<any>
    fetchEntityTypesOfViews: (viewId: string) => Promise<any>
    fetchEntityTypeOfUserView: (viewId: string) => Promise<any>
    
    fetchViewRights: (roleIds: string[], dataviewGuid: string) => Promise<RightObject>
    domainToType: (domainId: string) => string
    getCachedUgdmTypesByDomain: (domain: string) => Promise<any>
}

const handleFetchRawGetRequest = async <T>(url: string, value: boolean) => {
    try {
        const data = await ajaxService.fetchRaw(requestHeaders.jsonGet, url)
        let items = null
        if (data.status === 200) {
            items = await data.json()
            if (value) {
                return items.value as T
            } else {
                return items as T
            }
        } else if (data.status === 404) {
            items = null
            return items
        } else {
            throw new Error(data.status + ' \n' + data.statusText + ' \n' + data.url)
        }

    } catch (error) {
        throw error
    }
}

const handleFetchRawGetRequestOngoing = async <T>(url: string, value: boolean) => {
    try {
        // const data = await ajaxService.fetchRaw(requestHeaders.jsonGet, url)
        const data = await noDuplicateRequest(url, requestHeaders.jsonGet, 'json')

        if (!data) {
            return data
        }
        if (data) {
            if (value) {
                return data.value as T
            } else {
                return data as T
            }
        }
    } catch (error) {
        throw error
    }

}


const getEntityDomainsByEntityIdLocal = async (entityId: string) => {
    try {
        let url = parameterService.appParameters.OdataBaseUrl + `UgdmCore/UgdmEntityDomains?%24filter=ED_ENTITY eq guid'${entityId}'`
        return await handleFetchRawGetRequest(url, true)
    } catch (error) {
        throw error
    }
}

export const createParamsString = (params: string, inlinecount: string, top?: number, offset?: number): string => {
    if (inlinecount) {
        if (params !== '') {
            params = params + '&'
        }
        params = params + '$inlinecount=' + inlinecount
    }
    if (top) {
        if (params !== '') {
            params = params + '&'
        }
        params = params + '$top=' + top
    }

    if (offset) {
        if (params !== '') {
            params = params + '&'
        }
        params = params + '$skip=' + offset
    }
    return params
}

export const createFilterString = (filterString: string, filters: Filter[]): string => {
    if (filters.length > 0) {
        filterString = filterString + '$filter=('

        filters.forEach((filter, index) => {
            if (index === 0) {
                filterString = filterString + filter.condition
            } else {
                filterString = filterString + ' and ' + filter.condition
            }
        })
        filterString = filterString + ')'
    }
    return filterString
}

export const createEntityFilterString = (filterString: string, filters: Filter[], typeFilter: string): string => {
    if (filters.length > 0) {
        filterString = filterString + '$filter=((' + typeFilter + ') and ('

        filters.forEach((filter, index) => {
            if (index === 0) {
                filterString = filterString + filter.condition
            } else {
                filterString = filterString + ' or ' + filter.condition
            }
        })
        filterString = filterString + '))'
    }
    return filterString
}

export const createOrderByString = (orderByString: string, orderBy: OrderByInterface[]): string => {
    if (orderBy.length > 0) {
        orderByString = '$orderby='
        orderBy.forEach((order) => {
            orderByString = orderByString + order.columnName + ' ' + order.orderDirection + ','
        })
        orderByString = orderByString.substring(0, orderByString.length - 1)
    }
    return orderByString
}

export const combineParamsFilterOrderBys = (
    url: string,
    params: string,
    filterString: string,
    orderByString: string,
    resourceName: string,
): string => {
    if (params !== '') {
        if (url.endsWith(resourceName) || url.endsWith('UgdmTypes')) {
            url = url + '?'
        }
        url = url + params
    }

    if (filterString !== '') {
        if (url.endsWith(resourceName) || url.endsWith('UgdmTypes')) {
            url = url + '?'
        } else {
            url = url + '&'
        }
        url = url + filterString
    }

    if (orderByString !== '') {
        if (url.endsWith(resourceName) || url.endsWith('UgdmTypes')) {
            url = url + '?'
        } else {
            url = url + '&'
        }
        url = url + orderByString
    }

    return url
}

const createMetadataManager = function (
    parameterService: ParameterServiceInterface,
    ajaxService: AjaxServiceInterface,
    requestHeaders: RequestHeaderList,
    messageService: MessageServiceInterface,
) {

    const getCachedUgdmTypesByDomain = async (domain: string) => {
        try {
            let url = parameterService.appParameters.OdataBaseUrl + `UgdmMeta/UgdmTypes?%24filter=TY_DOMAIN eq guid'${domain}'`
            let result = await ongoingRequestHandler(url, requestHeaders.jsonGet, 5000, 'json')
            if (result && result.value) {
                return result.value
            }
            return null
        } catch (error) {
            throw error
        }
    }

    const requestCache: { url: string; result: any }[] = []

    const fetchCodelist = async function (urlParams: string) {
        try {
            const url = parameterService.appParameters.OdataBaseUrl + 'UgdmMeta/UgdmTypes?$filter=' + urlParams + '&$orderby=TY_NUMERIC,TY_LONGNAME'
            let cachedResult = requestCache.find((rc) => rc.url === url)
            if (cachedResult) {
                return cachedResult.result
            } else {
                const data = await ajaxService.fetchRaw(requestHeaders.jsonGet, url)
                if (data.status === 200) {
                    const metadata = await data.json()
                    requestCache.push({url: url, result: metadata})
                    return metadata
                } else {
                    console.log(data)
                    if (data.status === 404) {
                        return null
                    } else {
                        throw {
                            url: url,
                            messsage: 'Codelist could not be retrieved. Please check the codelist with following params: ' + urlParams,
                            errorCode: data.status
                        }
                    }
                }
            }
        } catch (error) {
            throw error
        }
    }

    const fetchDomainMetaData = async function (urlParams: string) {
        try {
            const url = parameterService.appParameters.OdataBaseUrl + 'UgdmMeta/UgdmDomains?$orderby=DM_LONGNAME'
            return await handleFetchRawGetRequest(url, false)
        } catch (error) {
            throw error
        }
    }

    const fetchUgdmCoreUgdmEntities = async function (urlParams: string) {
        try {
            const url = parameterService.appParameters.OdataBaseUrl + 'UgdmCore/UgdmEntities?$filter=' + urlParams + '&$orderby=EY_LONGNAME'
            return await ongoingRequestHandler(url, requestHeaders.jsonGet, 2000, 'json')
        } catch (error) {
            throw error
        }
    }

    const determinRelevantType = (resourceId: string) => {
        let relevantType = null
        if (resourceId && resourceId.length > 35) {
            switch (resourceId) {
                case constants.domain.ATTRIBUTE:
                case constants.type.UgdmAttributes: {
                    relevantType = 'UgdmAttributes'
                    break
                }
                case constants.type.UgdmEntityTypeAttributes: {
                    relevantType = 'UgdmEntityTypeAttributes'
                    break
                }
                case constants.type.UgdmEntityViewAttributes: {
                    relevantType = 'UgdmEntityViewAttributes'
                    break
                }
                case constants.domain.ASSOCIATION:
                case constants.type.UgdmAssociations: {
                    relevantType = 'UgdmAssociations'
                    break
                }
                case constants.domain.LOCATION_TYPE:
                case constants.type.UgdmLocationTypes: {
                    relevantType = 'UgdmLocationTypes'
                    break
                }
                case constants.domain.SRS:
                case constants.type.UgdmSrs: {
                    relevantType = 'UgdmSrs'
                    break
                }
                case constants.domain.SYSTEM:
                case constants.domain.DATA_SOURCE_SYSTEM:
                case constants.domain.DATABASE_SOURCE_SYSTEM:
                case constants.domain.DATA_SERVICE_SOURCE_SYSTEM:
                case constants.domain.FILE_SOURCE_SYSTEM:
                case constants.domain.DATA_SERVICE:
                case constants.domain.MAP_SERVICE:
                case constants.domain.APPLICATION_MAP_SERVICE:
                case constants.domain.EXTERNAL_MAP_SERVICE:
                case constants.domain.PROXY_MAP_SERVICE:
                case constants.type.UgdmSystems: {
                    relevantType = 'UgdmSystems'
                    break
                }
                case constants.domain.MEASUREMENT_TYPE:
                case constants.type.UgdmMeasurementTypes: {
                    relevantType = 'UgdmMeasurementTypes'
                    break
                }
                case constants.domain.UNIT:
                case constants.type.UgdmUnits: {
                    relevantType = 'UgdmUnits'
                    break
                }
                default: {
                    relevantType = null
                    break
                }
            }
        }
        return relevantType
    }

    const setSubtypeFilter = function (filter, subtype, resourceId?: string) {
        let newFilter = ''
        let relevantType = subtype
        relevantType = resourceId ? determinRelevantType(resourceId) : relevantType

        if (relevantType) {
            switch (relevantType) {
                case 'UgdmAssociations': {
                    newFilter += "TY_DOMAIN eq guid'" + constants.domain.ASSOCIATION + "'"
                    break
                }
                case 'UgdmAttributes':
                case 'UgdmEntityViewAttributes':
                case 'UgdmEntityTypeAttributes': {
                    newFilter += "TY_DOMAIN eq guid'" + constants.domain.ATTRIBUTE + "'"
                    break
                }
                case 'UgdmLocationTypes': {
                    newFilter += "TY_DOMAIN eq guid'" + constants.domain.LOCATION_TYPE + "'"
                    break
                }
                case 'UgdmMeasurementTypes': {
                    newFilter += "TY_DOMAIN eq guid'" + constants.domain.MEASUREMENT_TYPE + "'"
                    break
                }
                case 'UgdmSrs': {
                    newFilter += "TY_DOMAIN eq guid'" + constants.domain.SRS + "'"
                    break
                }
                case 'UgdmSystems': {
                    newFilter += "TY_DOMAIN eq guid'" + constants.domain.SYSTEM + "'"
                    newFilter += "or TY_DOMAIN eq guid'" + constants.domain.DATA_SOURCE_SYSTEM + "'"
                    newFilter += "or TY_DOMAIN eq guid'" + constants.domain.DATABASE_SOURCE_SYSTEM + "'"
                    newFilter += "or TY_DOMAIN eq guid'" + constants.domain.DATA_SERVICE_SOURCE_SYSTEM + "'"
                    newFilter += "or TY_DOMAIN eq guid'" + constants.domain.FILE_SOURCE_SYSTEM + "'"
                    newFilter += "or TY_DOMAIN eq guid'" + constants.domain.DATA_SERVICE + "'"
                    newFilter += "or TY_DOMAIN eq guid'" + constants.domain.MAP_SERVICE + "'"
                    newFilter += "or TY_DOMAIN eq guid'" + constants.domain.APPLICATION_MAP_SERVICE + "'"
                    newFilter += "or TY_DOMAIN eq guid'" + constants.domain.EXTERNAL_MAP_SERVICE + "'"
                    newFilter += "or TY_DOMAIN eq guid'" + constants.domain.PROXY_MAP_SERVICE + "'"
                    break
                }
                case 'UgdmUnits': {
                    newFilter += "TY_DOMAIN eq guid'" + constants.domain.UNIT + "'"
                    break
                }
                default: {
                    break
                }
            }

            if (filter && filter.length > 0) {
                if (newFilter.length > 0) {
                    newFilter = '(' + filter + ') and ' + newFilter
                } else {
                    newFilter = filter
                }
            }
        }
        return newFilter
    }

    const getResourceUrlParameters = (resourceName: string, filter: string, keys: string[], parentFieldName: string[]) => {
        let baseUrl = ''
        let resourceNamePost = resourceName
        let resourceId = ''

        if (resourceName && resourceName.length > 0) {
            if (resourceName.substring(0, 21) === 'UgdmTypeRelationships') {
                resourceName = 'UgdmTypeRelationships'
            }

            switch (resourceName) {
                // Meta
                case 'UgdmDomains':
                case 'UgdmTypes':
                case 'Codes':
                case 'UgdmTypeDomains':
                case 'UgdmTypeRelationships':
                case 'UgdmDomainTreeNodes':
                case 'UgdmTypeTreeNodes':
                case 'UgdmTechnicalTypeTreeNodes':
                case 'UgdmThematicTypeTreeNodes': {
                    baseUrl = parameterService.appParameters.OdataBaseUrl + 'UgdmMeta/'
                    break
                }
                // Core
                case 'UgdmEntities':
                case 'UgdmEntityWithLocations':
                case 'UgdmEntityLocs':
                case 'UgdmActions':
                case 'UgdmArtifacts':
                case 'UgdmAttachments':
                case 'UgdmRelationships':
                case 'UgdmEntityLocations':
                case 'UgdmRouteEvents':
                case 'UgdmMeasurements':
                case 'UgdmLogs':
                case 'UgdmReferences':
                case 'UgdmEntityDomains': {
                    baseUrl = parameterService.appParameters.OdataBaseUrl + 'UgdmCore/'
                    break
                }
                case 'UgdmEntityMeasurementTypes': {
                    baseUrl = parameterService.appParameters.OdataBaseUrl + 'UgdmCore/'
                    resourceName = 'MeasurementTypesForEntity'
                    break
                }
                case 'MeasurementTypesForEntity': {
                    baseUrl = parameterService.appParameters.OdataBaseUrl + 'UgdmCore/'
                    resourceName = 'MeasurementTypesForEntity'
                    break
                }
                // Identity
                case 'UgdmUsers':
                case 'UgdmRoles':
                case 'UgdmUserRoles':
                case 'UgdmTypeRoles':
                case 'UgdmPageRoles':
                case 'UgdmViewRoles':
                case 'UgdmDomainRoles':
                case 'UgdmEntityUsers':
                case 'UgdmUserClaims':
                case 'UgdmUserLogins': {
                    baseUrl = parameterService.appParameters.OdataBaseUrl + 'UgdmIdentity/'
                    break
                }
                // Meta Subtypes
                case 'UgdmAssociations':
                case 'UgdmAttributes':
                case 'UgdmLocationTypes':
                case 'UgdmMeasurementTypes':
                case 'UgdmSrs':
                case 'UgdmSystems':
                case 'UgdmUnits': {
                    baseUrl = parameterService.appParameters.OdataBaseUrl + 'UgdmMeta/'
                    filter = setSubtypeFilter(filter, resourceName)
                    resourceName = 'UgdmTypes'
                    resourceNamePost = 'UgdmTypes'
                    break
                }
                // Meta Specific Type Views
                case 'UgdmEntityViews':
                case 'UgdmEntityTypes': {
                    baseUrl = parameterService.appParameters.OdataBaseUrl + 'UgdmMeta/'
                    resourceName = 'UgdmTypes'
                    resourceNamePost = 'UgdmTypes'
                    break
                }
                // Meta Specific Attibute Views
                case 'UgdmEntityViewAttributes':
                case 'UgdmEntityTypeAttributes': {
                    baseUrl = parameterService.appParameters.OdataBaseUrl + 'UgdmMeta/'
                    filter = setSubtypeFilter(filter, resourceName)
                    resourceName = 'UgdmTypes'
                    resourceNamePost = 'UgdmTypes'
                    break
                }
                // default ist the dynamic application data service url
                default: {
                    baseUrl = parameterService.get(PARAMS.urls.ApplicationDataServiceBaseUrl)
                }
            }

            resourceId = constants.type[resourceName] // can be null or undefined
        }

        return {
            resourceName: resourceName,
            resourceNamePost: resourceNamePost,
            resourceId: resourceId,
            url: baseUrl,
            filter: filter,
            keys: keys,
            parentFieldName: parentFieldName,
        }
    }

    const getDefaultFilter = function (resourceId: string) {
        let relevantType = ''
        let filter: Filter
        relevantType = resourceId ? determinRelevantType(resourceId) : relevantType

        if (relevantType) {
            switch (relevantType) {
                case 'UgdmAssociations': {
                    filter = {
                        column: 'TY_DOMAIN',
                        condition: "TY_DOMAIN eq guid'" + constants.domain.ASSOCIATION + "'",
                        dateFilterType: null,
                        value: constants.domain.ASSOCIATION,
                        value2: null,
                    }
                    break
                }
                case 'UgdmAttributes':
                case 'UgdmEntityViewAttributes':
                case 'UgdmEntityTypeAttributes': {
                    filter = {
                        column: 'TY_DOMAIN',
                        condition: "TY_DOMAIN eq guid'" + constants.domain.ATTRIBUTE + "'",
                        dateFilterType: null,
                        value: constants.domain.ATTRIBUTE,
                        value2: null,
                    }
                    break
                }
                case 'UgdmLocationTypes': {
                    filter = {
                        column: 'TY_DOMAIN',
                        condition: "TY_DOMAIN eq guid'" + constants.domain.LOCATION_TYPE + "'",
                        dateFilterType: null,
                        value: constants.domain.LOCATION_TYPE,
                        value2: null,
                    }
                    break
                }
                case 'UgdmMeasurementTypes': {
                    filter = {
                        column: 'TY_DOMAIN',
                        condition: "TY_DOMAIN eq guid'" + constants.domain.MEASUREMENT_TYPE + "'",
                        dateFilterType: null,
                        value: constants.domain.MEASUREMENT_TYPE,
                        value2: null,
                    }

                    break
                }
                case 'UgdmSrs': {
                    filter = {
                        column: 'TY_DOMAIN',
                        condition: "TY_DOMAIN eq guid'" + constants.domain.SRS + "'",
                        dateFilterType: null,
                        value: constants.domain.SRS,
                        value2: null,
                    }
                    break
                }
                case 'UgdmSystems': {
                    // newFilter += "TY_DOMAIN eq guid'" + constants.domain.SYSTEM + "'"
                    // newFilter += "or TY_DOMAIN eq guid'" + constants.domain.DATA_SOURCE_SYSTEM + "'"
                    // newFilter += "or TY_DOMAIN eq guid'" + constants.domain.DATABASE_SOURCE_SYSTEM + "'"
                    // newFilter += "or TY_DOMAIN eq guid'" + constants.domain.DATA_SERVICE_SOURCE_SYSTEM + "'"
                    // newFilter += "or TY_DOMAIN eq guid'" + constants.domain.FILE_SOURCE_SYSTEM + "'"
                    // newFilter += "or TY_DOMAIN eq guid'" + constants.domain.DATA_SERVICE + "'"
                    // newFilter += "or TY_DOMAIN eq guid'" + constants.domain.MAP_SERVICE + "'"
                    // newFilter += "or TY_DOMAIN eq guid'" + constants.domain.APPLICATION_MAP_SERVICE + "'"
                    // newFilter += "or TY_DOMAIN eq guid'" + constants.domain.EXTERNAL_MAP_SERVICE + "'"
                    // newFilter += "or TY_DOMAIN eq guid'" + constants.domain.PROXY_MAP_SERVICE + "'"
                    break
                }
                case 'UgdmUnits': {
                    filter = {
                        column: 'TY_DOMAIN',
                        condition: "TY_DOMAIN eq guid'" + constants.domain.UNIT + "'",
                        dateFilterType: null,
                        value: constants.domain.UNIT,
                        value2: null,
                    }
                    break
                }
                default: {
                    break
                }
            }
        }
        return filter
    }

    const getResourceUrlAndParameters = (
        resourceName: string,
        filterGuid: string | null,
    ): {
        resourceName: string
        baseUrl: string
        defaultFilter: AdvancedFilterElement
        url: string
    } => {
        let baseUrl = ''
        let defaultFilter: AdvancedFilterElement
        if (resourceName && resourceName.length > 0) {
            if (resourceName.substring(0, 21) === 'UgdmTypeRelationships') {
                resourceName = 'UgdmTypeRelationships'
            }
            switch (resourceName) {
                case 'UgdmTypes': {
                    baseUrl = parameterService.appParameters.OdataBaseUrl + 'UgdmMeta/'
                    if (filterGuid && filterGuid !== '') {
                        defaultFilter = {
                            column: 'TY_DOMAIN',
                            comparatorFront: '=',
                            comparator: '=',
                            filterExpressionJoinType: 'and',
                            intable: false,
                            current: false,
                            filterExpression: 1,
                            filterString: "TY_DOMAIN eq guid'" + filterGuid + "'",
                            frontName: 'Domain',
                            joinType: 'and',
                            type: 'guid',
                            valid: true,
                            values: [{name: filterGuid, value: filterGuid}],
                        }
                    }
                    break
                }

                // Meta
                case 'UgdmDomains': {
                    baseUrl = parameterService.appParameters.OdataBaseUrl + 'UgdmMeta/'

                    if (filterGuid && filterGuid !== '') {
                        defaultFilter = {
                            column: 'DM_PARENT',
                            comparatorFront: '=',
                            comparator: '=',
                            filterExpressionJoinType: 'and',
                            intable: false,
                            current: false,
                            filterExpression: 1,
                            filterString: "DM_PARENT eq guid'" + filterGuid + "'",
                            frontName: 'Domain',
                            joinType: 'and',
                            type: 'guid',
                            valid: true,
                            values: [{name: filterGuid, value: filterGuid}],
                        }
                    }
                    break
                }

                case 'Codes':
                case 'UgdmTypeDomains':
                case 'UgdmTypeRelationships':
                case 'UgdmDomainTreeNodes':
                case 'UgdmTypeTreeNodes':
                case 'UgdmTechnicalTypeTreeNodes':
                case 'UgdmThematicTypeTreeNodes': {
                    baseUrl = parameterService.appParameters.OdataBaseUrl + 'UgdmMeta/'
                    break
                }
                // Core
                // case 'UgdmRelationshipAssociations':
                case 'UgdmEntities':
                case 'UgdmEntityWithLocations':
                case 'UgdmEntityLocs':
                case 'UgdmActions':
                case 'UgdmArtifacts':
                case 'UgdmAttachments':
                case 'UgdmRelationships':
                case 'UgdmEntityLocations':
                case 'UgdmRouteEvents':
                case 'UgdmMeasurements':
                case 'UgdmLogs':
                case 'UgdmReferences':
                case 'UgdmEntityDomains': {
                    baseUrl = parameterService.appParameters.OdataBaseUrl + 'UgdmCore/'
                    break
                }
                case 'UgdmEntityMeasurementTypes': {
                    baseUrl = parameterService.appParameters.OdataBaseUrl + 'UgdmCore/'
                    resourceName = 'MeasurementTypesForEntity'
                    break
                }
                case 'MeasurementTypesForEntity': {
                    baseUrl = parameterService.appParameters.OdataBaseUrl + 'UgdmCore/'
                    resourceName = 'MeasurementTypesForEntity'
                    break
                }
                // Identity
                case 'UgdmUsers':
                case 'UgdmRoles':
                case 'UgdmUserRoles':
                case 'UgdmTypeRoles':
                case 'UgdmPageRoles':
                case 'UgdmViewRoles':
                case 'UgdmDomainRoles':
                case 'UgdmEntityUsers':
                case 'UgdmUserClaims':
                case 'UgdmUserSettings':
                case 'UgdmUserLogins': {
                    baseUrl = parameterService.appParameters.OdataBaseUrl + 'UgdmIdentity/'
                    break
                }

                // Meta Subtypes
                case 'UgdmAssociations':
                case 'UgdmAttributes':
                case 'UgdmRelationshipAssociations':
                case 'UgdmLocationTypes':
                case 'UgdmMeasurementTypes':
                case 'UgdmSrs':
                case 'UgdmSystems':
                case 'UgdmUnits': {
                    baseUrl = parameterService.appParameters.OdataBaseUrl + 'UgdmMeta/'
                    resourceName = 'UgdmTypes'

                    defaultFilter = {
                        column: 'TY_DOMAIN',
                        comparatorFront: '=',
                        comparator: '=',
                        current: false,
                        filterExpressionJoinType: 'and',
                        intable: false,
                        filterExpression: 1,
                        filterString: "TY_DOMAIN eq guid'" + filterGuid + "'",
                        frontName: 'Domain',
                        joinType: 'and',
                        type: 'guid',
                        valid: true,
                        values: [{name: filterGuid, value: filterGuid}],
                    }
                    break
                }
                // Meta Specific Type Views
                case 'UgdmEntityViews':
                case 'UgdmEntityTypes': {
                    baseUrl = parameterService.appParameters.OdataBaseUrl + 'UgdmMeta/'
                    resourceName = 'UgdmTypes'
                    defaultFilter = {
                        column: 'TY_DOMAIN',
                        comparatorFront: '=',
                        comparator: '=',
                        current: false,
                        filterExpressionJoinType: 'and',
                        intable: false,
                        filterExpression: 1,
                        filterString: "TY_DOMAIN eq guid'" + filterGuid + "'",
                        frontName: 'Domain',
                        joinType: 'and',
                        type: 'guid',
                        valid: true,
                        values: [{name: filterGuid, value: filterGuid}],
                    }

                    break
                }
                // Meta Specific Attibute Views
                case 'UgdmEntityViewAttributes':
                case 'UgdmEntityTypeAttributes': {
                    baseUrl = parameterService.appParameters.OdataBaseUrl + 'UgdmMeta/'
                    resourceName = 'UgdmTypes'
                    if (filterGuid && filterGuid !== '') {
                        defaultFilter = {
                            column: 'TY_DOMAIN',
                            comparatorFront: '=',
                            comparator: '=',
                            current: false,
                            filterExpressionJoinType: 'and',
                            intable: false,
                            filterExpression: 1,
                            filterString: "TY_DOMAIN eq guid'" + filterGuid + "'",
                            frontName: 'Domain',
                            joinType: 'and',
                            type: 'guid',
                            valid: true,
                            values: [{name: filterGuid, value: filterGuid}],
                        }
                    }
                    break
                }
                // default ist the dynamic application data service url
                default: {
                    baseUrl = parameterService.get(PARAMS.urls.ApplicationDataServiceBaseUrl)
                }
            }
        }
        return {
            resourceName: resourceName,
            baseUrl: baseUrl,
            defaultFilter: defaultFilter,
            url: baseUrl + resourceName,
        }
    }

    return {
        getResourceUrlAndParameters: getResourceUrlAndParameters,

        // the following two functions return values that are already set at startup
        fetchLanguageName: async function () {
            const langGuid = parameterService.appParameters.languageGuid
            const urlCall = parameterService.appParameters.OdataBaseUrl + 'UgdmMeta/UgdmTypeLongname/' + langGuid + '/' + langGuid
            await ajaxService.fetchParameter(PARAMS.application.languageName, requestHeaders.jsonGet, urlCall);
            return parameterService.get(PARAMS.application.languageName);
        },

        fetchApplicationName: async function () {
            const langGuid = parameterService.appParameters.languageGuid
            const appGuid = parameterService.appParameters.applicationGUID
            const urlCall = parameterService.appParameters.OdataBaseUrl + 'UgdmMeta/UgdmDomainLongname/' + appGuid + '/' + langGuid
            await ajaxService.fetchParameter(PARAMS.application.applicationName, requestHeaders.jsonGet, urlCall);
            return parameterService.get(PARAMS.application.applicationName);
        },

        // fetsches the name of a domain and sets it under the parameter name parameterName = PARAMS.application.domainNamePrefix.concat(domainGuid) 
        fetchDomainName: async function (domainGuid: string) {
            const langGuid = parameterService.appParameters.languageGuid
            const urlCall = parameterService.appParameters.OdataBaseUrl + 'UgdmMeta/UgdmDomainLongname/' + domainGuid + '/' + langGuid
            const parameterName = PARAMS.application.domainNamePrefix.concat(domainGuid);
            await ajaxService.fetchParameter(parameterName, requestHeaders.jsonGet, urlCall);
            return parameterService.get(parameterName);
        },

        fetchApplicationSchema: async function () {
            let databaseSchemas: UgdmType[] = []
            databaseSchemas = await this.getUgdmTypesByDomain(constants.domain.DATABASE_SCHEMA)

            databaseSchemas = [
                ...databaseSchemas.filter(
                    (d) => d.TY_ID !== '41073420-e41e-4764-bcb2-29ad3d32cada' && d.TY_ID !== 'c47a630d-4666-4195-85fc-79c5cc06f89d',
                ),
            ]

            return databaseSchemas

        },

        fetchUgdmCoreUgdmEntitiesByEntityType: async function (entityType: string) {
            try {
                const OdataBaseUrl = parameterService.appParameters.OdataBaseUrl
                const url = OdataBaseUrl + `UgdmCore/UgdmEntities?$filter=EY_TYPE eq guid'${entityType}'&$orderby=EY_LONGNAME`
                return await handleFetchRawGetRequest(url, false)
            } catch (error) {
                throw error
            }
        },

        fetchUgdmCoreUgdmEntitiesById: async function (entityId: string) {
            try {
                const url = parameterService.appParameters.OdataBaseUrl + `UgdmCore/UgdmEntities?$filter=EY_ID eq guid'${entityId}'&$orderby=EY_LONGNAME`
                return await handleFetchRawGetRequest(url, false)
            } catch (error) {
                throw error
            }
        },

        fetchUgdmCoreUgdmEntityAttributes: async function () {
            try {
                const url = parameterService.appParameters.OdataBaseUrl + `UgdmMeta/UgdmTypes?$filter=(AT_ENTITY_TYPE eq guid'd7ed2ac1-55cd-46fc-853a-cfdeb1fc4e7f') and (TY_DOMAIN eq guid'adae0818-b21a-439e-8712-1c7ca826bc8a')&$orderby=AT_POSITION`
                return await handleFetchRawGetRequest(url, false)
            } catch (error) {
                throw error
            }
        },

        fetchQGisLayers: async function () {
            try {
                const url = parameterService.appParameters.OdataBaseUrl + `WebApi/Qgis/GetLayers`
                return await handleFetchRawGetRequest(url, false)
            } catch (error) {
                throw error
            }
        },

        fetchAppLocations: async function () {
            try {
                const url =
                    parameterService.appParameters.OdataBaseUrl +
                    `UgdmMeta/UgdmTypes?$filter=TY_DOMAIN eq guid'${constants.domain.LOCATION_APP_ENTITY_TYPE}'`
                return await handleFetchRawGetRequest(url, false)
            } catch (error) {
                throw error
            }
        },

        fetchActionViews: async function () {
            try {
                const url =
                    parameterService.appParameters.OdataBaseUrl +
                    `UgdmMeta/UgdmTypes?$filter=TY_DOMAIN eq guid'1d9a079a-9200-4f6b-84e7-d3a83abca965' and TY_NAME eq 'UgdmActions'&$orderby=TY_LONGNAME`
                return await handleFetchRawGetRequest(url, false)
            } catch (error) {
                throw error
            }
        },

        fetchActionViewsForView: async function (dataviewguid: string) {
            try {
                const url = parameterService.appParameters.OdataBaseUrl + `WebApi/ResourceMetadata/ActionViews/` + dataviewguid + '/'
                return await handleFetchRawGetRequest(url, false)
            } catch (error) {
                throw error
            }
        },

        fetchPrimaryViewsForEntityType: async function (entityType: string) {
            try {
                const langGuid = parameterService.appParameters.languageGuid
                const url = parameterService.appParameters.OdataBaseUrl + `UgdmMeta/PrimaryApplicationViewsForEntityType/${entityType}/${langGuid}`
                return await handleFetchRawGetRequest(url, false)
            } catch (error) {
                throw error
            }
        },

        fetchViewsForEntityType: async function (entityType: string) {
            try {
                const langGuid = parameterService.appParameters.languageGuid
                const url = parameterService.appParameters.OdataBaseUrl + `UgdmMeta/ApplicationViewsForEntityType/${entityType}/${langGuid}`
                return await handleFetchRawGetRequest(url, false)
            } catch (error) {
                throw error
            }
        },

        searchEntities: async function (filters: AdvancedFilterElement[], top: number, offset: number, defaultFilterValue?: string) {
            try {
                let url = parameterService.appParameters.OdataBaseUrl + 'UgdmCore/UgdmEntities'
                // let typeFilterUrl = ''
                if (defaultFilterValue) {
                    // typeFilterUrl = `EY_TYPE eq guid'${defaultFilterValue}'`
                    const filterRemarks: AdvancedFilterElement = createAdvancedFilterObjectWithoutMetadata(
                        1,
                        'and',
                        'EY_TYPE',
                        'and',
                        '=',
                        'Type',
                        'Guid',
                    )
                    addValuesToAdvancedFilter(filterRemarks, [{name: 'Type', value: defaultFilterValue}])
                    filters = [...filters, filterRemarks]
                } else {
                    // GUID: 678c6342-af1e-4eaf-94f9-3ecb14b61489 ==> ActionTemplateMaster
                    // GUID: 5d944694-18ce-4233-b5cc-2fe0c28e2f89 ==> Artefact
                    const filterRemarks: AdvancedFilterElement = createAdvancedFilterObjectWithoutMetadata(
                        0,
                        'and',
                        'EY_TYPE',
                        'and',
                        '!=',
                        'Type',
                        'Guid',
                    )
                    addValuesToAdvancedFilter(filterRemarks, [{name: 'Type', value: '678c6342-af1e-4eaf-94f9-3ecb14b61489'}])
                    const filterRemarks2: AdvancedFilterElement = createAdvancedFilterObjectWithoutMetadata(
                        0,
                        'and',
                        'EY_TYPE',
                        'and',
                        '!=',
                        'Type',
                        'Guid',
                    )
                    addValuesToAdvancedFilter(filterRemarks2, [{name: 'Type', value: '5d944694-18ce-4233-b5cc-2fe0c28e2f89'}])
                    filters = [...filters, filterRemarks, filterRemarks2]
                }

                const filterString = createFinalFilterString(filters)
                url = url + '?$top=' + top + '&$skip=' + offset + '&' + filterString

                return await handleFetchRawGetRequest(url, false)

            } catch (error) {
                throw error
            }
        },

        fetchEntityTypeMetaData: fetchCodelist,

        fetchArtifactMimeType: async (artifactId: string) => {
            try {
                let url = parameterService.appParameters.OdataBaseUrl + 'UgdmCore/UgdmArtifactFileType/' + artifactId
                return await handleFetchRawGetRequest(url, false)
            } catch (error) {
                throw error
            }
        },

        domainToType: (domainId: string) => {
            switch (domainId) {
                case constants.domain.ATTRIBUTE:
                case constants.type.UgdmAttributes: {
                    return constants.type.UgdmAttributes
                }
                case constants.type.UgdmEntityTypeAttributes: {
                    return constants.type.UgdmEntityTypeAttributes
                }
                case constants.type.UgdmEntityViewAttributes: {
                    return constants.type.UgdmEntityViewAttributes
                }
                case constants.domain.ASSOCIATION:
                case constants.type.UgdmAssociations: {
                    return constants.type.UgdmAssociations
                }
                case constants.domain.LOCATION_TYPE:
                case constants.type.UgdmLocationTypes: {
                    return constants.type.UgdmLocationTypes
                }
                case constants.domain.SRS:
                case constants.type.UgdmSrs: {
                    return constants.type.UgdmSrs
                }
                case constants.domain.SYSTEM:
                case constants.domain.DATA_SOURCE_SYSTEM:
                case constants.domain.DATABASE_SOURCE_SYSTEM:
                case constants.domain.DATA_SERVICE_SOURCE_SYSTEM:
                case constants.domain.FILE_SOURCE_SYSTEM:
                case constants.domain.DATA_SERVICE:
                case constants.domain.MAP_SERVICE:
                case constants.domain.APPLICATION_MAP_SERVICE:
                case constants.domain.EXTERNAL_MAP_SERVICE:
                case constants.domain.PROXY_MAP_SERVICE:
                case constants.type.UgdmSystems: {
                    return constants.type.UgdmSystems
                }
                case constants.domain.MEASUREMENT_TYPE:
                case constants.type.UgdmMeasurementTypes: {
                    return constants.type.UgdmMeasurementTypes
                }
                case constants.domain.UNIT:
                case constants.type.UgdmUnits: {
                    return constants.type.UgdmUnits
                }
                default: {
                    return null
                }
            }
        },

        fetchAllArtifactMimeTypes: async () => {
            try {
                let url = parameterService.appParameters.OdataBaseUrl + `UgdmMeta/UgdmTypes?$filter=TY_DOMAIN eq guid'${constants.domain.MIME_TYPE}'`
                return await handleFetchRawGetRequest(url, false)
            } catch (error) {
                throw error
            }
        },

        fetchEntityDetail: async function (resourceId: string): Promise<boolean> {

            const langGuid = parameterService.appParameters.languageGuid
            const baseUrl = parameterService.get(PARAMS.urls.applicationBaseUrl)
            const url = baseUrl + 'BcOdata/WebApi/LightResourceMetadata/' + resourceId + '/' + langGuid

            try {
                const data = await ajaxService.fetchRaw(requestHeaders.jsonGet, url)
                if (data.status === 404) {
                    throw {
                        url: url,
                        error: '404 Not found',
                        message: 'The requested view metadata (' + resourceId + ') could not be retrieved. Please make sure the DataviewGuid provided exists.'
                    }
                }
                const entityMetadata = await data.json()

                // Get the optional entity properties
                let entityType = null
                if (entityMetadata.hasOwnProperty('entityType')) {
                    entityType = entityMetadata.entityType
                }
                let geometryField = null
                if (entityMetadata.hasOwnProperty('geometryField')) {
                    geometryField = entityMetadata.geometryField
                }

                let srid = null
                if (entityMetadata.hasOwnProperty('srid')) {
                    srid = entityMetadata.srid
                }
                let resourceName = null
                if (entityMetadata.hasOwnProperty('resourceName')) {
                    resourceName = entityMetadata.resourceName
                }
                let resourceLongname = null
                if (entityMetadata.hasOwnProperty('resourceLongname')) {
                    resourceLongname = entityMetadata.resourceLongname
                }

                let resource = null
                if (entityMetadata.hasOwnProperty('resource')) {
                    resource = entityMetadata.resource
                }

                let isExternalQueryService = false
                if (entityMetadata.hasOwnProperty('isExternalQueryService')) {
                    isExternalQueryService = entityMetadata.isExternalQueryService
                }

                // Get the fields metadata
                const keys = Object.keys(entityMetadata.fields)
                console.log("Metadata raw field names", keys);
                let fields: EntityField[] = []
                for (const propertyName of keys) {
                    fields.push({...entityMetadata.fields[propertyName]})
                }
                let viewLists = []
                let removeFields = []

                for(let j = 0; j< fields.length; j++){
                    let field = fields[j]
                    if(field['atComplexType'] && field['atComplexType'] ===  constants.complexAttributeType.EntityList && field['atComplex'] && field['atComplex'] !== null){

                        let viewId = field['atComplex']
                        let rights = parameterService.get('RIGHTS')
                        let viewRights = rights[field['atComplex']]
                        // console.log('viewId', viewId)

                        if(viewRights.canRead){
                            viewLists.push({
                                columnName: field.name,
                                filter: '',
                                order: '',
                                getEntries: async () => {
                                    let dataView = null
                                    if (parameterService.get('DATAVIEW_' + viewId) === null) {
                                        if (parameterService.get('DATAVIEWS') === null) {
                                            dataView = await bc.metadataManager.getUgdmTypeById(viewId)
                                            parameterService.set('DATAVIEW_' + viewId, dataView);
                                        } else {
                                            dataView = parameterService.get('DATAVIEWS').find(dv => dv.TY_ID === viewId)
                                            parameterService.set('DATAVIEW_' + viewId, dataView);
                                        }
                                    } else {
                                        dataView = parameterService.get('DATAVIEW_' + viewId)
                                    }

                                    console.log("DATA VIEW alias DOMAIN", dataView);
                                    
                                    return await this.fetchViewList(dataView.TY_NAME, '', '')
                                },
                                dataviewGuid: viewId,
                                getEntry: async (guid: string) => {

                                    let dataView = null
                                    if (parameterService.get('DATAVIEW_' + viewId) === null) {
                                        if (parameterService.get('DATAVIEWS') === null) {
                                            dataView = await bc.metadataManager.getUgdmTypeById(viewId)
                                            parameterService.set('DATAVIEW_' + viewId, dataView);
                                        } else {
                                            dataView = parameterService.get('DATAVIEWS').find(dv => dv.TY_ID === viewId)
                                            parameterService.set('DATAVIEW_' + viewId, dataView);
                                        }
                                    } else {
                                        dataView = parameterService.get('DATAVIEW_' + viewId)
                                    }
                                    /**
                                     * Construct url with parameter and filter string if necessairy
                                     */
                                    let resourceUrlAndParameters = getResourceUrlAndParameters(dataView.TY_NAME, null)
                                    let url = resourceUrlAndParameters.url + ('(guid\'' + guid + '\')')
                                    let entry = await handleFetchRawGetRequestOngoing(url, false)
                                    if (entry && entry['Items']) {
                                        return entry['Items'][0]
                                    }
                                    return null
                                }
                            } as MappedViewLists)
                        }else{
                            removeFields.push(field.name)
                        }
                    }
                }

                console.log("Metadata field names to remove", removeFields);

                fields = fields.filter(field => !removeFields.includes(field.name))

                console.log("Metadata filtered fields", fields);

                let getColumnLongNameMap = new Map()
                let getFieldMap = new Map()
                let getBaseFieldMap = new Map()
                let getColumnEditableMap = new Map()
                let getDataTypeMap = new Map()
                let isNullableMap = new Map()
                let isPrimaryKey = new Map()
                let isVisibleListMap = new Map()
                let isVisibleTableMap = new Map()
                let isVisibleKanbanMap = new Map()
                let getDescriptionMap = new Map()
                let isVisibleMap = new Map()
                let getFieldGroupMap = new Map()
                let groups: GroupDefinition[] = []
                let groupNames: string[] = []
                let allGroupTypes = parameterService.get('GROUPS')
                if (allGroupTypes === null) {
                    allGroupTypes = await getCachedUgdmTypesByDomain(constants.domain.ATTRIBUTE_GROUP)
                    parameterService.set('GROUPS', allGroupTypes)
                }

                for (let i = 0; i < fields.length; i++) {
                    getColumnLongNameMap.set(fields[i].name, fields[i].longName)
                    getDescriptionMap.set(fields[i].name, fields[i].description)
                    getFieldMap.set(fields[i].name, fields[i])
                    if (fields[i].originalName) {
                        getBaseFieldMap.set(fields[i].originalName, fields[i])
                    }
                    getColumnEditableMap.set(fields[i].name, fields[i].editable)
                    getFieldGroupMap.set(fields[i].name, fields[i].atGroup)
                    getDataTypeMap.set(fields[i].name, fields[i].dataType)
                    isNullableMap.set(fields[i].name, fields[i].nullable)
                    isVisibleListMap.set(fields[i].name, fields[i].visibleShort)
                    isVisibleTableMap.set(fields[i].name, fields[i].visibleTable)
                    isVisibleKanbanMap.set(fields[i].name, fields[i].visibleKanban)
                    isVisibleMap.set(fields[i].name, fields[i].visible)
                    isPrimaryKey.set(fields[i].name, fields[i].primaryKey)
                    if (allGroupTypes) {
                        if (fields[i].name.indexOf('.Geometry.') === -1) {
                            if (!groupNames.includes(fields[i].atGroup)) {
                                groupNames.push(fields[i].atGroup)
                                let group = allGroupTypes.find((g) => g.TY_ID === fields[i].atGroupId)
                                let isHidden = group && group.TY_NUMERIC === 0
                                if (!isHidden) {
                                    isHidden = false
                                }
                                groups.push({
                                    name: fields[i].atGroup,
                                    hidden: isHidden,
                                    fields: [fields[i].name]
                                })
                            } else {
                                let group = groups.find(g => g.name === fields[i].atGroup)
                                group.fields.push(fields[i].name)
                            }
                        }
                    }
                }

                // Get the column data
                const columns: EntityColumn[] = [...entityMetadata.columns.filter(c => !removeFields.includes(c.field))]
                let listMapping = []
                let domains = []
                // console.log('columns', columns)
                // console.log('fields', fields)
                // console.log('removeFields', removeFields)

                for (let j = 0; j < columns.length; j++) {

                    if (columns[j].hasOwnProperty('filter')) {
                        // TY_DOMAIN handling
                        const regexTYDomain = /(TY_DOMAIN eq guid'[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}').*$/
                        const resultArrayDomain = columns[j]['filter']!.match(regexTYDomain)

                        if (resultArrayDomain) {
                            let tyDomainParameter = resultArrayDomain[0]
                            const listValuesJson = await fetchCodelist(tyDomainParameter)

                            if (listValuesJson) {
                                let values: Mapping[]
                                if (tyDomainParameter.toLowerCase().indexOf('adae0818-b21a-439e-8712-1c7ca826bc8a') !== -1) {
                                    values = listValuesJson.value.map((value: any) => {
                                        const mapping: Mapping = {
                                            guid: value['TY_ID'],
                                            value: value['TY_LONGNAME'] + '  (' + value['TY_ID'] + ')',
                                            remarks: value['TY_REMARKS'],
                                        }
                                        return mapping
                                    })
                                } else {
                                    values = listValuesJson.value.map((value: any) => {
                                        const mapping: Mapping = {
                                            guid: value['TY_ID'],
                                            value: value['TY_LONGNAME'],
                                            remarks: value['TY_REMARKS'],
                                        }
                                        return mapping
                                    })
                                }

                                listMapping.push({
                                    columnTitle: columns[j]['title'],
                                    fieldName: columns[j]['field'],
                                    guid: tyDomainParameter.replace('TY_DOMAINE eq', '').trim(),
                                    filter: tyDomainParameter,
                                    values: values,
                                } as ListMapping)
                            }
                        }

                        // EY_TYPE handling
                        const regexEYType = /(EY_TYPE eq guid'[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}').*$/
                        const resultArrayType = columns[j]['filter']!.match(regexEYType)

                        if (resultArrayType) {
                            let eyTypeParameter = resultArrayType[0]

                            // const listValuesJson = await fetchUgdmCoreUgdmEntities(eyTypeParameter)

                            // viewLists.push({
                            //     columnName: columns[j].field,
                            //     filter: eyTypeParameter,
                            //     getEntities: async () => {
                            //         return await fetchUgdmCoreUgdmEntities(eyTypeParameter)
                            //     }
                            // } as MappedViewLists)

                            // if (listValuesJson) {
                            //     const entityArray: UgdmCoreEntity[] = []
                            //     listValuesJson.value.forEach((element) => {
                            //         entityArray.push(element)
                            //     })
                            //     entities.push({
                            //         columnName: columns[j].field,
                            //         entities: entityArray,
                            //         filter: eyTypeParameter,
                            //     } as MappedUgdmCordEntities)
                            // }
                        }

                        const regexDMParent = /(DM_PARENT eq guid'PARENT_DOMAIN_ID_TO_REPLACE' and DM_CULTURE eq guid'[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}').*$/
                        const resultArrayParent = columns[j]['filter']!.match(regexDMParent)
                        if (resultArrayParent) {
                            let eyParentParameter = resultArrayParent[0]
                            const listValuesJson = await fetchDomainMetaData(eyParentParameter)

                            if (listValuesJson) {
                                const parrentDomainArray: UgdmDomain[] = []
                                listValuesJson.value.forEach((element) => {
                                    parrentDomainArray.push(element)
                                })
                                domains.push({
                                    columnName: columns[j].field,
                                    domains: parrentDomainArray,
                                } as MappedDomain)

                                const values: Mapping[] = listValuesJson.value.map((value: any) => {
                                    const mapping: Mapping = {
                                        guid: value['DM_ID'],
                                        value: value['DM_LONGNAME'],
                                    }
                                    return mapping
                                })

                                listMapping.push({
                                    columnTitle: columns[j]['title'],
                                    fieldName: columns[j]['field'],
                                    guid: '',
                                    filter: '',
                                    values: values,
                                } as ListMapping)
                            }
                        }
                        // Andere Entity
                        // "EY_TYPE eq guid'a7c2c35c-a1c6-4bb6-a6cb-63d25aeb0b46'"
                        // URL http://localhost/BryteCubeAppCC/Bc/BcOdata/UgdmCore/UgdmEntities?$filter=EY_TYPE%20eq%20guid%27a7c2c35c-a1c6-4bb6-a6cb-63d25aeb0b46%27&$orderby=EY_LONGNAME
                    }
                }

                // Construc the EntityType object
                let entity = {
                    groups: groups,
                    name: entityMetadata.name,
                    keys: entityMetadata.keys,
                    loaded: false,
                    properties: {
                        fields: fields,
                        columns: columns,
                        listMapping: listMapping,
                        viewLists: viewLists,
                        domains: domains,
                    },
                    resourceId: entityMetadata.resourceId,
                    getGroup: (key) => {

                        if (getFieldGroupMap.get(key)) {
                            return getFieldGroupMap.get(key)
                        }
                        return null
                    },

                    getBaseField: (key) => {

                        if (getBaseFieldMap.get(key)) {
                            return getBaseFieldMap.get(key)
                        }
                        return null
                    },

                    getField: (key) => {

                        if (getFieldMap.get(key)) {
                            return getFieldMap.get(key)
                        }
                        return null
                    },

                    getDescription: (key) => {

                        if (getDescriptionMap.get(key)) {
                            return getDescriptionMap.get(key)
                        }
                        return null
                    },

                    getColumnLongName: (key) => {

                        if (getColumnLongNameMap.get(key)) {
                            return getColumnLongNameMap.get(key)
                        }
                        return null
                    },

                    isEditable: (key) => {
                        if (getColumnEditableMap.get(key) !== null && getColumnEditableMap.get(key) !== undefined) {
                            return getColumnEditableMap.get(key)
                        }
                        return null
                    },
                    getDataType: (key) => {

                        if (getDataTypeMap.get(key)) {
                            return getDataTypeMap.get(key)
                        }
                        return null
                    },
                    isNullable: (key) => {
                        if (isNullableMap.get(key) !== null && isNullableMap.get(key) !== undefined) {
                            return isNullableMap.get(key)
                        }
                        return null
                    },
                    isVisibleList: (key) => {
                        if (isVisibleListMap.get(key) !== null && isVisibleListMap.get(key) !== undefined) {
                            return isVisibleListMap.get(key)
                        }
                        return null
                    },
                    isVisible: (key) => {

                        if (isVisibleMap.get(key) !== null && isVisibleMap.get(key) !== undefined) {
                            return isVisibleMap.get(key)
                        }
                        return null
                    },
                    isVisibleTable: (key) => {

                        if (isVisibleTableMap.get(key) !== null && isVisibleTableMap.get(key) !== undefined) {
                            return isVisibleTableMap.get(key)
                        }
                        return null
                    },
                    isVisibleKanban: (key) => {

                        if (isVisibleKanbanMap.get(key) !== null && isVisibleKanbanMap.get(key) !== undefined) {
                            return isVisibleKanbanMap.get(key)
                        }
                        return null
                    },
                    isPrimaryKey: (key) => {

                        if (isPrimaryKey.get(key) !== null && isPrimaryKey.get(key) !== undefined) {
                            return isPrimaryKey.get(key)
                        }
                        return null
                    },
                    getTypeLength: (key) => {
                        const field = fields.find(f => f.name === key)
                        if (!field) {
                            throw new Error('Entityfield not existing')
                        }
                        return field.typeLength
                        // if (field.viewLength) {
                        //     if (field.viewLength < field.typeLength) {
                        //         return field.viewLength
                        //     } else {
                        //         return field.typeLength
                        //     }
                        // } else {
                        //     return field.typeLength
                        // }
                    },
                    hasCalendar: false
                }


                let properties: {
                    idProperty?: string,
                    nameProperty?: string,
                    titleProperty?: string,
                    startDateProperty?: string,
                    endDateProperty?: string,
                    descriptionProperty?: string,
                    statusProperty?: string,
                    hasCalendar: boolean
                } = {hasCalendar: false}

                if (resourceName) {

                    switch (resourceName) {
                        case 'UgdmLocationTypes':
                        case 'UgdmMeasurementTypes':
                        case 'UgdmSrs':
                        case 'UgdmSystems':
                        case 'UgdmRelationshipAssociations':
                        case 'UgdmAttributes':
                        case 'UgdmUnits':
                        case 'UgdmTypes': {
                            properties.idProperty = 'TY_ID'
                            properties.nameProperty = 'TY_NAME'
                            properties.titleProperty = 'TY_LONGNAME'
                            break
                        }

                        case 'UgdmDomains': {
                            properties.idProperty = 'DM_ID'
                            properties.titleProperty = 'DM_NAME'
                            break
                        }

                        case 'UgdmActions': {
                            properties.idProperty = 'AC_ID'
                            properties.titleProperty = 'AC_PURPOSE'
                            properties.nameProperty = 'AC_PURPOSE'
                            properties.startDateProperty = 'AC_START'
                            properties.endDateProperty = 'AC_END'
                            properties.statusProperty = 'AC_STATUS'
                            properties.hasCalendar = true
                            break
                        }

                        case 'UgdmArtifacts': {
                            properties.idProperty = 'EY_ID'
                            let nameField = fields.find((f) => f.originalName === 'EY_NAME')
                            let titleField = fields.find((f) => f.originalName === 'EY_NAME')

                            if (nameField) {
                                properties.nameProperty = nameField.name
                            }

                            if (titleField) {
                                properties.titleProperty = titleField.name
                                // Set specific field as Title-Property
                                for (let i = 0; i < fields.length; i++) {
                                    let field = fields[i]
                                    if ((field.dataType === 'String' || field.dataType === 'List') && field.remarks && field.remarks.indexOf('(@TITLE)') !== -1) {
                                        properties.titleProperty = field.name
                                        break
                                    }
                                }
                            }
                            break
                        }

                        case 'UgdmMeasurements': {
                            properties.idProperty = fields.find((f) => f.name === 'ME_ID').name
                            break
                        }

                        case 'UgdmTypeRelationships': {

                            break
                        }
                        case 'UgdmTypeRoles': {
                            properties.idProperty = 'TY_ID'
                            break
                        }

                        case 'UgdmUserRoles': {
                            properties.idProperty = 'TY_ID'
                            break
                        }

                        case 'UgdmUsers': {
                            properties.idProperty = 'Id'
                            properties.nameProperty = 'UserName'
                            properties.titleProperty = 'UserName'
                            break
                        }

                        case 'UgdmRoles': {
                            properties.idProperty = 'Id'
                            properties.titleProperty = 'Name'
                            break
                        }

                        default: {

                            if (isUserDefinedView(resourceName)) {

                                properties.idProperty = fields.find((f) => f.originalName && f.originalName === 'EY_ID').name

                                let nameField = fields.find((f) => f.originalName === 'EY_NAME')
                                let statusField = fields.find((f) => f.originalName === 'EY_STATUS')
                                let startDateField = fields.find((f) => f.originalName === 'EY_START')
                                let endDateField = fields.find((f) => f.originalName === 'EY_END')
                                let titleField = fields.find((f) => f.originalName === 'EY_NAME')
                                let descriptionField = fields.find((f) => f.originalName === 'EY_DESCRIPTION')

                                if (nameField) {
                                    properties.nameProperty = nameField.name
                                }

                                if (descriptionField) {
                                    properties.descriptionProperty = descriptionField.name
                                }

                                if (statusField) {
                                    properties.statusProperty = statusField.name
                                }

                                if (startDateField) {
                                    properties.startDateProperty = startDateField.name
                                    // Set specific field as StartDate-Property
                                    for (let i = 0; i < fields.length; i++) {
                                        let field = fields[i]
                                        if (field.dataType === 'DateTime' && field.remarks && field.remarks.indexOf('(@Calendar=START)') !== -1) {
                                            properties.startDateProperty = field.name
                                        }
                                    }
                                }
                                if (endDateField) {
                                    properties.endDateProperty = endDateField.name
                                    // Set specific field as EndDate-Property
                                    for (let i = 0; i < fields.length; i++) {
                                        let field = fields[i]
                                        if (field.dataType === 'DateTime' && field.remarks && field.remarks.indexOf('(@Calendar=END)') !== -1) {
                                            properties.endDateProperty = field.name
                                        }
                                    }
                                }
                                if (titleField) {
                                    properties.titleProperty = titleField.name

                                    // Set specific field as Title-Property
                                    for (let i = 0; i < fields.length; i++) {
                                        let field = fields[i]
                                        if ((field.dataType === 'String' || field.dataType === 'List') && field.remarks && field.remarks.indexOf('(@TITLE)') !== -1) {
                                            properties.titleProperty = field.name
                                            console.log("TitleProperty from @TITLE fetchEntityDetail", field.dataType, field.remarks, field, properties)
                                            break
                                        }
                                    }
                                }

                                if (startDateField && endDateField) {
                                    if (startDateField.visible && endDateField.visible) {
                                        properties.hasCalendar = true
                                    }
                                }
                            }
                        }
                    }
                }
                let relations = []
                // Assign values that can be null to the target entity
                entity = Object.assign(
                    entity,
                    {isExternalQueryService},
                    {...properties},
                    entityType === null ? null : {entityType},
                    geometryField === null ? null : {geometryField},
                    srid === null ? null : {srid},
                    resourceName === null ? null : {resourceName},
                    resourceLongname === null ? null : {resourceLongname},
                    resource === null ? null : {resource},
                    {relations}
                )
                entity.loaded = true
                parameterService.setMetadata(entity.resourceId, entity)
                return true
            } catch (error) {
                console.error('ERROR FETCHING ENTITYDETAILS FOR', resourceId, error)
                throw error
            }
        },

        fetchEntityDetail3: async function (resourceId: string): Promise<boolean> {

            try {

                let allViews = parameterService.get('DATAVIEWS')
                let view = null
                if (allViews !== null) {
                    view = allViews.find(v => v.TY_ID === resourceId)
                }
                if (!view) {
                    view = await metadataManager.getUgdmTypeById(resourceId)
                }

                if (view) {
                    console.log('view', view.TY_NAME)
                    let groups2 = parameterService.get('GROUPS')
                    if (groups2 === null) {
                        groups2 = await getCachedUgdmTypesByDomain(constants.domain.ATTRIBUTE_GROUP)
                        parameterService.set('GROUPS', groups2)
                    }

                    let dataTypes = parameterService.get('DATA_TYPES')
                    if (dataTypes === null) {
                        dataTypes = await getCachedUgdmTypesByDomain(constants.domain.DATA_TYPE)
                        parameterService.set('DATA_TYPES', dataTypes)
                    }

                    let complexAttributeTypes = parameterService.get('COMPLEX_ATTRIBUT_TYPES')
                    if (complexAttributeTypes === null) {
                        complexAttributeTypes = await metadataManager.getCachedUgdmTypesByDomain(constants.domain.DATA_TYPE)
                        parameterService.set('COMPLEX_ATTRIBUT_TYPES', complexAttributeTypes)
                    }

                    let entityType = ''
                    let listMapping = []
                    let viewLists = []
                    let domains = []

                    let mainCallURL = parameterService.appParameters.applicationBaseUrl + 'BcOdataApp/WebApi/ApplicationOdata/ViewDescription/' + resourceId + '/' + parameterService.appParameters.languageGuid
                    const data = await ajaxService.fetchRaw(requestHeaders.jsonGet, mainCallURL)
                    if (data.status === 404) {
                        throw {
                            url: mainCallURL,
                            error: '404 Not found',
                            message: 'The requested view metadata could not be retrieved. Please make sure the DataviewGuid provided exists.'
                        }
                    }

                    const entityMetadataArray = await data.json()
                    let entityMetadata = entityMetadataArray[0]
                    entityType = entityMetadata.EntityId // entity type behind view
                    let SRID = '2056'

                    console.log('EntityMetadata', entityMetadata)

                    let keys = Object.keys(entityMetadata.Attributes)
                    const attributes: EntityAttribute[] = []

                    for (let key of keys) {
                        // if an attribute is not visible, it will not be here. Therefore check its existance
                        if(entityMetadata.Attributes[key] && entityMetadata.Attributes[key]['BcAttribute'])
                        {
                            attributes.push(entityMetadata.Attributes[key]['BcAttribute'])
                            /*****************************
                             * Codelists
                             */
                            if (entityMetadata.Attributes[key]['CodeList']) {
                                let values: Mapping[] = []
                                let clKeys = Object.keys(entityMetadata.Attributes[key]['CodeList'])

                                // let regex = /((?:[A-Za-z]|_)+\s+(?:eq|ne)\s+guid'[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}')/gm

                                for (let clKey of clKeys) {
                                    values.push({
                                        guid: clKey,
                                        value: entityMetadata.Attributes[key]['CodeList'][clKey]['TY_LONGNAME'],
                                        remarks: entityMetadata.Attributes[key]['CodeList'][clKey]['TY_REMARKS'],
                                    })
                                }

                                if (values.length > 0) {
                                    listMapping.push({
                                        columnTitle: entityMetadata.Attributes[key]['BcAttribute']['TY_LONGNAME'],
                                        fieldName: entityMetadata.Attributes[key]['BcAttribute']['TY_NAME'],
                                        guid: entityMetadata.Attributes[key]['BcAttribute']['AT_COMPLEX'],
                                        filter: `TY_DOMAIN eq guid'${entityMetadata.Attributes[key]['BcAttribute']['AT_COMPLEX']}'`,
                                        values: values,
                                    } as ListMapping)
                                }
                            }

                            /*****************************
                             * EntityLists
                             */

                            if (entityMetadata.Attributes[key]['BcAttribute']['AT_COMPLEX_TYPE'] === '4aaaaf1e-53cd-41fe-b83e-b7802323af9a') {
                                let filter = '' //`EY_TYPE eq guid'${entityMetadata.Attributes[key]['BcAttribute']['AT_COMPLEX']}'`
                                if (entityMetadata.Attributes[key]['BcAttribute']['TY_REMARKS'] !== null && entityMetadata.Attributes[key]['BcAttribute']['TY_REMARKS'] !== '') {
                                    //filter = filter + ` and (${entityMetadata.Attributes[key]['BcAttribute']['TY_REMARKS']})`
                                    let tempFilter = entityMetadata.Attributes[key]['BcAttribute']['TY_REMARKS'].split(';')[0]
                                    filter = filter + `(${tempFilter})`
                                }

                                let order = ''

                                // get the rights for the view list to be used in this attribute
                                let rights = parameterService.get('RIGHTS')
                                let viewRights = rights[entityMetadata.Attributes[key]['BcAttribute']['AT_COMPLEX']]

                                if(viewRights && viewRights.canRead){
                                    viewLists.push({
                                        columnName: entityMetadata.Attributes[key]['BcAttribute']['TY_NAME'],
                                        filter: filter,
                                        order: order,
                                        // get first the metadata (type) of the view, the fetch the entries
                                        getEntries: async () => {
                                            let dataView = null
                                            if (parameterService.get('DATAVIEW_' + entityMetadata.Attributes[key]['BcAttribute']['AT_COMPLEX']) === null) {
                                                if (parameterService.get('DATAVIEWS') === null) {
                                                    dataView = await bc.metadataManager.getUgdmTypeById(entityMetadata.Attributes[key]['BcAttribute']['AT_COMPLEX'])
                                                    parameterService.set('DATAVIEW_' + entityMetadata.Attributes[key]['BcAttribute']['AT_COMPLEX'], dataView);
                                                } else {
                                                    dataView = parameterService.get('DATAVIEWS').find(dv => dv.TY_ID === entityMetadata.Attributes[key]['BcAttribute']['AT_COMPLEX'])
                                                    parameterService.set('DATAVIEW_' + entityMetadata.Attributes[key]['BcAttribute']['AT_COMPLEX'], dataView);
                                                }
                                            } else {
                                                dataView = parameterService.get('DATAVIEW_' + entityMetadata.Attributes[key]['BcAttribute']['AT_COMPLEX'])
                                            }
console.log("DATA VIEW alias DOMAIN 2", dataView);
                                            return await this.fetchViewList(dataView.TY_NAME, filter, order)
                                        },
                                        dataviewGuid: entityMetadata.Attributes[key]['BcAttribute']['AT_COMPLEX'],
                                        getEntry: async (guid: string) => {

                                            let dataView = null
                                            if (parameterService.get('DATAVIEW_' + entityMetadata.Attributes[key]['BcAttribute']['AT_COMPLEX']) === null) {
                                                if (parameterService.get('DATAVIEWS') === null) {
                                                    dataView = await bc.metadataManager.getUgdmTypeById(entityMetadata.Attributes[key]['BcAttribute']['AT_COMPLEX'])
                                                    parameterService.set('DATAVIEW_' + entityMetadata.Attributes[key]['BcAttribute']['AT_COMPLEX'], dataView);
                                                } else {
                                                    dataView = parameterService.get('DATAVIEWS').find(dv => dv.TY_ID === entityMetadata.Attributes[key]['BcAttribute']['AT_COMPLEX'])
                                                    parameterService.set('DATAVIEW_' + entityMetadata.Attributes[key]['BcAttribute']['AT_COMPLEX'], dataView);
                                                }
                                            } else {
                                                dataView = parameterService.get('DATAVIEW_' + entityMetadata.Attributes[key]['BcAttribute']['AT_COMPLEX'])
                                            }
                                            /**
                                             * Construct url with parameter and filter string if necessairy
                                             */
                                            let resourceUrlAndParameters = getResourceUrlAndParameters(dataView.TY_NAME, null)
                                            let url = resourceUrlAndParameters.url + ('(guid\'' + guid + '\')')
                                            let entry = await handleFetchRawGetRequestOngoing(url, false)
                                            if (entry && entry['Items']) {
                                                return entry['Items'][0]
                                            }
                                            return null
                                        }
                                    } as MappedViewLists)
                                }else{
                                    attributes.pop()
                                }
                            }

                        }                        
                    }

                    attributes.sort((a, b) => {
                        if (!a.AT_POSITION) {
                            a.AT_POSITION = 999
                        }
                        if (!b.AT_POSITION) {
                            b.AT_POSITION = 999
                        }
                        return a.AT_POSITION - b.AT_POSITION
                    })

                    let fields: EntityField[] = attributes.map(attribute => {
                        if (attribute.TY_NAME !== 'OBJECTID') {
                            return {
                                type: getSimplifiedType(getDataType(attribute, dataTypes)),
                                dataType: getDataType(attribute, dataTypes),
                                name: attribute.TY_NAME,
                                longName: attribute.TY_LONGNAME,
                                originalName: getOriginalName(attribute),
                                visible: attribute.AT_VISIBLE,
                                visibleShort: attribute.AT_VISIBLE_SHORT,
                                visibleTable: attribute.AT_VISIBLE_TABLE,
                                visibleKanban: attribute.AT_VISIBLE_KANBAN,
                                queryable: attribute.AT_QUERYABLE,
                                editable: attribute.AT_EDITABLE ? attribute.AT_EDITABLE : false,
                                nullable: attribute.AT_NULLABLE,
                                primaryKey: attribute.AT_PRIMARY_KEY ? attribute.AT_PRIMARY_KEY : false,
                                description: attribute.TY_DESCRIPTION ? attribute.TY_DESCRIPTION : null,
                                unique: attribute.AT_UNIQUE,
                                typeLength: attribute.AT_LENGTH,
                                typePrecision: attribute.AT_PRECISION,
                                viewLength: (attribute.AT_VIEW_LENGTH !== undefined && attribute.AT_VIEW_LENGTH !== null) ? attribute.AT_VIEW_LENGTH : undefined,
                                viewPrecision: (attribute.AT_VIEW_PRECISION !== undefined && attribute.AT_VIEW_PRECISION !== null) ? attribute.AT_VIEW_PRECISION : undefined,
                                defaultValue: getOriginalName(attribute) === 'EY_TYPE' ? entityType : attribute.AT_DEFAULT_VALUE,
                                remarks: attribute.TY_REMARKS,
                                atId: attribute.TY_ID,
                                atGroup: attribute.AT_GROUP ? groups2.find(g => g.TY_ID === attribute.AT_GROUP).TY_LONGNAME : '',
                                atGroupId: attribute.AT_GROUP,

                            }
                        }
                    })
                    fields = fields.filter(field => field !== undefined)

                    let primaryKeys = fields.filter(f => f.primaryKey === true).map(f => f.name)


                    let getColumnLongNameMap = new Map()
                    let getFieldMap = new Map()
                    let getBaseFieldMap = new Map()
                    let getColumnEditableMap = new Map()
                    let getDataTypeMap = new Map()
                    let isNullableMap = new Map()
                    let isPrimaryKey = new Map()
                    let isVisibleListMap = new Map()
                    let isVisibleTableMap = new Map()
                    let isVisibleKanbanMap = new Map()
                    let isVisibleMap = new Map()
                    let getFieldGroupMap = new Map()
                    let groups: GroupDefinition[] = []
                    let groupNames: string[] = []
                    let allGroupTypes = parameterService.get('GROUPS')
                    let getDescriptionMap = new Map()
                    if (allGroupTypes === null) {
                        allGroupTypes = await getCachedUgdmTypesByDomain(constants.domain.ATTRIBUTE_GROUP)
                        parameterService.set('GROUPS', allGroupTypes)
                    }
                    let geometryField = fields.find(f => geometryTypeNames.includes(f.dataType))
                    // console.log('geometryField', geometryField)
                    let geometryFieldName = ''
                    if (geometryField) {
                        if (entityMetadata.Srs && entityMetadata.Srs.SR_SRID) {
                            SRID = '' + entityMetadata.Srs.SR_SRID
                        }
                        geometryFieldName = geometryField.name
                        let copyField = {...geometryField}
                        geometryField.type = 'string'
                        geometryField.name = geometryField.name + '.Geometry.WellKnownText'
                        copyField.type = 'number'
                        copyField.name = copyField.name + '.Geometry.CoordinateSystemId'
                        copyField.defaultValue = SRID
                        fields.push(copyField)
                    }

                    for (let i = 0; i < fields.length; i++) {
                        getColumnLongNameMap.set(fields[i].name, fields[i].longName)
                        getDescriptionMap.set(fields[i].name, fields[i].description)
                        getFieldMap.set(fields[i].name, fields[i])
                        if (fields[i].originalName) {
                            getBaseFieldMap.set(fields[i].originalName, fields[i])
                        }
                        getColumnEditableMap.set(fields[i].name, fields[i].editable)
                        getFieldGroupMap.set(fields[i].name, fields[i].atGroup)
                        getDataTypeMap.set(fields[i].name, fields[i].dataType)
                        isNullableMap.set(fields[i].name, fields[i].nullable)
                        isVisibleListMap.set(fields[i].name, fields[i].visibleShort)
                        isVisibleTableMap.set(fields[i].name, fields[i].visibleTable)
                        isVisibleKanbanMap.set(fields[i].name, fields[i].visibleKanban)
                        isVisibleMap.set(fields[i].name, fields[i].visible)
                        isPrimaryKey.set(fields[i].name, fields[i].primaryKey)
                        if (allGroupTypes) {
                            if (fields[i].name.indexOf('.Geometry.') === -1) {
                                if (!groupNames.includes(fields[i].atGroup)) {
                                    groupNames.push(fields[i].atGroup)
                                    let group = allGroupTypes.find((g) => g.TY_ID === fields[i].atGroupId)
                                    let isHidden = group && group.TY_NUMERIC === 0
                                    if (!isHidden) {
                                        isHidden = false
                                    }
                                    groups.push({
                                        name: fields[i].atGroup,
                                        hidden: isHidden,
                                        fields: [fields[i].name]
                                    })
                                } else {
                                    let group = groups.find(g => g.name === fields[i].atGroup)
                                    group.fields.push(fields[i].name)
                                }
                            }
                        }
                    }


                    let columns: { field: string, title: string, hidden: boolean, filter: string, complex: string }[] = fields.map(field => {
                        // console.log('field', field)
                        return {
                            field: field.name,
                            title: field.longName,
                            hidden: field.visible === true ? false : true,
                            filter: getFilter(attributes.find(a => a.TY_ID === field.atId)),
                            complex: attributes.find(a => a.TY_ID === field.atId).AT_COMPLEX
                        }
                    })


                    let relations: EntityRelationship [] = []
                    let allFromRelationships = entityMetadata.FromRelationshipAssociations.map(f => {
                        return {
                            direction: 'FROM',
                            relationShip: f.BcType,
                            label: f.RA_TO_ROLE.TY_LONGNAME,
                            rlType: f.BcType.RA_RELATIONSHIP_TYPE,
                            // rlType: f.Relationships[0].TY_ID,
                            viewId: f.BcType.RA_TO_VIEW,
                        }
                    })

                    allFromRelationships.sort((a, b) => {
                        return a.relationShip.TY_NUMERIC - b.relationShip.TY_NUMERIC
                    })

                    let allToRelationships = entityMetadata.ToRelationshipAssociations.map(f => {
                        return {
                            direction: 'TO',
                            relationShip: f.BcType,
                            label: f.RA_FROM_ROLE.TY_LONGNAME,
                            rlType: f.BcType.RA_RELATIONSHIP_TYPE,
                            // rlType: f.Relationships[0].TY_ID,
                            viewId: f.BcType.RA_FROM_VIEW,
                        }
                    })

                    allToRelationships.sort((a, b) => {
                        return a.relationShip.TY_NUMERIC - b.relationShip.TY_NUMERIC
                    })

                    relations = [...allFromRelationships, ...allToRelationships]
                    relations = [...relations.filter(r => r.rlType && r.direction && r.label && r.viewId && r.relationShip)]

                    // Construc the EntityType object
                    let entity = {}
                    try {
                        entity = {
                            groups: groups,
                            name: undefined,
                            keys: primaryKeys,
                            loaded: false,
                            properties: {
                                fields: fields,
                                columns: columns,
                                listMapping: listMapping,
                                viewLists: viewLists,
                                domains: domains,
                            },

                            resourceId: view.TY_ID,

                            getGroup: (key) => {

                                if (getFieldGroupMap.get(key)) {
                                    return getFieldGroupMap.get(key)
                                }
                                return null
                            },

                            getBaseField: (key) => {

                                if (getBaseFieldMap.get(key)) {
                                    return getBaseFieldMap.get(key)
                                }
                                return null
                            },

                            getField: (key) => {

                                if (getFieldMap.get(key)) {
                                    return getFieldMap.get(key)
                                }
                                return null
                            },

                            getDescription: (key) => {

                                if (getDescriptionMap.get(key)) {
                                    return getDescriptionMap.get(key)
                                }
                                return null
                            },

                            getColumnLongName: (key) => {

                                if (getColumnLongNameMap.get(key)) {
                                    return getColumnLongNameMap.get(key)
                                }
                                return null
                            },

                            isEditable: (key) => {
                                if (getColumnEditableMap.get(key) !== null && getColumnEditableMap.get(key) !== undefined) {
                                    return getColumnEditableMap.get(key)
                                }
                                return null
                            },
                            getDataType: (key) => {

                                if (getDataTypeMap.get(key)) {
                                    return getDataTypeMap.get(key)
                                }
                                return null
                            },
                            isNullable: (key) => {
                                if (isNullableMap.get(key) !== null && isNullableMap.get(key) !== undefined) {
                                    return isNullableMap.get(key)
                                }
                                return null
                            },
                            isVisibleList: (key) => {
                                if (isVisibleListMap.get(key) !== null && isVisibleListMap.get(key) !== undefined) {
                                    return isVisibleListMap.get(key)
                                }
                                return null
                            },
                            isVisible: (key) => {

                                if (isVisibleMap.get(key) !== null && isVisibleMap.get(key) !== undefined) {
                                    return isVisibleMap.get(key)
                                }
                                return null
                            },
                            isVisibleTable: (key) => {

                                if (isVisibleTableMap.get(key) !== null && isVisibleTableMap.get(key) !== undefined) {
                                    return isVisibleTableMap.get(key)
                                }
                                return null

                            },
                            isVisibleKanban: (key) => {

                                if (isVisibleKanbanMap.get(key) !== null && isVisibleKanbanMap.get(key) !== undefined) {
                                    return isVisibleKanbanMap.get(key)
                                }
                                return null
                            },
                            isPrimaryKey: (key) => {

                                if (isPrimaryKey.get(key) !== null && isPrimaryKey.get(key) !== undefined) {
                                    return isPrimaryKey.get(key)
                                }
                                return null
                            },
                            getTypeLength: (key) => {
                                const field = fields.find(f => f.name === key)
                                if (!field) {
                                    throw new Error('Entityfield not existing')
                                }
                                return field.typeLength
                                // if (field.viewLength) {
                                //     if (field.viewLength < field.typeLength) {
                                //         return field.viewLength
                                //     } else {
                                //         return field.typeLength
                                //     }
                                // } else {
                                //     return field.typeLength
                                // }
                            },
                            hasCalendar: false
                        }

                    } catch (error) {
                        console.log('error defining entity object')
                        throw 'error defining entity object'
                    }

                    let properties: {
                        idProperty?: string,
                        nameProperty?: string,
                        titleProperty?: string,
                        startDateProperty?: string,
                        endDateProperty?: string,
                        descriptionProperty?: string,
                        statusProperty?: string,
                        hasCalendar: boolean
                    } = {hasCalendar: false}

                    if (view.TY_NAME) {

                        switch (view.TY_NAME) {
                            case 'UgdmLocationTypes':
                            case 'UgdmMeasurementTypes':
                            case 'UgdmSrs':
                            case 'UgdmSystems':
                            case 'UgdmRelationshipAssociations':
                            case 'UgdmAttributes':
                            case 'UgdmTypes': {
                                properties.idProperty = 'TY_ID'
                                properties.nameProperty = 'TY_NAME'
                                properties.titleProperty = 'TY_LONGNAME'
                                break
                            }

                            case 'UgdmDomains': {
                                properties.idProperty = 'DM_ID'
                                properties.titleProperty = 'DM_NAME'
                                break
                            }

                            case 'UgdmActions': {
                                properties.idProperty = 'AC_ID'
                                properties.titleProperty = 'AC_PURPOSE'
                                properties.nameProperty = 'AC_PURPOSE'
                                properties.startDateProperty = 'AC_START'
                                properties.endDateProperty = 'AC_END'
                                properties.statusProperty = 'AC_STATUS'
                                properties.hasCalendar = true
                                break
                            }

                            case 'UgdmArtifacts': {
                                properties.idProperty = 'EY_ID'
                                let nameField = fields.find((f) => f.originalName === 'EY_NAME')
                                let titleField = fields.find((f) => f.originalName === 'EY_NAME')

                                if (nameField) {
                                    properties.nameProperty = nameField.name
                                }

                                if (titleField) {
                                    properties.titleProperty = titleField.name
                                    // Set specific field as Title-Property
                                    for (let i = 0; i < fields.length; i++) {
                                        let field = fields[i]
                                        if (field.dataType === 'String' && field.remarks && field.remarks.indexOf('(@TITLE)') !== -1) {
                                            properties.titleProperty = field.name
                                            break
                                        }
                                    }
                                }
                                break
                            }

                            case 'UgdmMeasurements': {
                                properties.idProperty = fields.find((f) => f.name === 'ME_ID').name
                                break
                            }

                            case 'UgdmTypeRelationships': {

                                break
                            }

                            case 'UgdmTypeRoles': {
                                properties.idProperty = 'TY_ID'
                                break
                            }

                            case 'UgdmUserRoles': {
                                properties.idProperty = 'TY_ID'
                                break
                            }

                            case 'UgdmUsers': {
                                properties.idProperty = 'Id'
                                properties.nameProperty = 'UserName'
                                properties.titleProperty = 'UserName'
                                break
                            }

                            case 'UgdmRoles': {
                                properties.idProperty = 'Id'
                                properties.titleProperty = 'Name'
                                break
                            }

                            default: {

                                if (isUserDefinedView(view.TY_NAME)) {

                                    properties.idProperty = fields.find((f) => f.originalName && f.originalName === 'EY_ID').name

                                    let nameField = fields.find((f) => f.originalName === 'EY_NAME')
                                    let statusField = fields.find((f) => f.originalName === 'EY_STATUS')
                                    let startDateField = fields.find((f) => f.originalName === 'EY_START')
                                    let endDateField = fields.find((f) => f.originalName === 'EY_END')
                                    let titleField = fields.find((f) => f.originalName === 'EY_NAME')
                                    let descriptionField = fields.find((f) => f.originalName === 'EY_DESCRIPTION')

                                    if (nameField) {
                                        properties.nameProperty = nameField.name
                                    }

                                    if (descriptionField) {
                                        properties.descriptionProperty = descriptionField.name
                                    }

                                    if (statusField) {
                                        properties.statusProperty = statusField.name
                                    }

                                    if (startDateField) {
                                        properties.startDateProperty = startDateField.name
                                        // Set specific field as StartDate-Property
                                        for (let i = 0; i < fields.length; i++) {
                                            let field = fields[i]
                                            if (field.dataType === 'DateTime' && field.remarks && field.remarks.indexOf('(@Calendar=START)') !== -1) {
                                                properties.startDateProperty = field.name
                                            }
                                        }
                                    }

                                    if (endDateField) {
                                        properties.endDateProperty = endDateField.name
                                        // Set specific field as EndDate-Property
                                        for (let i = 0; i < fields.length; i++) {
                                            let field = fields[i]
                                            if (field.dataType === 'DateTime' && field.remarks && field.remarks.indexOf('(@Calendar=END)') !== -1) {
                                                properties.endDateProperty = field.name
                                            }
                                        }
                                    } else {
                                        if (properties.startDateProperty) {
                                            properties.endDateProperty = properties.startDateProperty
                                        }
                                    }
                                    if (titleField) {
                                        properties.titleProperty = titleField.name
                                        // Set specific field as Title-Property
                                        for (let i = 0; i < fields.length; i++) {
                                            let field = fields[i]
                                            // console.log('Searching TitleProperty', field.name, field.dataType, field.remarks)
                                            if ((field.dataType === 'String' || field.dataType === 'List') && field.remarks && field.remarks.indexOf('(@TITLE)') !== -1) {
                                                properties.titleProperty = field.name
                                                // console.log("TitleProperty from @TITLE fetchEntityDetail3", field.dataType, field.remarks, field, properties)
                                                break
                                            }
                                        }
                                    }

                                    if (startDateField && endDateField) {
                                        if (startDateField.visible && endDateField.visible) {
                                            properties.hasCalendar = true
                                        }
                                    }
                                }
                            }
                        }
                    }

                    // Assign values that can be null to the target entity
                    entity = Object.assign(
                        entity,
                        {...properties},
                        entityType === null ? null : {entityType},
                        geometryField === null ? null : {geometryField: geometryFieldName},
                        {relations},
                        {resourceName: view.TY_NAME},
                        {resourceLongname: view.TY_LONGNAME},
                        {resource: view}
                    )
                    entity.loaded = true
                    parameterService.setMetadata(entity.resourceId, entity)
                    let baseStyles = ['color: white', 'background-color: #2563eb', 'padding: 2px 6px', 'border-radius: 2px'].join(';')
                    console.log(`%cMetadata`, baseStyles, entity.resourceName+':', entity)
                    return true
                }
                return true

            } catch (error) {
                console.error('ERROR FETCHING ENTITYDETAILS FOR', resourceId, error)
                throw error
            }
        },

        fetchViewList: async function (resourceName: string, filter: string, order: string) {
            try {
                let resourceUrlAndParameters = getResourceUrlAndParameters(resourceName, null)
                let url = resourceUrlAndParameters.url
                if(filter && filter.length !== 0) {
                    url += '?$filter=' + filter
                }
                if(order && order.length !== 0) {
                    url += '?$orderby=' + order
                }
                return await handleFetchRawGetRequestOngoing(url, false)
            } catch (error) {
                throw error
            }

        },


        /**
         *
         * @param options
         * ParameterObject
         *
         * @returns
         */
        fetchJsonData: async function (options: {
            filters: AdvancedFilterElement[]
            resourceName: string
            inlinecount: InlineCountType
            top: number | null
            offset: number | null
            orderBy: OrderByInterface[]
            typeDomainOrDmParent?: string | null
            entityGuid?: string | null
            rlType?: string | null
            rlFromItemguid?: string | null
            rlDirection?: string | null
        }) {
            try {
                let {
                    filters,
                    resourceName,
                    inlinecount,
                    top,
                    offset,
                    orderBy,
                    typeDomainOrDmParent,
                    entityGuid,
                    rlType,
                    rlFromItemguid,
                    rlDirection
                } = options
                let url = ''
                let AttachementRelationshipsForAllEntites = 'a44b46db-046b-47a0-9043-52ab469f1fce'
                let finalFilters = filters ? [...filters] : []
                let resourceUrlAndParameters = getResourceUrlAndParameters(resourceName, typeDomainOrDmParent ? typeDomainOrDmParent : null)

                if (resourceUrlAndParameters.defaultFilter) {
                    finalFilters.push(resourceUrlAndParameters.defaultFilter)
                }

                let params = createParamsString('', inlinecount, top, offset)
                let filterString = createFinalFilterString(finalFilters)
                let orderByString = createOrderByString('', orderBy)

                /**
                 * Construct url with parameter and filter string if necessairy
                 */
                url = combineParamsFilterOrderBys(resourceUrlAndParameters.url, params, filterString, orderByString, resourceName)


                /***************************************
                 * Handling URL for UgdmArifacts
                 */
                if (resourceName === 'UgdmArtifacts' && entityGuid && entityGuid !== '') {
                    url =
                        url +
                        "&$relationshipfilter=RL_TYPE eq guid'" +
                        AttachementRelationshipsForAllEntites +
                        "' and RL_FROM eq guid'" +
                        entityGuid +
                        "'"
                }

                /************************************
                 * Handling URL for Relationships
                 */
                if (rlDirection && rlType && rlFromItemguid) {
                    if (rlDirection === 'TO') {
                        url = url + "&$relationshipfilter=RL_TYPE eq guid'" + rlType + "' and RL_TO eq guid'" + rlFromItemguid + "'"
                    } else if (rlDirection === 'FROM') {
                        url = url + "&$relationshipfilter=RL_TYPE eq guid'" + rlType + "' and RL_FROM eq guid'" + rlFromItemguid + "'"
                    }
                }

                return await handleFetchRawGetRequestOngoing(url, false)

            } catch (error) {
                throw error
            }
        },


        /**
         *
         * @param options
         * ParameterObject
         *
         * @returns
         */
        fetchJsonDataInOngoingRequest: async function (options: {
            duration: number,
            filters: AdvancedFilterElement[]
            resourceName: string
            inlinecount: InlineCountType
            top: number | null
            offset: number | null
            orderBy: OrderByInterface[]
            typeDomainOrDmParent?: string | null
            entityGuid?: string | null
            rlType?: string | null
            rlFromItemguid?: string | null
            rlDirection?: string | null

        }) {
            try {
                let {
                    duration,
                    filters,
                    resourceName,
                    inlinecount,
                    top,
                    offset,
                    orderBy,
                    typeDomainOrDmParent,
                    entityGuid,
                    rlType,
                    rlFromItemguid,
                    rlDirection
                } = options
                let url = ''
                let AttachementRelationshipsForAllEntites = 'a44b46db-046b-47a0-9043-52ab469f1fce'
                let finalFilters = filters ? [...filters] : []
                let resourceUrlAndParameters = getResourceUrlAndParameters(resourceName, typeDomainOrDmParent ? typeDomainOrDmParent : null)

                if (resourceUrlAndParameters.defaultFilter) {
                    finalFilters.push(resourceUrlAndParameters.defaultFilter)
                }

                let params = createParamsString('', inlinecount, top, offset)
                let filterString = createFinalFilterString(finalFilters)
                let orderByString = createOrderByString('', orderBy)

                /**
                 * Construct url with parameter and filter string if necessairy
                 */
                url = combineParamsFilterOrderBys(resourceUrlAndParameters.url, params, filterString, orderByString, resourceName)


                /***************************************
                 * Handling URL for UgdmArifacts
                 */
                if (resourceName === 'UgdmArtifacts' && entityGuid && entityGuid !== '') {
                    url =
                        url +
                        "&$relationshipfilter=RL_TYPE eq guid'" +
                        AttachementRelationshipsForAllEntites +
                        "' and RL_FROM eq guid'" +
                        entityGuid +
                        "'"
                }

                /************************************
                 * Handling URL for Relationships
                 */
                if (rlDirection && rlType && rlFromItemguid) {
                    if (rlDirection === 'TO') {
                        url = url + "&$relationshipfilter=RL_TYPE eq guid'" + rlType + "' and RL_TO eq guid'" + rlFromItemguid + "'"
                    } else if (rlDirection === 'FROM') {
                        url = url + "&$relationshipfilter=RL_TYPE eq guid'" + rlType + "' and RL_FROM eq guid'" + rlFromItemguid + "'"
                    }
                }
                return await ongoingRequestHandler(url, requestHeaders.jsonGet, duration, 'json')

            } catch (error) {
                throw error
            }
        },

        fetchCount: async function (
            filters: AdvancedFilterElement[],
            resourceName: string,
            orderBy: OrderByInterface[],
            filterGuid: string,
            entityGuid: string,
            rlType: string,
            rlFromItemguid: string,
            rlDirection: string,
        ) {
            try {
                let url = ''
                let AttachementRelationshipsForAllEntites = 'a44b46db-046b-47a0-9043-52ab469f1fce'
                let finalFilters = [...filters]
                let resourceUrlAndParameters = getResourceUrlAndParameters(resourceName, filterGuid)

                if (resourceUrlAndParameters.defaultFilter) {
                    finalFilters.push(resourceUrlAndParameters.defaultFilter)
                }

                let params = '$count=true&$top=0'
                let filterString = createFinalFilterString(finalFilters)
                let orderByString = createOrderByString('', orderBy)

                /**
                 * Construct url with parameter and filter string if necessairy
                 */
                url = combineParamsFilterOrderBys(resourceUrlAndParameters.url, params, filterString, orderByString, resourceName)

                /***************************************
                 * Handling URL for UgdmArifacts
                 */
                if (resourceName === 'UgdmArtifacts') {
                    url =
                        url +
                        "&$relationshipfilter=RL_TYPE eq guid'" +
                        AttachementRelationshipsForAllEntites +
                        "' and RL_FROM eq guid'" +
                        entityGuid +
                        "'"
                }

                /************************************
                 * Handling URL for Relationships
                 */
                if (rlDirection !== '' && rlType !== '' && rlFromItemguid !== '') {
                    if (rlDirection === 'TO') {
                        url = url + "&$relationshipfilter=RL_TYPE eq guid'" + rlType + "' and RL_TO eq guid'" + rlFromItemguid + "'"
                    } else if (rlDirection === 'FROM') {
                        url = url + "&$relationshipfilter=RL_TYPE eq guid'" + rlType + "' and RL_FROM eq guid'" + rlFromItemguid + "'"
                    }
                }
                return await handleFetchRawGetRequest(url, false)
            } catch (error) {
                throw error
            }
        },

        fetchMeasurementProgrammesForEntityType: async function (entityType: string) {
            try {
                let url = parameterService.appParameters.OdataBaseUrl + `UgdmMeta/UgdmTypeRelationships?$filter=TR_TYPE eq guid'dac82600-b29a-4c1e-b10e-23ca84fb4207' and TR_TO eq guid'${entityType}'`
                return await handleFetchRawGetRequest(url, false)
            } catch (error) {
                throw error
            }
        },

        fetchMeasurementTypesForEntityType: async function (entityType: string) {
            try {
                let url = parameterService.appParameters.OdataBaseUrl + `UgdmMeta/UgdmTypeRelationships?$filter=TR_TYPE eq guid'c31ab0ba-d87c-4f09-b172-3a5dad6685f1' and TR_FROM eq guid'${entityType}'`
                return await handleFetchRawGetRequest(url, false)
            } catch (error) {
                throw error
            }
        },

        // this could be optimized by using tr_position eq 1 -> entity type, TR_POSITION eq 2 -> geometry table type
        fetchEntityTypesOfViews: async function (viewId: string) {
            try {
                let url = parameterService.appParameters.OdataBaseUrl + `UgdmMeta/UgdmTypeRelationships?$filter=TR_TYPE eq guid'5A5C8B7D-67D3-4E60-A5C2-0BD201766FFE' and TR_FROM eq guid'${viewId}' and TR_TO ne guid'd7ed2ac1-55cd-46fc-853a-cfdeb1fc4e7f'`
                return await handleFetchRawGetRequest(url, true)
            } catch (error) {
                throw error
            }
        },


        fetchEntityTypeOfUserView: async function (viewId: string) {
            try {
                let url = parameterService.appParameters.OdataBaseUrl + `UgdmMeta/UgdmTypeRelationships?$filter=TR_TYPE eq guid'5A5C8B7D-67D3-4E60-A5C2-0BD201766FFE' and TR_FROM eq guid'${viewId}' and TR_POSITION eq 2`
                return await handleFetchRawGetRequest(url, true)
            } catch (error) {
                throw error
            }
        },

        fetchFromTypeRelationships: async function (TR_FROM: string, TR_TYPE: string) {
            try {
                let url = parameterService.appParameters.OdataBaseUrl + `UgdmMeta/UgdmTypeRelationships?$filter=TR_TYPE eq guid'${TR_TYPE}' and TR_FROM eq guid'${TR_FROM}'`
                return await handleFetchRawGetRequest(url, false)
            } catch (error) {
                throw error
            }
        },

        fetchToTypeRelationships: async function (TR_TO: string, TR_TYPE: string) {
            try {
                let url = parameterService.appParameters.OdataBaseUrl + `UgdmMeta/UgdmTypeRelationships?$filter=TR_TYPE eq guid'${TR_TYPE}' and TR_TO eq guid'${TR_TO}'`
                return await handleFetchRawGetRequest(url, false)
            } catch (error) {
                throw error
            }
        },

        fetchMeasurementTypesForMeasurementProgram: async function (measureProgramGuid: string) {
            try {
                let url = parameterService.appParameters.OdataBaseUrl + `UgdmMeta/UgdmTypeRelationships?$filter=TR_TYPE eq guid'525044d8-2933-4572-a9f7-f3fe64b82f4d' and TR_FROM eq guid'${measureProgramGuid}'`
                return await handleFetchRawGetRequest(url, false)
            } catch (error) {
                throw error
            }
        },

        fetchAttachementsCount: async function (entityGuid: string) {
            try {
                let AttachementRelationshipsForAllEntites = 'a44b46db-046b-47a0-9043-52ab469f1fce'
                let url = parameterService.appParameters.applicationBaseUrl + 'BcOdata/UgdmCore/UgdmRelationships'
                url = url + "?$filter=RL_TYPE eq guid'" + AttachementRelationshipsForAllEntites + "' and RL_FROM eq guid'" + entityGuid + "'"
                // url = url + "&$relationshipfilter=RL_TYPE eq guid'" + AttachementRelationshipsForAllEntites + "' and RL_FROM eq guid'" + entityGuid + "'"
                return await handleFetchRawGetRequest(url, false)

            } catch (error) {
                throw error
            }
        },

        checkColumnForExistenNullValues: async function (dataviewName: string, column: string) {
            try {
                let url = `${parameterService.appParameters.applicationBaseUrl}BcOdataApp/Ugdm/${dataviewName}?$inlinecount=allpages&$top=1&$filter=(${column} eq null)`
                return await handleFetchRawGetRequest(url, false)
            } catch (error) {
                throw error
            }
        },

        getEntitiesFromEntityType: async function (entityType: string) {
            try {
                const url = parameterService.appParameters.OdataBaseUrl + `UgdmCore/UgdmEntities?$filter=EY_TYPE eq guid'${entityType}'`
                return await handleFetchRawGetRequest(url, false)
            } catch (error) {
                throw error
            }
        },

        fetchThemeTreeLevel: async function (parentGuid: string, layers: boolean, views: boolean, rights: boolean) {
            try {
                const cultureGuid = parameterService.appParameters.languageGuid
                let url = parameterService.appParameters.OdataBaseUrl + `UgdmMeta/UgdmThematicNodes?layers=${layers}&views=${views}&checkRights=${rights}&$filter=Parent eq guid'${parentGuid}' and Culture eq guid'${cultureGuid}'`
                return await handleFetchRawGetRequest(url, false)
            } catch (error) {
                throw error
            }
        },

        fetchThemeTree: async function (themetreeGuid: string) {
            try {
                const cultureGuid = parameterService.appParameters.languageGuid
                let url = parameterService.appParameters.OdataBaseUrl + `UgdmMeta/UgdmThematicNodesWithRights?$filter=Parent eq guid'${themetreeGuid}' and Culture eq guid'${cultureGuid}'`
                return await handleFetchRawGetRequest(url, false)
            } catch (error) {
                throw error
            }
        },

        fetchEntitiesForArtifact: async function (artifactId: string) {
            try {
                let url = parameterService.appParameters.OdataBaseUrl + 'UgdmCore/UgdmEntities'
                let AttachementRelationshipsForAllEntites = 'a44b46db-046b-47a0-9043-52ab469f1fce'
                url = url + "?$relationshipfilter=RL_TYPE eq guid'" + AttachementRelationshipsForAllEntites + "' and RL_TO eq guid'" + artifactId + "'"
                return await handleFetchRawGetRequest(url, false)
            } catch (error) {
                throw error
            }
        },

        fetchAttachements: async function (
            filters: AdvancedFilterElement[],
            resourceName: string,
            inlinecount: InlineCountType,
            top: number | null,
            offset: number | null,
            orderBy: OrderByInterface[],
            entityGuid: string,
        ) {
            try {
                let url = parameterService.appParameters.OdataBaseUrl + 'UgdmCore/UgdmArtifacts'
                let AttachementRelationshipsForAllEntites = 'a44b46db-046b-47a0-9043-52ab469f1fce'
                let params = createParamsString('', inlinecount, top, offset)
                let filterString = createFinalFilterString(filters)
                let orderByString = createOrderByString('', orderBy)
                url = combineParamsFilterOrderBys(url, params, filterString, orderByString, resourceName)
                url = url + "&$relationshipfilter=RL_TYPE eq guid'" + AttachementRelationshipsForAllEntites + "' and RL_FROM eq guid'" + entityGuid + "'"
                return await handleFetchRawGetRequest(url, false)

            } catch (error) {
                throw error
            }
        },

        fetchAttachement: async function (
            filters: AdvancedFilterElement[],
            resourceName: string,
            inlinecount: InlineCountType,
            top: number | null,
            offset: number | null,
            orderBy: OrderByInterface[],
        ) {
            try {
                let url = parameterService.appParameters.OdataBaseUrl + 'UgdmCore/UgdmArtifacts'
                let AttachementRelationshipsForAllEntites = 'a44b46db-046b-47a0-9043-52ab469f1fce'
                let params = createParamsString('', inlinecount, top, offset)
                let filterString = createFinalFilterString(filters)
                let orderByString = createOrderByString('', orderBy)
                url = combineParamsFilterOrderBys(url, params, filterString, orderByString, resourceName)
                url = url + "&$relationshipfilter=RL_TYPE eq guid'" + AttachementRelationshipsForAllEntites + "'"
                return await handleFetchRawGetRequest(url, false)

            } catch (error) {
                throw error
            }
        },

        fetchRelationshipCount: async function (rlType: string, entityGuid: string, rldirection: string) {
            try {
                let url = parameterService.appParameters.applicationBaseUrl + 'BcOdata/UgdmCore/UgdmRelationships'
                if (rldirection === 'TO') {
                    url = url + "?$filter=RL_TYPE eq guid'" + rlType + "' and RL_TO eq guid'" + entityGuid + "'"
                } else if (rldirection === 'FROM') {
                    url = url + "?$filter=RL_TYPE eq guid'" + rlType + "' and RL_FROM eq guid'" + entityGuid + "'"
                }
                url = url + '&$count=true&$top=0'
                // return await ongoingRequestHandler(url, requestHeaders.jsonGet, 1000, 'json')
                return await handleFetchRawGetRequest(url, false)
            } catch (error) {
                throw error
            }
        },

        fetchAllRelationshipCounts: async function (entityGuid: string) {
            try {
                let url = parameterService.appParameters.applicationBaseUrl + 'BcOdata/UgdmCore/UgdmRelationships'
                url = url + "?$filter=(RL_TO eq guid'" + entityGuid + "' or RL_FROM eq guid'" + entityGuid + "')"
                // return await ongoingRequestHandler(url, requestHeaders.jsonGet, 1000, 'json')
                return await handleFetchRawGetRequest(url, false)
            } catch (error) {
                throw error
            }
        },

        fetchViewRights: async function (roleIds: string[], dataviewGuid: string) {
            try {
                const oDataApp = parameterService.get(PARAMS.urls.applicationBaseUrl) + 'BcOdata/UgdmIdentity/UgdmTypeRoles'
                let url = oDataApp + '?$filter='
                let filter = ''

                for (let i = 0; i < roleIds.length; i++) {
                    filter = filter + `ROLE_ID eq guid'${roleIds[i]}' or `
                }

                filter = filter.substring(0, filter.length - 4)
                url = url + `(${filter})`

                let rights = {
                    canRead: false,
                    canCreate: false,
                    canModify: false,
                    canDelete: false,
                }
                const data = await ajaxService.fetchRaw(requestHeaders.jsonGet, url)
                let items = null
                if (data.status === 200) {
                    items = await data.json()
                    let elements = items.value
                    elements = elements.filter((e) => e.TY_ID === dataviewGuid)
                    for (let i = 0; i < elements.length; i++) {
                        if (elements[i]['canRead'] === true) {
                            rights.canRead = true
                        }
                        if (elements[i]['canModify'] === true) {
                            rights.canModify = true
                        }
                        if (elements[i]['canCreate'] === true) {
                            rights.canCreate = true
                        }
                        if (elements[i]['canDelete'] === true) {
                            rights.canDelete = true
                        }
                    }
                    return rights
                } else if (data.status === 404) {
                    return rights
                } else {
                    throw new Error(data.status + ',' + data.statusText + ',' + data.url)
                }
            } catch (error) {
                throw error
            }
        },

        createExcelURL: (params: {
            resourceName: string,
            filters: AdvancedFilterElement[],
            orderBy: OrderByInterface[],
            userfriendly: boolean,
            entityGuid?: string,
            rlType?: string,
            rlTo?: string,
            rlDirection?: string,
            top?: number,
        }) => {

            const {resourceName, filters, orderBy, rlType, rlTo, rlDirection, userfriendly, top, entityGuid} = params

            let urlParams = createParamsString('', 'allpages')
            let filterString = createFinalFilterString(filters)
            let orderByString = createOrderByString('', orderBy)
            if (top) {
                urlParams = urlParams + '&$top=' + top
            }
            let url = ''

            if (isUserDefinedView(resourceName)) {

                if (!rlType && !rlDirection && !rlTo) {
                    const oDataApp = parameterService.get(PARAMS.urls.ApplicationDataServiceBaseUrl)
                    url = oDataApp + resourceName
                    url = combineParamsFilterOrderBys(url, urlParams, filterString, orderByString, resourceName)
                    url = url + '&$userfriendly=' + userfriendly + '&$format=xlsx'

                } else if (rlType && rlDirection && rlTo) {
                    const oDataApp = parameterService.get(PARAMS.urls.ApplicationDataServiceBaseUrl)
                    url = oDataApp + resourceName
                    url = combineParamsFilterOrderBys(url, urlParams, filterString, orderByString, resourceName)
                    if (rlDirection === 'TO') {
                        url = url + "&$relationshipfilter=RL_TYPE eq guid'" + rlType + "' and RL_TO eq guid'" + rlTo + "'"
                    } else if (rlDirection === 'FROM') {
                        url = url + "&$relationshipfilter=RL_TYPE eq guid'" + rlType + "' and RL_FROM eq guid'" + rlTo + "'"
                    }
                    url = url + '&$userfriendly=' + userfriendly + '&$format=xlsx'

                } else {
                    console.log('Not all required information for relationship-export for entity table.')
                    return null
                }

            } else {
                switch (resourceName) {
                    case 'UgdmActions': {
                        url = parameterService.appParameters.OdataBaseUrl + 'UgdmCore/UgdmActions'
                        url = combineParamsFilterOrderBys(url, urlParams, filterString, orderByString, resourceName)
                        url = url + '&$userfriendly=' + userfriendly + '&$format=xlsx'
                        break;
                    }
                    case 'UgdmArtifacts': {
                        url = parameterService.appParameters.OdataBaseUrl + 'UgdmCore/UgdmArtifacts'
                        url = combineParamsFilterOrderBys(url, urlParams, filterString, orderByString, resourceName)
                        let AttachementRelationshipsForAllEntites = 'a44b46db-046b-47a0-9043-52ab469f1fce'
                        if (entityGuid) {
                            url =
                                url +
                                "&$relationshipfilter=RL_TYPE eq guid'" +
                                AttachementRelationshipsForAllEntites +
                                "' and RL_FROM eq guid'" +
                                entityGuid +
                                "'"
                        }

                        url = url + '&$userfriendly=' + userfriendly + '&$format=xlsx'
                        break;
                    }
                    case 'UgdmTypes': {
                        const OdataBaseUrl = parameterService.get(PARAMS.urls.applicationBaseUrl)
                        url = OdataBaseUrl + 'BcOdata/UgdmMeta/UgdmTypes'
                        url = combineParamsFilterOrderBys(url, urlParams, filterString, orderByString, resourceName)
                        url = url + '&$userfriendly=' + userfriendly + '&$format=xlsx'
                        break;
                    }
                    case 'UgdmSystems': {
                        const OdataBaseUrl = parameterService.get(PARAMS.urls.applicationBaseUrl)
                        url = OdataBaseUrl + 'BcOdata/UgdmMeta/UgdmTypes'
                        url = combineParamsFilterOrderBys(url, urlParams, filterString, orderByString, resourceName)
                        url = url + '&$userfriendly=' + userfriendly + '&$format=xlsx'
                        break;
                    }
                    case 'UgdmAssociations' : {

                        break;
                    }
                    case 'UgdmAttributes' : {
                        const OdataBaseUrl = parameterService.get(PARAMS.urls.applicationBaseUrl)
                        url = OdataBaseUrl + 'BcOdata/UgdmMeta/UgdmTypes'
                        url = combineParamsFilterOrderBys(url, urlParams, filterString, orderByString, resourceName)
                        url = url + '&$userfriendly=' + userfriendly + '&$format=xlsx'
                        break;
                    }
                    case 'UgdmDomains': {
                        const OdataBaseUrl = parameterService.get(PARAMS.urls.applicationBaseUrl)
                        url = OdataBaseUrl + 'BcOdata/UgdmMeta/UgdmDomains'
                        url = combineParamsFilterOrderBys(url, urlParams, filterString, orderByString, resourceName)
                        url = url + '&$userfriendly=' + userfriendly + '&$format=xlsx'
                        break;
                    }
                    case 'UgdmLocationTypes' : {
                        const OdataBaseUrl = parameterService.get(PARAMS.urls.applicationBaseUrl)
                        url = OdataBaseUrl + 'BcOdata/UgdmMeta/UgdmTypes'
                        url = combineParamsFilterOrderBys(url, urlParams, filterString, orderByString, resourceName)
                        url = url + '&$userfriendly=' + userfriendly + '&$format=xlsx'
                        break;
                    }
                    case 'UgdmMeasurementTypes' : {

                        break;
                    }
                    case 'UgdmSrs' : {
                        const OdataBaseUrl = parameterService.get(PARAMS.urls.applicationBaseUrl)
                        url = OdataBaseUrl + 'BcOdata/UgdmMeta/UgdmTypes'
                        url = combineParamsFilterOrderBys(url, urlParams, filterString, orderByString, resourceName)
                        url = url + '&$userfriendly=' + userfriendly + '&$format=xlsx'
                        break;
                    }
                    case 'UgdmTypesWithChildren' : {

                        break;
                    }
                    case 'UgdmTypeDomains' : {

                        break;
                    }
                    case 'UgdmTypeRelationships' : {

                        break;
                    }
                    case 'UgdmUnits' : {

                        break;
                    }
                    case 'UgdmEntityTypes' : {

                        break;
                    }
                    case 'UgdmEntityTypeAttributes' : {

                        break;
                    }
                    case 'UgdmEntityViews' : {

                        break;
                    }
                    case 'UgdmUsers' : {
                        const OdataBaseUrl = parameterService.get(PARAMS.urls.applicationBaseUrl)
                        url = OdataBaseUrl + 'BcOdata/UgdmIdentity/UgdmUsers'
                        url = combineParamsFilterOrderBys(url, urlParams, filterString, orderByString, resourceName)
                        url = url + '&$userfriendly=' + userfriendly + '&$format=xlsx'
                        break;
                    }
                    case 'UgdmEntityViewAttributes' : {

                        break;
                    }
                    case 'UgdmRoles': {
                        const OdataBaseUrl = parameterService.get(PARAMS.urls.applicationBaseUrl)
                        url = OdataBaseUrl + 'BcOdata/UgdmIdentity/UgdmRoles'
                        url = combineParamsFilterOrderBys(url, urlParams, filterString, orderByString, resourceName)
                        url = url + '&$userfriendly=' + userfriendly + '&$format=xlsx'
                        break;
                    }
                    case 'UgdmRelationshipAssociations' : {
                        const OdataBaseUrl = parameterService.get(PARAMS.urls.applicationBaseUrl)
                        url = OdataBaseUrl + 'BcOdata/UgdmMeta/UgdmTypes'
                        url = combineParamsFilterOrderBys(url, urlParams, filterString, orderByString, resourceName)
                        url = url + '&$userfriendly=' + userfriendly + '&$format=xlsx'
                        break;
                    }
                }
            }
            return url

        },


        getExelUrl: (
            tableType: 'action' | 'entity' | 'relation' | 'measurement' | 'types' | 'domains' | 'users' | 'roles' | 'mesurementtypes' | 'attachment',
            filters: AdvancedFilterElement[],
            resourceName: string,
            orderBy: OrderByInterface[],
            rlType: string,
            rlTo: string,
            rldirection: string,
            userfriendly: boolean,
            top?: number,
        ): string => {
            let params = createParamsString('', 'allpages')
            let filterString = createFinalFilterString(filters)
            // let filterString = createFilterString('', filters)
            let orderByString = createOrderByString('', orderBy)
            if (top) {
                params = params + '&$top=' + top
            }
            let url = ''
            if (tableType === 'entity') {
                const oDataApp = parameterService.get(PARAMS.urls.ApplicationDataServiceBaseUrl)
                url = oDataApp + resourceName
                url = combineParamsFilterOrderBys(url, params, filterString, orderByString, resourceName)
                url = url + '&$userfriendly=' + userfriendly + '&$format=xlsx'

            } else if (tableType === 'attachment') {
                const OdataBaseUrl = parameterService.get(PARAMS.urls.applicationBaseUrl)
                url = OdataBaseUrl + 'BcOdata/UgdmCore/UgdmArtifacts'
                url = combineParamsFilterOrderBys(url, params, filterString, orderByString, resourceName)

                if (rldirection === 'TO') {
                    url = url + "&$relationshipfilter=RL_TYPE eq guid'" + rlType + "' and RL_TO eq guid'" + rlTo + "'"
                } else if (rldirection === 'FROM') {
                    url = url + "&$relationshipfilter=RL_TYPE eq guid'" + rlType + "' and RL_FROM eq guid'" + rlTo + "'"
                }

                url = url + '&$userfriendly=' + userfriendly + '&$format=xlsx'

            } else if (tableType === 'types') {
                const OdataBaseUrl = parameterService.get(PARAMS.urls.applicationBaseUrl)
                url = OdataBaseUrl + 'BcOdata/UgdmMeta/UgdmTypes'
                url = combineParamsFilterOrderBys(url, params, filterString, orderByString, resourceName)
                url = url + '&$userfriendly=' + userfriendly + '&$format=xlsx'

            } else if (tableType === 'users') {
                const OdataBaseUrl = parameterService.get(PARAMS.urls.applicationBaseUrl)
                url = OdataBaseUrl + 'BcOdata/UgdmIdentity/UgdmUsers'
                url = combineParamsFilterOrderBys(url, params, filterString, orderByString, resourceName)
                url = url + '&$userfriendly=' + userfriendly + '&$format=xlsx'

            } else if (tableType === 'roles') {
                const OdataBaseUrl = parameterService.get(PARAMS.urls.applicationBaseUrl)
                url = OdataBaseUrl + 'BcOdata/UgdmIdentity/UgdmRoles'
                url = combineParamsFilterOrderBys(url, params, filterString, orderByString, resourceName)
                url = url + '&$userfriendly=' + userfriendly + '&$format=xlsx'

            } else if (tableType === 'domains') {
                const OdataBaseUrl = parameterService.get(PARAMS.urls.applicationBaseUrl)
                url = OdataBaseUrl + 'BcOdata/UgdmMeta/UgdmDomains'
                url = combineParamsFilterOrderBys(url, params, filterString, orderByString, resourceName)
                url = url + '&$userfriendly=' + userfriendly + '&$format=xlsx'

            } else if (tableType === 'measurement') {
                const OdataBaseUrl = parameterService.get(PARAMS.urls.applicationBaseUrl)
                url = OdataBaseUrl + 'BcOdata/UgdmCore/UgdmMeasurements'
                url = combineParamsFilterOrderBys(url, params, filterString, orderByString, resourceName)
                url = url + '&$userfriendly=' + userfriendly + '&$format=xlsx'

            } else if (tableType === 'relation') {
                const oDataApp = parameterService.get(PARAMS.urls.ApplicationDataServiceBaseUrl)
                url = oDataApp + resourceName
                url = combineParamsFilterOrderBys(url, params, filterString, orderByString, resourceName)
                if (rldirection === 'TO') {
                    url = url + "&$relationshipfilter=RL_TYPE eq guid'" + rlType + "' and RL_TO eq guid'" + rlTo + "'"
                } else if (rldirection === 'FROM') {
                    url = url + "&$relationshipfilter=RL_TYPE eq guid'" + rlType + "' and RL_FROM eq guid'" + rlTo + "'"
                }
                url = url + '&$userfriendly=' + userfriendly + '&$format=xlsx'

            } else if (tableType === 'action') {
                url = parameterService.appParameters.OdataBaseUrl + 'UgdmCore/UgdmActions'
                url = combineParamsFilterOrderBys(url, params, filterString, orderByString, resourceName)
                url = url + '&$userfriendly=' + userfriendly + '&$format=xlsx'
            }
            return url
        },

        getGeoPackageURL: (
            tableType: 'action' | 'entity' | 'relation' | 'measurement' | 'types' | 'domains' | 'users' | 'roles' | 'mesurementtypes' | 'attachment',
            filters: AdvancedFilterElement[],
            resourceName: string,
            orderBy: OrderByInterface[],
            rlType: string,
            rlTo: string,
            rldirection: string,
            userfriendly: boolean,
            top?: number,
        ): string => {
            let params = createParamsString('', 'allpages')
            let filterString = createFinalFilterString(filters)
            // let filterString = createFilterString('', filters)
            let orderByString = createOrderByString('', orderBy)
            if (top) {
                params = params + '&$top=' + top
            }
            let url = ''

            if (tableType === 'entity') {
                const oDataApp = parameterService.get(PARAMS.urls.ApplicationDataServiceBaseUrl)
                url = oDataApp + resourceName
                url = combineParamsFilterOrderBys(url, params, filterString, orderByString, resourceName)
                url = url + '&$userfriendly=' + userfriendly + '&$format=gpkg'

            } else if (tableType === 'attachment') {
                const OdataBaseUrl = parameterService.get(PARAMS.urls.applicationBaseUrl)
                url = OdataBaseUrl + 'BcOdata/UgdmCore/UgdmArtifacts'
                url = combineParamsFilterOrderBys(url, params, filterString, orderByString, resourceName)

                if (rldirection === 'TO') {
                    url = url + "&$relationshipfilter=RL_TYPE eq guid'" + rlType + "' and RL_TO eq guid'" + rlTo + "'"
                } else if (rldirection === 'FROM') {
                    url = url + "&$relationshipfilter=RL_TYPE eq guid'" + rlType + "' and RL_FROM eq guid'" + rlTo + "'"
                }

                url = url + '&$userfriendly=' + userfriendly + '&$format=gpkg'

            } else if (tableType === 'types') {
                const OdataBaseUrl = parameterService.get(PARAMS.urls.applicationBaseUrl)
                url = OdataBaseUrl + 'BcOdata/UgdmMeta/UgdmTypes'
                url = combineParamsFilterOrderBys(url, params, filterString, orderByString, resourceName)
                url = url + '&$userfriendly=' + userfriendly + '&$format=gpkg'

            } else if (tableType === 'users') {
                const OdataBaseUrl = parameterService.get(PARAMS.urls.applicationBaseUrl)
                url = OdataBaseUrl + 'BcOdata/UgdmIdentity/UgdmUsers'
                url = combineParamsFilterOrderBys(url, params, filterString, orderByString, resourceName)
                url = url + '&$userfriendly=' + userfriendly + '&$format=gpkg'

            } else if (tableType === 'roles') {
                const OdataBaseUrl = parameterService.get(PARAMS.urls.applicationBaseUrl)
                url = OdataBaseUrl + 'BcOdata/UgdmIdentity/UgdmRoles'
                url = combineParamsFilterOrderBys(url, params, filterString, orderByString, resourceName)
                url = url + '&$userfriendly=' + userfriendly + '&$format=gpkg'

            } else if (tableType === 'domains') {
                const OdataBaseUrl = parameterService.get(PARAMS.urls.applicationBaseUrl)
                url = OdataBaseUrl + 'BcOdata/UgdmMeta/UgdmDomains'
                url = combineParamsFilterOrderBys(url, params, filterString, orderByString, resourceName)
                url = url + '&$userfriendly=' + userfriendly + '&$format=gpkg'

            } else if (tableType === 'measurement') {
                const OdataBaseUrl = parameterService.get(PARAMS.urls.applicationBaseUrl)
                url = OdataBaseUrl + 'BcOdata/UgdmCore/UgdmMeasurements'
                url = combineParamsFilterOrderBys(url, params, filterString, orderByString, resourceName)
                url = url + '&$userfriendly=' + userfriendly + '&$format=gpkg'

            } else if (tableType === 'relation') {
                const oDataApp = parameterService.get(PARAMS.urls.ApplicationDataServiceBaseUrl)
                url = oDataApp + resourceName
                url = combineParamsFilterOrderBys(url, params, filterString, orderByString, resourceName)
                if (rldirection === 'TO') {
                    url = url + "&$relationshipfilter=RL_TYPE eq guid'" + rlType + "' and RL_TO eq guid'" + rlTo + "'"
                } else if (rldirection === 'FROM') {
                    url = url + "&$relationshipfilter=RL_TYPE eq guid'" + rlType + "' and RL_FROM eq guid'" + rlTo + "'"
                }
                url = url + '&$userfriendly=' + userfriendly + '&$format=gpkg'

            } else if (tableType === 'action') {
                url = parameterService.appParameters.OdataBaseUrl + 'UgdmCore/UgdmActions'
                url = combineParamsFilterOrderBys(url, params, filterString, orderByString, resourceName)
                url = url + '&$userfriendly=' + userfriendly + '&$format=gpkg'
            }

            return url
        },

        getWordUrl: (
            tableType: 'action' | 'entity' | 'relation',
            filters: AdvancedFilterElement[],
            resourceName: string,
            orderBy: OrderByInterface[],
            rlType: string,
            rlTo: string,
            rldirection: string,
            userfriendly: boolean,
            templateId?: string,
            asPdf?: boolean
        ): string => {
            let params = createParamsString('', 'allpages')
            let filterString = createFinalFilterString(filters)
            // let filterString = createFilterString('', filters)
            let orderByString = createOrderByString('', orderBy)
            let url = ''

            if (tableType === 'entity') {
                const oDataApp = parameterService.get(PARAMS.urls.ApplicationDataServiceBaseUrl)
                url = oDataApp + resourceName
                url = combineParamsFilterOrderBys(url, params, filterString, orderByString, resourceName)
                if (templateId) {
                    url = url + '&$WordTemplate=' + templateId
                }
                if(asPdf && asPdf === true) {
                    url += '&$format=pdf';
                } else {
                    url += '&$format=docx';
                }
                
            } else if (tableType === 'relation') {
                const oDataApp = parameterService.get(PARAMS.urls.ApplicationDataServiceBaseUrl)
                url = oDataApp + resourceName
                url = combineParamsFilterOrderBys(url, params, filterString, orderByString, resourceName)

                if (rldirection === 'TO') {
                    url = url + "&$relationshipfilter=RL_TYPE eq guid'" + rlType + "' and RL_TO eq guid'" + rlTo + "'"
                } else if (rldirection === 'FROM') {
                    url = url + "&$relationshipfilter=RL_TYPE eq guid'" + rlType + "' and RL_FROM eq guid'" + rlTo + "'"
                }
                if (templateId) {
                    url = url + '&$WordTemplate=' + templateId
                }
                url = url + '&$format=docx'
            }
            return url
        },

        getQRBillUrl: (
            tableType: 'entity' | 'relation',
            filters: AdvancedFilterElement[],
            resourceName: string,
            orderBy: OrderByInterface[],
            rlType: string,
            rlTo: string,
            rldirection: string,
            userfriendly: boolean,
            templateId: string,
        ): string => {
            let params = createParamsString('', 'allpages')
            let filterString = createFinalFilterString(filters)
            // let filterString = createFilterString('', filters)
            let orderByString = createOrderByString('', orderBy)
            let url = ''

            if (tableType === 'entity') {
                const oDataApp = parameterService.get(PARAMS.urls.ApplicationDataServiceBaseUrl)
                url = oDataApp + resourceName
                url = combineParamsFilterOrderBys(url, params, filterString, orderByString, resourceName)
                url = url + '&$QRBillTemplate=' + templateId
                url = url + '&$format=qrbill'
            } else if (tableType === 'relation') {
                const oDataApp = parameterService.get(PARAMS.urls.ApplicationDataServiceBaseUrl)
                url = oDataApp + resourceName
                url = combineParamsFilterOrderBys(url, params, filterString, orderByString, resourceName)

                if (rldirection === 'TO') {
                    url = url + "&$relationshipfilter=RL_TYPE eq guid'" + rlType + "' and RL_TO eq guid'" + rlTo + "'"
                } else if (rldirection === 'FROM') {
                    url = url + "&$relationshipfilter=RL_TYPE eq guid'" + rlType + "' and RL_FROM eq guid'" + rlTo + "'"
                }
                url = url + '&$QRBillTemplate=' + templateId
                url = url + '&$format=qrbill'
            }
            return url
        },

        fetchEntityActions: async function (
            filters: AdvancedFilterElement[],
            resourceName: string,
            inlinecount: InlineCountType,
            top: number | null,
            offset: number | null,
            orderBy: OrderByInterface[],
        ) {
            try {
                let url = parameterService.appParameters.OdataBaseUrl + 'UgdmCore/UgdmActions'
                let params = createParamsString('', inlinecount, top, offset)
                let filterString = createFinalFilterString(filters)
                let orderByString = createOrderByString('', orderBy)
                url = combineParamsFilterOrderBys(url, params, filterString, orderByString, resourceName)
                return await handleFetchRawGetRequest(url, false)
            } catch (error) {
                throw error
            }
        },

        fetchEntityActionCount: async function (filters: AdvancedFilterElement[]) {
            try {
                let url = parameterService.appParameters.OdataBaseUrl + 'UgdmCore/UgdmActions?$count=true&$top=0&'
                let filterString = createFinalFilterString(filters)
                url = url + filterString
                return await handleFetchRawGetRequest(url, false)
            } catch (error) {
                throw error
            }
        },

        checkMeasurementExistence: async function (entityguid: string) {
            try {
                let url = parameterService.appParameters.OdataBaseUrl + `UgdmCore/UgdmMeasurements?$count&$filter=ME_ENTITY eq guid'${entityguid}'&$top=0`
                const data = await ajaxService.fetchRaw(requestHeaders.jsonGet, url)
                let items = null
                if (data.status === 200) {
                    items = await data.json()
                    if (items['odata.count'] && items['odata.count'] !== '0') {
                        return true
                    }
                } else if (data.status === 404) {
                    items = null
                    return false
                } else {
                    throw new Error(data.status + ',' + data.statusText + ',' + data.url)
                }
                return false
            } catch (error) {
                throw error
            }
        },

        updateResource: async function (resourceName: string, postData: string) {
            const oDataApp = parameterService.get(PARAMS.urls.ApplicationDataServiceBaseUrl)
            let url = oDataApp + resourceName
            try {
                const data = await ajaxService.fetchPost(requestHeaders.jsonPost, url, postData)
                // console.log(data)
                let updatedItem = null
                updatedItem = await data.json()
                return updatedItem
            } catch (error) {
                throw error
            }
        },

        getUgdmTypeDomainsByDomain: async function (domain: string) {
            try {
                let url = parameterService.appParameters.OdataBaseUrl + `UgdmMeta/UgdmTypeDomains?%24filter=TD_DOMAIN eq guid'${domain}'`
                return await handleFetchRawGetRequest<UgdmTypeDomain[]>(url, true)
            } catch (error) {
                throw error
            }
        },


        getUgdmDomainsByParent: async function (parentDomain: string) {
            try {
                let url = parameterService.appParameters.OdataBaseUrl + `UgdmMeta/UgdmDomains?%24filter=DM_PARENT eq guid'${parentDomain}'`
                return await handleFetchRawGetRequest<UgdmType[]>(url, true)
            } catch (error) {
                throw error
            }
        },

        getUgdmTypesByDomain: async function (domain: string) {
            try {
                let url = parameterService.appParameters.OdataBaseUrl + `UgdmMeta/UgdmTypes?%24filter=TY_DOMAIN eq guid'${domain}'`
                return await handleFetchRawGetRequest<UgdmType[]>(url, true)
            } catch (error) {
                throw error
            }
        },
        getCachedUgdmTypesByDomain,

        getUgdmDomainById: async function (domain: string) {
            try {
                let url = parameterService.appParameters.OdataBaseUrl + `UgdmMeta/UgdmDomains?%24filter=DM_ID eq guid'${domain}'`
                const result = await ajaxService.fetchRaw(requestHeaders.xmlGet, url)
                if (result.status === 200) {
                    const jsonData = await result.json()
                    return jsonData.value[0] as UgdmDomain
                } else if (result.status === 404) {
                    return null
                } else {
                    throw new Error(result.status + ' \n' + result.statusText + ' \n' + result.url)
                }
            } catch (error) {
                throw error
            }
        },

        getDomains: async function () {
            try {
                let url = parameterService.appParameters.OdataBaseUrl + `UgdmMeta/UgdmDomains`
                return await handleFetchRawGetRequest<UgdmDomain[]>(url, true)
            } catch (error) {
                throw error
            }
        },

        getTypeDomains: async function () {
            try {
                let url = parameterService.appParameters.OdataBaseUrl + `UgdmMeta/UgdmTypeDomains`
                return await handleFetchRawGetRequest<UgdmTypeDomain[]>(url, true)
            } catch (error) {
                throw error
            }
        },


        getUgdmChildDomainsByDomain: async function (domain: string) {
            try {
                let url = parameterService.appParameters.OdataBaseUrl + `UgdmMeta/UgdmDomains?%24filter=DM_PARENT eq guid'${domain}'`
                return await handleFetchRawGetRequest<UgdmDomain[]>(url, true)
            } catch (error) {
                throw error
            }
        },

        getDocumentCategories: async function (domainId: string) {
            try {
                let url = parameterService.appParameters.OdataBaseUrl + `UgdmMeta/UgdmDomains?%24filter=DM_PARENT eq guid'${domainId}'`
                return await handleFetchRawGetRequest(url, true)
            } catch (error) {
                throw error
            }
        },

        getEntityDomainsByEntityIdArray: async function (entityArray: string[]) {
            try {
                let data = []
                for (let i = 0; i < entityArray.length; i++) {
                    let requestData = await getEntityDomainsByEntityIdLocal(entityArray[i])
                    data.push(requestData)
                }
                return data
            } catch (error) {
                throw error
            }
        },

        getEntityDomainsByEntityId: async function (entityId: string) {
            try {
                let url = parameterService.appParameters.OdataBaseUrl + `UgdmCore/UgdmEntityDomains?%24filter=ED_ENTITY eq guid'${entityId}'`
                return await handleFetchRawGetRequest(url, true)
            } catch (error) {
                throw error
            }
        },

        getUgdmTypesByDomainAndStringFilter: async function (domain: string, attribute: string, value: string) {
            try {
                let url = parameterService.appParameters.OdataBaseUrl + `UgdmMeta/UgdmTypes?%24filter=TY_DOMAIN eq guid'${domain}' and ${attribute} eq '${value}'`
                return await handleFetchRawGetRequest<UgdmType[]>(url, true)
            } catch (error) {
                throw error
            }
        },

        getAttributeById: async function (attributeId: string) {
            try {
                let url =
                    parameterService.appParameters.OdataBaseUrl +
                    `UgdmMeta/UgdmTypes?%24filter=(AT_ID eq guid'${attributeId}' and TY_DOMAIN eq guid'adae0818-b21a-439e-8712-1c7ca826bc8a')`
                return await handleFetchRawGetRequest<EntityAttribute>(url, true)
            } catch (error) {
                throw error
            }
        },

        getAttributesByEntityTypes: async function (entityType: string) {
            try {
                let url =
                    parameterService.appParameters.OdataBaseUrl +
                    `UgdmMeta/UgdmTypes?%24filter=(AT_ENTITY_TYPE eq guid'${entityType}' and TY_DOMAIN eq guid'adae0818-b21a-439e-8712-1c7ca826bc8a')`
                return await handleFetchRawGetRequest<EntityAttribute[]>(url, true)
            } catch (error) {
                throw error
            }
        },

        getUgdmActionById: async function (actionId: string) {
            try {
                let url = parameterService.appParameters.OdataBaseUrl + `UgdmMeta/UgdmActions?%24filter=AC_ID eq guid'${actionId}'`
                const result = await ajaxService.fetchRaw(requestHeaders.xmlGet, url)
                if (result.status === 200) {
                    const jsonData = await result.json()
                    return jsonData.value[0]
                } else if (result.status === 404) {
                    return null
                } else {
                    throw new Error(result.status + ' \n' + result.statusText + ' \n' + result.url)
                }
            } catch (error) {
                throw error
            }
        },

        getUgdmTypeById: async function (typeId: string, domainId?: string) {
            try {
                let url = parameterService.appParameters.OdataBaseUrl + `UgdmMeta/UgdmTypes(guid'${typeId}')`
                if(domainId && domainId.length === 36) {
                    url = parameterService.appParameters.OdataBaseUrl + `UgdmMeta/UgdmTypes?$filter=TY_ID eq guid'${typeId}' and TY_DOMAIN eq guid'${domainId}'`
                }
                const result = await ajaxService.fetchRaw(requestHeaders.xmlGet, url)
                if (result.status === 200) {
                    const jsonData = await result.json()
                    if (jsonData.value) {
                        return jsonData.value[0] as UgdmType
                    } else {
                        return jsonData as UgdmType
                    }

                } else if (result.status === 404) {
                    return null
                } else {
                    throw new Error(result.status + ' \n' + result.statusText + ' \n' + result.url)
                }
            } catch (error) {
                console.log('error', error)
                throw error
            }
        },

        getUgdmLayers: async function (url: string) {
            try {
                const data = await ajaxService.fetchRaw(requestHeaders.jsonGet, url)
                const jsonData = (await data.json()) as UgdmLayer[]
                if (jsonData) {
                    jsonData.forEach((data) => {
                        parameterService.set('LAYER_OBJECT_' + data.TY_ID, data)
                    })
                }
                return jsonData
            } catch (error) {
                throw error
            }
        },

        getUserSettings: async function (userId: string) {
            try {
                let url = parameterService.appParameters.OdataBaseUrl + `UgdmIdentity/UgdmUserSettings?$filter=UserId eq guid'${userId}'`
                return await ongoingRequestHandler(url, requestHeaders.jsonGet, 0, 'json')
            } catch (error) {
                throw error
            }
        },

        setUserSetting: async function (setting: IM_USER_SETTING) {
            try {
                let url = parameterService.appParameters.OdataBaseUrl + `UgdmIdentity/UgdmUserSettings`
                const data = await ajaxService.fetchPost(requestHeaders.jsonPost, url, JSON.stringify(setting))
                return await data.json()
            } catch (error) {
                appendErrorInformation(error, 'Fehler beim speichern des UserSettings. (Metadatamanager)')
                throw error
            }
        },

        deleteUserSetting: async (userSettingId: string) => {
            try {
                let url = parameterService.appParameters.OdataBaseUrl + `UgdmIdentity/UgdmUserSettings(guid'${userSettingId}')`
                return await ajaxService.fetchDelete(requestHeaders.jsonDelete, url)
            } catch (error) {
                appendErrorInformation(error, 'Fehler beim Löschen der User-Einstellung.')
                throw error
            }
        },

        getSingleUserClaim: async function (userId: string, claimType: string) {
            try {
                const encodedClaimType = encodeURIComponent(claimType)
                let url = parameterService.appParameters.OdataBaseUrl + `UgdmIdentity/UgdmUserClaims?$filter=UserId eq guid'${userId}' and ClaimType eq '${encodedClaimType}'`
                return await ongoingRequestHandler(url, requestHeaders.jsonGet, 0, 'json')
            } catch (error) {
                throw error
            }
        },

        deleteUserClaim: async (claimId: string) => {
            try {
                let url = parameterService.appParameters.OdataBaseUrl + `UgdmIdentity/UgdmUserClaims(guid'${claimId}')`
                return await ajaxService.fetchDelete(requestHeaders.jsonDelete, url)
            } catch (error) {
                appendErrorInformation(error, 'Fehler beim lôschen eines User-Claims (deprecated!).')
                throw error
            }
        },

        getAllUserClaimsByUserId: async function (userId: string) {
            try {
                let url = parameterService.appParameters.OdataBaseUrl + `UgdmIdentity/UgdmUserClaims?$filter=UserId eq guid'${userId}'`
                return await handleFetchRawGetRequest(url, false)
            } catch (error) {
                throw error
            }
        },

        getAllUserClaims: async function () {
            try {
                let url = parameterService.appParameters.OdataBaseUrl + `UgdmIdentity/UgdmUserClaims`
                return await handleFetchRawGetRequest(url, false)
            } catch (error) {
                throw error
            }
        },

        setSingleUserClaim: async function (claim: any) {
            try {
                let url = parameterService.appParameters.OdataBaseUrl + `UgdmIdentity/UgdmUserClaims`
                const data = await ajaxService.fetchPost(requestHeaders.jsonPost, url, claim)
                return await data.json()
            } catch (error) {
                appendErrorInformation(error, 'Fehler beim speichern eines UserClaims (deprecated!)')
                throw error
            }
        },
    }
}

export const metadataManager = createMetadataManager(parameterService, ajaxService, requestHeaders, messageService)


const getOriginalName = (attribute: EntityAttribute) => {
    if (attribute.TY_NAME.endsWith('_ID') && attribute.AT_PRIMARY_KEY) {
        return 'EY_ID'
    }
    if (attribute.TY_NAME.endsWith('EY_TYPE')) {
        return 'EY_TYPE'
    }
    if (attribute.TY_NAME.endsWith('EY_NAME')) {
        return 'EY_NAME'
    }
    if (attribute.TY_NAME.endsWith('EY_LONGNAME')) {
        return 'EY_LONGNAME'
    }
    if (attribute.TY_NAME.endsWith('EY_DESCRIPTION')) {
        return 'EY_DESCRIPTION'
    }
    if (attribute.TY_NAME.endsWith('EY_REMARKS')) {
        return 'EY_REMARKS'
    }
    if (attribute.TY_NAME.endsWith('EY_CODE')) {
        return 'EY_CODE'
    }
    if (attribute.TY_NAME.endsWith('EY_STATUS')) {
        return 'EY_STATUS'
    }
    if (attribute.TY_NAME.endsWith('EY_START')) {
        return 'EY_START'
    }
    if (attribute.TY_NAME.endsWith('EY_END')) {
        return 'EY_END'
    }
    if (attribute.TY_NAME.endsWith('EY_CREATED')) {
        return 'EY_CREATED'
    }
    if (attribute.TY_NAME.endsWith('EY_MODIFIED')) {
        return 'EY_MODIFIED'
    }
    if (attribute.TY_NAME.endsWith('EY_OWNER')) {
        return 'EY_OWNER'
    }
    if (attribute.TY_NAME.endsWith('EY_PRIVATE')) {
        return 'EY_PRIVATE'
    }
    if (attribute.TY_NAME.endsWith('EY_RESPONSIBLE')) {
        return 'EY_RESPONSIBLE'
    }
    if (attribute.TY_NAME.endsWith('EY_CREATED_BY')) {
        return 'EY_CREATED_BY'
    }
    if (attribute.TY_NAME.endsWith('EY_MODIFIED_BY')) {
        return 'EY_MODIFIED_BY'
    }

    return null
}

const getDataType = (attribute: EntityAttribute, dataTypes: []) => {
    let longName = dataTypes.find(g => g.TY_ID === attribute.AT_DATA_TYPE).TY_LONGNAME
    if (longName === 'Guid' && attribute.AT_PRIMARY_KEY) {
        return longName
    } else if (longName === 'Guid' && !attribute.AT_PRIMARY_KEY) {
        if (attribute.AT_COMPLEX_TYPE === '7d627986-bbc3-435d-b5ac-999da9336538') {
            return 'List'
        }
        if (attribute.AT_COMPLEX_TYPE === '4aaaaf1e-53cd-41fe-b83e-b7802323af9a') {
            return 'EntityList'
        }
    }
    return longName
}

const getSimplifiedType = (type: string) => {

    switch (type) {
        case 'Binary': {
            return 'string'
        }
        case 'Boolean': {
            return 'boolean'
        }
        case 'EntityList':
        case 'List':
        case 'String': {
            return 'string'
        }
        case 'Time':
        case 'DateTimeOffset':
        case 'DateTime': {
            return 'date'
        }
        case 'Int32':
        case 'Int64':
        case 'Decimal':
        case 'Double':
        case 'Int16': {
            return 'number'
        }
        case 'Polygon':
        case 'MultiPolygon':
        case 'Point':
        case 'MultiPoint':
        case 'LineString':
        case 'MultiLineString':
        case 'Guid': {
            return 'string'
        }
    }
}

const getFilter = (attribute: EntityAttribute) => {
    // console.log('att', attribute)
    if (!attribute.AT_PRIMARY_KEY && attribute.AT_COMPLEX_TYPE) {
        if (attribute.AT_COMPLEX_TYPE.toLowerCase() === '7d627986-bbc3-435d-b5ac-999da9336538') {
            return `TY_DOMAIN eq guid'${attribute.AT_COMPLEX}' and TY_CULTURE eq guid'${attribute.TY_CULTURE}'`
        }
        if (attribute.AT_COMPLEX_TYPE.toLowerCase() === '4aaaaf1e-53cd-41fe-b83e-b7802323af9a') {
            return `EY_TYPE eq guid'${attribute.AT_COMPLEX}' ${attribute.TY_REMARKS ? ' and ' + attribute.TY_REMARKS.split(';')[0] : ''}`
        }
        if (attribute.AT_COMPLEX_TYPE.toLowerCase() === '9c4ea662-f6ef-4886-b61a-9ee06ba97212') {
            return `DM_PARENT eq guid'${attribute.AT_COMPLEX}' and DM_CULTURE eq guid'${attribute.TY_CULTURE}'`
        }
    }
    return null
}
