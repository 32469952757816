import {Request} from "../services/ajaxService";
import {handleErrorInAlert} from "./alertNotificationUtils";
import bc from "bc";

/***************************************************************
 * Concurrent Request Utils / Queue Promise Function / Request Utils
 ***************************************************************/

export const getCountAndValueProperties = (resourceName: string) => {
    let format = null
    let count = null
    if (resourceName && resourceName.length > 0) {
        if (resourceName.substring(0, 21) === 'UgdmTypeRelationships') {
            resourceName = 'UgdmTypeRelationships'
        }

        switch (resourceName) {
            case 'UgdmTypes': {
                format = 'value'
                count = 'odata.count'
                break
            }

            // Meta
            case 'UgdmDomains':
            case 'Codes':
            case 'UgdmTypeDomains':
            case 'UgdmTypeRelationships':
            case 'UgdmRelationshipAssociations':
            case 'UgdmDomainTreeNodes':
            case 'UgdmTypeTreeNodes':
            case 'UgdmTechnicalTypeTreeNodes':
            case 'UgdmThematicTypeTreeNodes': {
                format = 'value'
                count = 'odata.count'
                break
            }
            // Core
            case 'UgdmEntities':
            case 'UgdmEntityWithLocations':
            case 'UgdmEntityLocs':
            case 'UgdmActions':
            case 'UgdmArtifacts':
            case 'UgdmAttachments':
            case 'UgdmRelationships':
            case 'UgdmEntityLocations':
            case 'UgdmRouteEvents':
            case 'UgdmMeasurements':
            case 'UgdmLogs':
            case 'UgdmReferences':
            case 'UgdmEntityDomains': {
                format = 'value'
                count = 'odata.count'
                break
            }
            case 'UgdmEntityMeasurementTypes': {
                resourceName = 'MeasurementTypesForEntity'
                format = 'value'
                count = 'odata.count'
                break
            }
            case 'MeasurementTypesForEntity': {
                resourceName = 'MeasurementTypesForEntity'
                format = 'value'
                count = 'odata.count'
                break
            }
            // Identity
            case 'UgdmUsers':
            case 'UgdmRoles':
            case 'UgdmUsfrRoles':
            case 'UgdmTypeRoles':
            case 'UgdmPageRoles':
            case 'UgdmViewRoles':
            case 'UgdmDomainRoles':
            case 'UgdmEntityUsers':
            case 'UgdmUserClaims':
            case 'UgdmUserLogins': {
                format = 'value'
                count = 'odata.count'
                break
            }
            // Meta Subtypes
            case 'UgdmAssociations':
            case 'UgdmAttributes':
            case 'UgdmLocationTypes':
            case 'UgdmMeasurementTypes':
            case 'UgdmSrs':
            case 'UgdmSystems':
            case 'UgdmUnits': {
                format = 'value'
                count = 'odata.count'
                break
            }
            // Meta Specific Type Views
            case 'UgdmEntityViews':
            case 'UgdmEntityTypes': {
                resourceName = 'UgdmTypes'
                format = 'value'
                count = 'odata.count'
                break
            }
            // Meta Specific Attibute Views
            case 'UgdmEntityViewAttributes':
            case 'UgdmEntityTypeAttributes': {
                resourceName = 'UgdmTypes'
                format = 'value'
                count = 'odata.count'
                break
            }
            // default ist the dynamic application data service url
            default: {
                format = 'Items'
                count = 'Count'
            }
        }
    }
    return {
        resourceName: resourceName,
        format: format,
        count: count,
    }
}
let ongoingRequestArray: {
    url: string
    status: 'pending' | 'finished'
    data: any
    header: Request
    demands: number
    request: Promise<Response>
}[] = []
let requestDeleteTimeouts = []
/**
 * It takes a url, a header, a duration and a value type and returns a promise that resolves to the response of the request
 * @param {string} url - The url of the request
 * @param {Request} headers - Request
 * @param {number} duration - The time in milliseconds after which the request will be deleted from the ongoingRequestArray.
 * @param {'json' | 'text'} valueType - 'json' | 'text'
 * @returns A function that takes in a url, headers, duration, and valueType and returns a promise that resolves to the data from the request.
 */
export const ongoingRequestHandler = async (url: string, headers: Request, duration: number, valueType: 'json' | 'text') => {
    try {
        let existingRequest = ongoingRequestArray.find((r) => r.url === url && r.header === headers)
        if (existingRequest) {
            // console.log('existing request', existingRequest.url,)
            existingRequest.demands = existingRequest.demands + 1
            if (existingRequest.status === 'pending') {
                let data = await existingRequest.request
                existingRequest.demands = existingRequest.demands - 1
                if (existingRequest.demands === 0 && duration !== 0) {
                    if (requestDeleteTimeouts[url]) {
                        clearTimeout(requestDeleteTimeouts[url])
                    }
                    requestDeleteTimeouts[url] = setTimeout(() => {
                        ongoingRequestArray = [...ongoingRequestArray.filter((r) => r.url !== url)]
                        requestDeleteTimeouts[url] = undefined
                        // console.log('requests deleted for ', url, ongoingRequestArray)
                    }, duration)
                }
                return data
            } else {
                existingRequest.demands = existingRequest.demands - 1
                if (existingRequest.demands === 0 && duration !== 0) {
                    if (requestDeleteTimeouts[url]) {
                        clearTimeout(requestDeleteTimeouts[url])
                    }
                    requestDeleteTimeouts[url] = setTimeout(() => {
                        ongoingRequestArray = [...ongoingRequestArray.filter((r) => r.url !== url)]
                        requestDeleteTimeouts[url] = undefined
                        // console.log('requests deleted for ', url, ongoingRequestArray)
                    }, duration)
                }
                return existingRequest.data
            }
        } else {
            let ongoingRequest: {
                url: string
                status: 'pending' | 'finished'
                data: any
                header: Request
                demands: number
                request: Promise<Response>
            } = {
                url: url,
                header: headers,
                data: null,
                status: 'pending',
                demands: 0,
                request: null,
            }
            ongoingRequest.request = fetch(url, headers).then(async (response) => {
                if (response.status === 200) {
                    if (valueType === 'json') {
                        ongoingRequest.data = await response.json()
                    } else if (valueType === 'text') {
                        ongoingRequest.data = await response.text()
                    }
                } else if (response.status === 404) {
                    ongoingRequest.data = null
                } else {
                    throw  {
                        message: response.status + ',' + response.statusText + ',' + response.url,
                        name: 'RequestError'
                    }
                }
                ongoingRequest.status = 'finished'
                return ongoingRequest.data
            }).catch(error => {
                ongoingRequestArray = [...ongoingRequestArray.filter((r) => r.url !== url)]
                requestDeleteTimeouts[url] = undefined
                let urlErrors = bc.parameterService.get('URL_ERRORS')
                if(!urlErrors){
                    urlErrors = [url]
                    bc.parameterService.set('URL_ERRORS', urlErrors)
                    throw {url: url, error}
                }
                if(!urlErrors.includes(url)){
                    urlErrors = [...urlErrors, url]
                    bc.parameterService.set('URL_ERRORS', urlErrors)
                    throw {url: url, error}
                }else{
                    console.log('URL error already handled!', url)
                }
            })
            ongoingRequestArray.push(ongoingRequest)
            return await ongoingRequest.request
        }
    } catch (error) {
        console.error('url:',url , 'error:',  error)
        handleErrorInAlert(error)
        throw error
    }
}
let duplicateRequestArray: {
    url: string
    status: 'pending' | 'finished'
    data: any
    header: Request
    request: Promise<Response>,
    try: number,
}[] = []
let failedRequestsArray: {
    url: string,
    tries: number
}[] = []
export const noDuplicateRequest = async (url: string, headers: Request, valueType: 'json' | 'text') => {
    try {
        let existingRequest = duplicateRequestArray.find((r) => r.url === url && r.header === headers)
        if (existingRequest) {
            if (existingRequest.status === 'pending') {
                return await existingRequest.request
            } else {
                return existingRequest.data
            }
        } else {
            let ongoingRequest: {
                url: string
                status: 'pending' | 'finished'
                data: any
                header: Request
                request: Promise<Response>,
                try: number,
            } = {
                url: url,
                header: headers,
                data: null,
                status: 'pending',
                request: null,
                try: 1,
            }
            duplicateRequestArray.push(ongoingRequest)
            ongoingRequest.request = fetch(url, headers).then(async (response) => {
                if (response.status === 200) {
                    if (valueType === 'json') {
                        ongoingRequest.data = await response.json()
                    } else if (valueType === 'text') {
                        ongoingRequest.data = await response.text()
                    }
                } else if (response.status === 404) {
                    ongoingRequest.data = null
                } else if (response.status === 400) {
                    ongoingRequest.data = null
                } else if (response.status === 500) {
                    let failedRequest = failedRequestsArray.find(f => f.url === url)
                    if (failedRequest) {
                        failedRequest.tries = failedRequest.tries + 1
                        if (failedRequest.tries === 4) {
                            throw new Error(response.status + ',' + response.statusText + ',' + response.url)
                        } else {
                            duplicateRequestArray = [...duplicateRequestArray.filter((r) => r.url !== url)]
                            return await noDuplicateRequest(url, headers, valueType)
                        }
                    } else {
                        failedRequestsArray.push({url: url, tries: 2})
                        duplicateRequestArray = [...duplicateRequestArray.filter((r) => r.url !== url)]
                        return await noDuplicateRequest(url, headers, valueType)
                    }
                } else {
                    throw new Error(response.status + ',' + response.statusText + ',' + response.url)
                }
                
                ongoingRequest.status = 'finished'
                duplicateRequestArray = [...duplicateRequestArray.filter((r) => r.url !== url)]
                failedRequestsArray = [...failedRequestsArray.filter((r) => r.url !== url)]
                return ongoingRequest.data
            })
            return await ongoingRequest.request
        }
    } catch (error) {
        handleErrorInAlert(error)
        throw error
    }
}
/**
 * It takes a function that returns an async function that will only run the original function once as long
 * an call of this function is still ongoing
 * @param callback - (...args) => Promise<any>
 * @param args - any[]
 * @returns A function that returns a promise.
 */
export const createQueuePromiseFunction = (callback: (...args) => Promise<any>, ...args) => {

    let isRunning = false
    let call: Promise<any>

    return async () => {
        // let value:any = null
        if (!isRunning) {
            isRunning = true
            call = callback(...args)
            return call.then(result => {
                isRunning = false
                return result
            })
        } else if (isRunning) {
            return call.then(result => {
                isRunning = false
                return result
            })
        }
    }
}