import tippy, {Instance} from "tippy.js";
import {BcEvent} from "../interfaces";
import bc from "bc";
import {PARAMS} from "../services/parameterService";

/***************************************************************
 * General purpose utils
 ***************************************************************/

export const createEvent = (eventName: string, data: any, options: {
    composed: boolean,
    bubbles: boolean,
    cancelable: boolean
} = {composed: false, bubbles: false, cancelable: true}) => {
    let event = new Event(eventName, options) as BcEvent
    if (data) {
        event.detail = data
    }
    return event
}

export const exists = (value: any) => {
    if (value !== undefined && value !== null) {
        return true
    }
    return false
}

export function debounce(callback: Function, delay: number) {
    let timeout
    return (...args: any) => {
        const context = this
        clearTimeout(timeout)
        timeout = setTimeout(() => callback.apply(context, args), delay)
    }
}

export function tooltip(node, params = {}) {
    // Determine the title to show. We want to prefer
    // the custom content passed in first, then the
    // HTML title attribute then the aria-label
    // in that order.
    const content = node.title
    if (content && content !== '') {
        node.setAttribute('aria-label', content)

        // Clear out the HTML title attribute since
        // we don't want the default behavior of it
        // showing up on hover.
        node.title = ''

        // Support any of the Tippy props by forwarding all "params":
        // https://atomiks.github.io/tippyjs/v6/all-props/

        //@ts-ignore
        const tip: Instance = tippy(node, {content, touch: false})

        return {
            // If the props change, let's update the Tippy instance:
            //   update: (newParams) => tip.setProps({ content, ...newParams }),

            // Clean up the Tippy instance on unmount:
            destroy: () => tip.destroy(),
        }
    }
}

function fallbackCopyTextToClipboard(text) {
    let textArea = document.createElement('textarea')
    textArea.value = text
    // Avoid scrolling to bottom
    textArea.style.top = '-99999px'
    textArea.style.left = '-99999px'
    textArea.style.position = 'fixed'
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    try {
        let successful = document.execCommand('copy')
        if (successful) {
            document.body.removeChild(textArea)
            textArea = undefined
            return true
        } else {
            document.body.removeChild(textArea)
            textArea = undefined
            return false
        }
    } catch (err) {
        document.body.removeChild(textArea)
        textArea = undefined
        console.error('Fallback: Oops, unable to copy', err)
        return false
    }
}

export const copyToClipboard = async (text: string): Promise<boolean> => {
    if (!navigator.clipboard) {
        return fallbackCopyTextToClipboard(text)
    } else {
        try {
            await navigator.clipboard.writeText(text)
            return true
        } catch (error) {
            return false
        }
    }
}

export const getThemeTreeViewGuids = () => {

    let themeTreeViewGuids = bc.parameterService.get('THEME_TREE_VIEW_GUIDS');
    console.log('getThemeTreeViewGuids themeTreeViewGuids', themeTreeViewGuids);

    if(themeTreeViewGuids) {
        return themeTreeViewGuids;
    }
    
    themeTreeViewGuids = []
    let domains = bc.parameterService.get('DOMAINS')
    let rights = bc.parameterService.get('RIGHTS')
    let typeDomains = bc.parameterService.get('TYPE_DOMAINS')
    let views = bc.parameterService.get('DATAVIEWS')

    console.log('getThemeTreeViewGuids', domains, rights, typeDomains, views);
    
    const getRecusiveTree = (parentGuid: string) => {
        let subDomains = domains.filter(d => d.DM_PARENT && d.DM_PARENT.toLowerCase() === parentGuid.toLowerCase())
        subDomains.sort((a, b) => (a.DM_NAME).localeCompare(b.DM_NAME))
        for (let i = 0; i < subDomains.length; i++) {
            if (subDomains[i].DM_PARENT.toLowerCase() === parentGuid.toLowerCase()) {
                let items = typeDomains.filter(t => t.TD_DOMAIN && t.TD_DOMAIN.toLowerCase() === subDomains[i].DM_ID.toLowerCase())
                let itemViews = []
                for (let j = 0; j < items.length; j++) {
                    if (views) {
                        let view = views.find(v => v.TY_ID.toLowerCase() === items[j].TD_TYPE.toLowerCase())
                        if (view && rights[view.TY_ID].canRead) {
                            itemViews = [...itemViews, view]
                        }
                    }
                }
                itemViews.sort((a, b) => ('' + a.TY_LONGNAME).localeCompare(b.TY_LONGNAME))
                themeTreeViewGuids = [...themeTreeViewGuids, ...itemViews.map(v => v.TY_ID)]
                getRecusiveTree(subDomains[i].DM_ID)
            }
        }
    }

    let themeTree = bc.parameterService.get('ThemeTree')
    getRecusiveTree(themeTree ? themeTree : PARAMS.application.classificationTreeRootGUID)
    bc.parameterService.set('THEME_TREE_VIEW_GUIDS', themeTreeViewGuids)
    console.log('THEME_TREE_VIEW_GUIDS set', themeTreeViewGuids);
    return themeTreeViewGuids
    
}


export const getComponentText = (name: string) => {
    let allTexts = window['texts'][name]
    let finalText = null
    if (allTexts) {
        finalText = {...allTexts['en']}
        finalText = {...finalText, ...allTexts[navigator.language.substring(0, 2)]}
        finalText = {...finalText, ...allTexts[navigator.language]}
    }
    return finalText
}



