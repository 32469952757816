import {FieldChange, FieldCondition, GroupChange} from "../interfaces";

function createGroupChange(data: {
    group: string[],
    visible: boolean,
    required?: 'allRequired' | 'allNotRequired' | 'metadata' | 'keep',
    requiredFields?: string[],
    notRequiredFields?: string[] | null
}) {
    const {group, required, visible, requiredFields, notRequiredFields} = data
    let groupChange: GroupChange = {
        group,
        visible,
    }

    if (required) {
        groupChange['requiredType'] = required
    }

    if (requiredFields) {
        groupChange['requiredFields'] = requiredFields
    }

    if (notRequiredFields) {
        groupChange['notRequiredFields'] = notRequiredFields
    }
    return groupChange
}


function createFieldChange(data: {
    field: string,
    required: 'true' | 'false' | 'keep' | 'metadata' | boolean,
    visible: boolean,
    resetOnHide?: boolean,
    newValue?: string | null,
    disabled?: boolean,
}) {

    const {field, required, visible, resetOnHide, newValue, disabled} = data
    let fieldChange: FieldChange = {
        field,
        required,
        visible,
    }

    if (resetOnHide !== undefined) {
        fieldChange['resetOnHide'] = resetOnHide
    }

    if (newValue !== undefined) {
        fieldChange['newValue'] = newValue
    }

    if (disabled !== undefined) {
        fieldChange['disabled'] = disabled
    }

    return fieldChange
}


function createFieldCondition(data: {
    value: string | null,

}) {

}

// this sets the "action" to run, if condition is met
function FieldConditionHelper(value: string | null|string[], isReverseValue: boolean) {
    let fieldChanges: FieldChange[] = []
    let groupChanges: GroupChange[] = []
    let valueChanges: { field: string, value: string | null|string[] }[] = []
    let otherFields: { field: string, value: string | null|string[] }[] = []
    let listFilters: { field: string, values: string[] }[] = []
    let reverseListFilters: { field: string, values: string[] }[] = []
    let callback: Function[] = []

    let fieldCondition: FieldConditionHelper = {
        value: value,
        fieldChanges,
        groupChanges,
        otherFields,
        valueChanges,
        listFilters,
        reverseListFilters,
        callback,

        addCallback: (callbackFunction: Function) => {
            callback.push(callbackFunction)
        },

        addFieldChange: (change: FieldChange) => {
            fieldChanges.push(change);
        },
        addGroupChange: (change: GroupChange) => {
            groupChanges.push(change);
        },
        addValueChange: (field: string, value: string | null) => {
            valueChanges.push({ field, value });
        },
        addOtherFieldCondition: (field: string, value: string | null) => {
            otherFields.push({ field, value });
        },
        hideFields: (fields: string[], reset = false) => {
            for (let i = 0; i < fields.length; i++) {
                let fieldChange: FieldChange = {
                    field: fields[i],
                    required: 'keep',
                    visible: false,
                    resetOnHide: reset
                };
                fieldChanges.push(fieldChange);
            }
        },
        showFields: (fields: string[], newValue?: string) => {
            for (let i = 0; i < fields.length; i++) {
                let fieldChange: FieldChange = {
                    field: fields[i],
                    required: 'keep',
                    visible: true,
                    resetOnHide: false
                };

                if (newValue) {
                    fieldChange.newValue = newValue;
                }

                fieldChanges.push(fieldChange);
            }
        },

        enableFields: (fields: string[]) => {
            for (let i = 0; i < fields.length; i++) {
                let fieldChange: FieldChange = {
                    field: fields[i],
                    required: 'keep',
                    visible: 'keep',
                    disabled: false,
                };
                fieldChanges.push(fieldChange);
            }
        },

        disableFields: (fields: string[]) => {
            for (let i = 0; i < fields.length; i++) {
                let fieldChange: FieldChange = {
                    field: fields[i],
                    required: 'keep',
                    visible: 'keep',
                    disabled: true,
                };
                fieldChanges.push(fieldChange);
            }
        },

        requiredFields: (fields: string[]) => {
            for (let i = 0; i < fields.length; i++) {
                let fieldChange: FieldChange = {
                    field: fields[i],
                    required: 'true',
                    visible: 'keep',
                };
                fieldChanges.push(fieldChange);
            }
        },

        optionalFields: (fields: string[]) => {
            for (let i = 0; i < fields.length; i++) {
                let fieldChange: FieldChange = {
                    field: fields[i],
                    required: 'false',
                    visible: 'keep',
                };
                fieldChanges.push(fieldChange);
            }
        },


        hideGroups: (groups: string[]) => {
            let groupChange: GroupChange = {
                group: groups,
                visible: false,
            };
            groupChanges.push(groupChange);
        },

        showGroups: (groups: string[]) => {
            let groupChange: GroupChange = {
                group: groups,
                visible: true,
            };
            groupChanges.push(groupChange);
        },

        listFilter: (field: string, list: string[]) => {
            listFilters.push({ field, values: list });
        },

        reverseListFilter: (field: string, list: string[]) => {
            reverseListFilters.push({ field, values: list });
        },
        
    }

    fieldCondition.reverseValue = isReverseValue;

    return fieldCondition
}

interface FieldConditionHelper extends FieldCondition {
    addCallback: (callbackFunction: Function) => void
    addFieldChange: (change: FieldChange) => void
    addGroupChange: (change: GroupChange) => void
    addValueChange: (field: string, value: string | null|string[]) => void
    addOtherFieldCondition: (field: string, value: string | null|string[]) => void
    hideFields: (fields: string[], reset: boolean) => void,
    listFilter: (field: string, list:string[])=>void,
    reverseListFilter: (field: string, list:string[])=>void,
    showFields: (fields: string[], newValue?: string) => void,
    enableFields: (fields: string[]) => void,
    disableFields: (fields: string[]) => void,
    requiredFields:(fields: string[]) => void,
    optionalFields:(fields: string[]) => void,
    showGroups: (groups: string[]) => void,
    hideGroups: (groups: string[]) => void,
    listFilters: { field: string, values: string[] }[]
    reverseListFilters: { field: string, values: string[] }[]
}

function getRules(rules: FormRule[]) {
    return [...rules.map(r => r.getRule())]
}

function setFormRules(dataviewguid: string, rules: FormRule) {
    bc.parameterService.set(dataviewguid + '_VISIBILITY_RULES', rules)
}

export class FormRule {
    private field: string = ''
    private conditions: FieldCondition[] = []

    constructor(name: string) {
        this.field = name
    }

    getField() {
        return this.field
    }

    getConditions() {
        return this.conditions
    }

    // this defines the value condition that trigger something to happen
    createValueCondition(value: string | null|string[]) {
        let fieldCondition = FieldConditionHelper(value, false)
        this.conditions.push(fieldCondition)

        return fieldCondition
    }

    // this defines the reverse value condition that trigger something to happen
    createReverseValueCondition(value: string | null) {
        let fieldCondition = FieldConditionHelper(value, true)
        this.conditions.push(fieldCondition)
        return fieldCondition
    }

    getRule() {
        return {
            field: this.field,
            conditions: this.conditions
        }
    }

}

export const formRuleHelper = {
    FormRule,
    createGroupChange,
    createFieldChange,
    getRules,
    setFormRules
}




